export const APP_GET = 'APP_GET';
export const APP_ERROR = 'APP_ERROR';

export const SITES_GET = 'SITES_GET';
export const SITES_ERROR = 'SITES_ERROR';



export const LOGIN_GET = 'LOGIN_GET';
export const LOGOUT = 'LOGOUT';

export const LANGUAGE_GET = 'LANGUAGE_GET';
export const LANGUAGE_ERROR = 'LANGUAGE_ERROR';

export const HEADER_GET = 'HEADER_GET';
export const HEADER_ERROR = 'HEADER_ERROR';

export const BOOKING_GET = 'BOOKING_GET';

export const BOOKING_SETTING_GET ='BOOKING_SETTING_GET';

export const BOOKING_ENGINE_CALENDAR_GET = 'BOOKING_ENGINE_CALENDAR_GET';
export const BOOKING_ENGINE_CALENDAR_ERROR = 'BOOKING_ENGINE_CALENDAR_ERROR';

export const BOOKING_ENGINE_PACKAGE_GET = 'BOOKING_ENGINE_PACKAGE_GET';
export const BOOKING_ENGINE_PACKAGE_ERROR = 'BOOKING_ENGINE_PACKAGE_ERROR';

export const BOOKING_ENGINE_PACKAGE_UPDATE_SELECTED = 'BOOKING_ENGINE_PACKAGE_UPDATE_SELECTED';


export const BOOKING_ENGINE_CALENDAR_LOADING = 'BOOKING_ENGINE_CALENDAR_LOADING'
export const BOOKING_ENGINE_PACKAGE_LOADING = 'BOOKING_ENGINE_PACKAGE_LOADING'
export const BOOKING_ENGINE_CALENDAR_SET_DATE = 'BOOKING_ENGINE_CALENDAR_SET_DATE'
export const BOOKING_ENGINE_SESSION_GET = 'BOOKING_ENGINE_SESSION_GET';
export const BOOKING_ENGINE_GUIDE_GET = 'BOOKING_ENGINE_GUIDE_GET';

export const BOOKING_ENGINE_SET_GUIDE = 'BOOKING_ENGINE_SET_GUIDE';
export const BOOKING_ENGINE_SET_SESSIONS = 'BOOKING_ENGINE_SET_SESSIONS';
export const BOOKING_UPDATE_PAX = 'BOOKING_UPDATE_PAX';

export const BOOKING_FIRST_PRICE = 'BOOKING_FIRST_PRICE';

export const BOOKING_BLOCK_PRICE_UPDATE = 'BOOKING_BLOCK_PRICE_UPDATE';


/*

*/


export const B_ENGINE_PRODUCTID_SET = 'B_ENGINE_PRODUCTID_SET';
export const B_ENGINE_SETTING_LOADING = 'B_ENGINE_SETTING_LOADING';
export const B_ENGINE_SETTING_GET = 'B_ENGINE_SETTING_GET';

export const B_ENGINE_PACKAGE_LOADING = 'B_ENGINE_PACKAGE_LOADING';
export const B_ENGINE_PACKAGE_GET = 'B_ENGINE_PACKAGE_GET';
export const B_ENGINE_PACKAGE_SET = 'B_ENGINE_PACKAGE_SET';

export const B_ENGINE_CALENDAR_LOADING = 'B_ENGINE_CALENDAR_LOADING';
export const B_ENGINE_CALENDAR_GET = 'B_ENGINE_CALENDAR_GET';
export const B_ENGINE_CALENDAR_SET = 'B_ENGINE_CALENDAR_SET';


export const B_ENGINE_GUIDE_SESSION_LOADING = 'B_ENGINE_GUIDE_SESSION_LOADING';

export const B_ENGINE_GUIDE_GET = 'B_ENGINE_GUIDE_GET';
export const B_ENGINE_SESSION_GET = 'B_ENGINE_SESSION_GET';

export const B_ENGINE_SESSION_SET = 'B_ENGINE_SESSION_SET';

export const B_ENGINE_GUIDE_SET = 'B_ENGINE_GUIDE_SET';

export const B_ENGINE_BLOCK_GET = 'B_ENGINE_BLOCK_GET';
export const B_ENGINE_BLOCK_LOADING = 'B_ENGINE_BLOCK_LOADING';

export const B_ENGINE_UPDATE_PAX = 'B_ENGINE_UPDATE_PAX';

export const B_ENGINE_UPDATE_PRICE ="B_ENGINE_UPDATE_PRICE"
export const B_ENGINE_UPDATE_PRICE_LOADING ="B_ENGINE_UPDATE_PRICE_LOADING"

export const B_ENGINE_PACKAGE_DETAIL_GET ="B_ENGINE_PACKAGE_DETAIL_GET"
export const B_ENGINE_CANCEL_GET ="B_ENGINE_CANCEL_GET"


export const B_ENGINE_OPTIONS_GET ="B_ENGINE_OPTIONS_GET"

export const B_ENGINE_UPDATE_OPTIONS_PAX ="B_ENGINE_UPDATE_OPTIONS_PAX"

export const B_ENGINE_UPDATE_OPTIONS_LOADING ="B_ENGINE_UPDATE_OPTIONS_LOADING"

export const B_ENGINE_UPDATE_OPTION_PRICE ="B_ENGINE_UPDATE_OPTION_PRICE"


export const B_ENGINE_RESET ="B_ENGINE_RESET"









