import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="rowBar"></div>
                <div className="whiteBar">
                    <div className="left">
                        <div className="logo">
                            <img src="/Assets/img/tourzoneIcon.svg" alt="icon" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="footerColum">
                            <div className="title">COMPANY</div>
                            <ul>
                                <li>
                                    <Link to="/">Who we are?</Link>
                                </li>
                                <li>
                                    <Link to="/">What we do?</Link>
                                </li>
                                <li>
                                    <Link to="/">Carrers?</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="footerColum">
                            <div className="title">HELP</div>
                            <ul>
                                <li>
                                    <Link to="/">FAQs</Link>
                                </li>
                                <li>
                                    <Link to="/">Contact US</Link>
                                </li>
                                <li>
                                    <Link to="/">Specialist Support</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="footerColum">
                            <div className="title">RESOURCES</div>
                            <ul>
                                <li>
                                    <Link to="/">Covid-19 Travel Alerts</Link>
                                </li>
                                <li>
                                    <Link to="/">View all trips</Link>
                                </li>

                            </ul>
                        </div>

                        <div className="footerColum">
                            <div className="title">WORK WITH US</div>
                            <ul>
                                <li>
                                    <Link to="/">Supplier Administration</Link>
                                </li>
                                <li>
                                    <Link to="/">Became An Affiliate Partner</Link>
                                </li>

                            </ul>
                        </div>

                        <div className="footerColum">
                            <div className="title">TERM OF USE</div>
                            <ul>
                                <li>
                                    <Link to="/">Terms & Conditions</Link>
                                </li>
                                <li>
                                    <Link to="/">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/">Cookie Policy</Link>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
                <div className="grayBar">
                    <div className="left">
                        <div className="row">
                            <div className="paymetAccept">
                                we accept:
                            </div>
                            <div className="paymetAcceptIcon">
                                <div className="icon">
                                    <img src="/Assets/img/icon/visa.svg" alt="visa" />
                                </div>
                                <div className="icon">
                                    <img src="/Assets/img/icon/mastercards.svg" alt="master card" />
                                </div>
                                <div className="icon">
                                    <img src="/Assets/img/icon/american-express.svg" alt="american express" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="middle">

                        <div className="row">Vivon tourism Limited, Antalya/TURKEY</div>
                        <div className="row">For the latest travel advice from the Foreign & Commonwealth Office including security and local laws  plus passport and visa information check www.gov.uk/foreign-travel-advice</div>
                    </div>
                    <div className="right">
                        <div className="row">Privacy & Cookies | Booking Conditions | Site Map</div>
                    </div>
                </div>
                <div className="footerBottom">
                    <div className="row"><b>© Copyright 2020 vivon tourism. All Rights Reserved.</b></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(Footer)
