import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";

import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/flatpickr.min.css'
import Moment from 'moment';

import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

export class Experience extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: "",
            sendRequestLoading: true,
            spect: [],
            priceVal: [1, 80],
            destinations: [],
        }
    }

    calendar = React.createRef();


    componentDidMount() {
        this.sendPrices();
    }

    sendPrices = async () => {
        this.setState({
            sendRequestLoading: true
        });

        const data = {
            destinationId: this.props.destinationId,
        }

        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getSearchService`, data)

            if (resp.status === 200) {

                this.setState({
                    sendRequestLoading: false,
                    spect: resp.data
                });



            }
        } catch (err) {
            console.error(err);
        }
    };

    onSliderChange = (value) => {

        this.setState({
            priceVal: value,
        });
    };

    priceFormat = (e) => {
        return e.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    }

    render() {
        console.log(this.state);
        return (
            <>
                <div className='searchPanel'>
                    <div className='filter'>
                        <div className='title'>
                            FILTER
                        </div>
                        <div className='dates'>
                            <div className='title'>
                                Enter your dates to find available activities
                            </div>
                            <div className='calendar'>
                                <Flatpickr
                                    ref={calendar => { this.calendar = calendar; }}
                                    placeholder="Select date"
                                    options={{
                                        minDate: "today",
                                        altInput: true,
                                        altFormat: "F j",
                                        dateFormat: "Y-m-d",
                                        locale: {
                                            firstDayOfWeek: 1
                                        },
                                        mode: "range"
                                    }}
                                    onChange={(selectedDates, dateStr, instance) => {
                                        this.setState({
                                            selectedDate: dateStr
                                        })
                                    }}
                                />
                                <div className="icon">
                                    <img src="/Assets/img/icon/calendar.svg" alt="Search" />
                                </div>
                            </div>
                            <div className='button'>Result</div>
                        </div>
                        {!this.state.sendRequestLoading &&
                            <>
                                <div className='filterItem'>
                                    <div className='title'>Destinations <span className="chevron"></span></div>
                                    <div className='elems'>
                                        <ul>
                                            {this.state.spect.destination.map((item, index) => (
                                                <li>
                                                    <input className="styled-checkbox" id={"dest-" + index} type="checkbox" value="1" />
                                                    <label htmlFor={"dest-" + index}>{item.name}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className='filterItem'>
                                    <div className='title'>
                                        Duration<span className="chevron"></span>
                                    </div>
                                    <div className='elems'>
                                        <ul>
                                            <li>
                                                <input className="styled-checkbox" id="multiday" type="checkbox" value="1" />
                                                <label htmlFor="multiday">0 - 3 hours</label>
                                            </li>
                                            <li>
                                                <input className="styled-checkbox" id="multiday" type="checkbox" value="1" />
                                                <label htmlFor="multiday">3 - 5 hours</label>
                                            </li>
                                            <li>
                                                <input className="styled-checkbox" id="multiday" type="checkbox" value="1" />
                                                <label htmlFor="multiday">5 - 7 hours</label>
                                            </li>
                                            <li>
                                                <input className="styled-checkbox" id="multiday" type="checkbox" value="1" />
                                                <label htmlFor="multiday">Full day (7+ hours)</label>
                                            </li>
                                            <li>
                                                <input className="styled-checkbox" id="multiday" type="checkbox" value="1" />
                                                <label htmlFor="multiday">1-3 days</label>
                                            </li>
                                            <li>
                                                <input className="styled-checkbox" id="oneday" type="checkbox" value="1" />
                                                <label htmlFor="oneday">3+ days</label>
                                            </li>

                                        </ul>
                                    </div>

                                </div>
                                <div className='filterItem'>
                                    <div className='title'>Themes<span className="chevron"></span></div>
                                    <div className='elems'>
                                        <ul>
                                            {this.state.spect.categories.map((item, index) => (
                                                <li>
                                                    <input className="styled-checkbox" id={"cat-" + index} type="checkbox" value="1" />
                                                    <label htmlFor={"cat-" + index}>{item.name}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className='filterItem'>
                                    <div className='title'>Price<span className="chevron"></span></div>
                                    <div className='elems price'>
                                        <div className='price'>
                                            <Range min={0} max={1000} allowCross={false} value={this.state.priceVal} onChange={this.onSliderChange} />
                                        </div>
                                        <div className="priceVals">
                                            <div className='startPrice'>{this.state.spect.currency.currency} {this.priceFormat((this.state.priceVal[0]) * this.state.spect.currency.multipler)}</div>
                                            <div className='endPricePrice'>
                                                {this.state.spect.currency.currency} {this.priceFormat((this.state.priceVal[1]) * this.state.spect.currency.multipler)}
                                                {this.state.priceVal[1] === 1000 && " +"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='filterItem'>
                                    <div className='title'>Guide<span className="chevron"></span></div>
                                    <div className='elems'>
                                        <ul>
                                            {this.state.spect.guides.map((item, index) => (
                                                <li>
                                                    <input className="styled-checkbox" id={"lngs-" + index} type="checkbox" value="1" />
                                                    <label htmlFor={"lngs-" + index}>{item.name}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className='filterItem'>
                                    <div className='title'>Services<span className="chevron"></span></div>
                                    <div className='elems'>
                                        <ul>

                                            <li>
                                                <input className="styled-checkbox" id="evoucher" type="checkbox" value="1" />
                                                <label htmlFor="evoucher" >E-Voucher</label>
                                            </li>
                                            <li>
                                                <input className="styled-checkbox" id="instant" type="checkbox" value="1" />
                                                <label htmlFor="instant" >Instant Confirmation</label>
                                            </li>
                                            <li>
                                                <input className="styled-checkbox" id="evoucher" type="checkbox" value="1" />
                                                <label htmlFor="wheelchair" >Wheelchair compatibility</label>
                                            </li>
                                            <li>
                                                <input className="styled-checkbox" id="pickup" type="checkbox" value="1" />
                                                <label htmlFor="pickup" >Pick-Up Service</label>
                                            </li>
                                            <li>
                                                <input className="styled-checkbox" id="liveGuide" type="checkbox" value="1" />
                                                <label htmlFor="liveGuide" >Live guide</label>
                                            </li>
                                            <li>
                                                <input className="styled-checkbox" id="privateTour" type="checkbox" value="1" />
                                                <label htmlFor="privateTour" >Private Tour</label>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='result'></div>
                </div>

            </>
        );
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
})

export default connect(mapStateToProps)(Experience);
