import React, { Component } from 'react'
import { connect } from 'react-redux'
import LanguageSet from '../../../Helpers/LanguageSet';

export class Categories extends Component {
    render() {

        const { categories } = this.props.appData.appData.sites.data;
        return (
            <><div className="categories">
                {categories.filter(item=> item.img !== "").map((item, index) => (

                    <div className="items" style={{background:"url(" + process.env.REACT_APP_ASSETS_ADDRESS + "images/categories/" + item.img + "),rgba(0,0,0,0.3)"}}>
                        {item.name}
                    </div>

                ))}
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});


export default connect(mapStateToProps)(Categories)
