import React, { Component } from 'react'
import { connect } from 'react-redux'

export class LanguageSet extends Component {
    render() {
        const { language } = this.props.appData.appData.sites.data;
    

        return (
            <>
                {
                    language.translate[this.props.code]
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
})



export default connect(mapStateToProps)(LanguageSet)
