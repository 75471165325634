import React, { Component } from 'react'
import LanguageSet from '../../../Helpers/LanguageSet';
import { connect } from 'react-redux'

class ManageBooking extends Component {

    myRef = React.createRef();

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside, false);

    }
    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside, false);
    }

    handleClickOutside = e => {
  
        let parentElem = false

        e.path.forEach((item)=>{

            if(item.id === "manageBooking") {
                parentElem = true;

            }

        })
        

        if (!this.myRef.current.contains(e.target) && parentElem === false) {
         
            this.props.closeManageBooking()
        }

    }


    
    render() {
        const { language } = this.props.appData.appData.sites.data;
        return (
            <div className="ManageBookingPopup"  ref={this.myRef} onClick={this.handleClickInside}>
                <div className="icon">
                    <img src="/Assets/img/icon/ticket-color.svg" alt="Facebook" />
                </div>
                <div className="manageBookingTitle">

                    <LanguageSet code="HEADER_MANAGE_BOOKING_TITLE" />
                </div>
                <div className="manageBookingTitleSub">
                    <LanguageSet code="HEADER_MANAGE_BOOKING_TITLE_SUB" />
                </div>

                <div className="manageBookingInputBox">
                    <input type="text" id="helpQ" name="helpQ" placeholder={language.translate.HEADER_MANAGE_BOOKING_VOUCHER_NUMBER} required="" />

                </div>

                <div className="manageBookingInputBox">
                    <input type="text" id="helpQ" name="helpQ" placeholder={language.translate.HEADER_MANAGE_BOOKING_PIN_NUMBER} required="" />
                </div>
                <div className="manageBookingButton">
                <LanguageSet code="HEADER_MANAGE_BOOKING_SERACH_BOOKING" />
                </div>


            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(ManageBooking)
