import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from "axios";

import DestinationSlider from './DestinationSlider';
import Overview from './Overview';
import Buttons from './Buttons';



class Destination extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sendRequestLoading: true,
            destinationId: props.location.state.id,
            data: [],
        }
    }

    componentDidMount() {
        this.sendPostRequest();

    }

    sendPostRequest = async () => {
        this.setState({
            sendRequestLoading: true
        });

        const data = {
            destinationId: this.state.destinationId
        }


        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getDestinationDetail`, data)

            if (resp.status === 200) {
                this.setState({
                    data: resp.data,
                    sendRequestLoading: false

                })


            }
        } catch (err) {
            console.error(err);
        }
    };

    render() {

        console.log(this.props)
        return (
            <div className="destinationPage">
                {!this.state.sendRequestLoading &&
                    <>
                        <DestinationSlider data={this.state.data} />
                        <div className="destinationContent">
                            <Overview data={this.state.data} />
                            <Buttons data={this.state.data} destinationId={this.state.destinationId} />
                          
                        </div>
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
})



export default connect(mapStateToProps)(Destination)
