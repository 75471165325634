import React, { Component } from 'react'
import { connect } from 'react-redux'

class CancelPolicy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,

        };
    }
    render() {
        return (
            <div className="cancelPolicy spy" id="cancellationPolicySpy">
                <div className="title">Policies</div>
                <div className='titleSub'>Cancellation Policy</div>
                <div className="content" dangerouslySetInnerHTML={{ __html: this.state.data.cancelPolicy.detail }}>
                </div>


                <div className='titleSub'>Confirmation Policy</div>
                <div className="content">
                    <ul>
                        <li>The customer receives a confirmation voucher via email within 2 business hours of successful booking.</li>
                        <li>In case the preferred slots are unavailable, an alternate schedule of the customer’s preference will be arranged and a new confirmation voucher will be sent via email.</li>
                        <li>Alternatively, the customer may choose to cancel their booking before confirmation and a full refund will be processed.</li>
                    </ul>
                </div>

                <div className='titleSub'>Refund Policy</div>
                <div className="content">
                    <ul>
                        <li>Refunds in the Tourzone wallet will be transferred within 3 business days.</li>
                        <li>Cash Refunds once initiated, usually take around 5-7 business days to get transferred to the same account through which the payment is made.</li>

                    </ul>
                </div>

                <div className='titleSub'>Booking Policy</div>
                <div className="content">
                    <ul>
                        <li>For confirm booking100% payment at the time of booking.</li>


                    </ul>
                </div>
                <div className='titleSub'>Payment Terms Policy</div>
                <div className="content">
                    <ul>
                        <li>100.0% of total tour cost will have to be paid 0 days before the date of booking</li>


                    </ul>
                </div>
            </div>

        )
    }
}


const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(CancelPolicy)
