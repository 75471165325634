import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import { Link } from 'react-router-dom';

import "../../../Assets/bookingresult.css"
import Moment from 'moment';

import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.scss';
import Fancybox from '../../../Helpers/fancybox';

import CurrencyFormat from 'react-currency-format';


SwiperCore.use([Navigation]);
export class BookingResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            selectedPackage: 0,
            time: false,
            clock: false,
            selectedPackageId: false,
            breakdown: false,
            openDetails: false,
            selectedId: 0
        }


    }


    booking = (packageId, types) => {

        this.setState({
            selectedPackageId: packageId
        }, () => {

            let selectedPackage = this.state.data.data.filter(item => item.packageId === packageId)[0];

            let options = [];

            selectedPackage.options.map(item => {
                if (item.quantity > 0) {
                    let optData = {
                        id: item.id,
                        quantity: item.quantity
                    }

                    options.push(optData);
                }
            })

            let data = {
                packageId: this.state.selectedPackageId,
                time: this.state.time,
                reservationDate: this.state.data.reservationDate,
                packageSpect: selectedPackage,
                priceId: selectedPackage.priceId,
                guideId: selectedPackage.guideId,
                options: options


            }


            this.props.book(data, types);
        });


    }

    breakdown = (action) => {
        this.setState({
            breakdown: action,
        })
    }



    selectedDate = (e) => {
        return moment(e).format("ll")
    }



    componentDidMount() {
        this.setState({
            data: this.props.data,
        })

    }

    updateBooking = (packageId) => {


        this.setState({
            selectedPackageId: packageId
        }, () => {

            let selectedPackage = this.state.data.data.filter(item => item.packageId === packageId)[0];

            let options = [];

            selectedPackage.options.map(item => {
                if (item.quantity > 0) {
                    let optData = {
                        id: item.id,
                        quantity: item.quantity
                    }

                    options.push(optData);
                }
            })

            let data = {
                packageId: this.state.selectedPackageId,
                time: this.state.time,
                reservationDate: this.state.data.reservationDate,
                packageSpect: selectedPackage,
                priceId: selectedPackage.priceId,
                guideId: selectedPackage.guideId,
                options: options
            }





            this.props.updateBook(data);
        });

        /*


        this.props.updateBook(bookId);
        console.log("asd");
        */

    }

    calculateTotalPrice = (packages) => {
        let totalSpoPrices = 0;
        let totalPrices = 0;
        let optionsTotal = 0;
        let optionsSpoTotal = 0;
        packages.totalPrice.map((item, i) => {
            totalSpoPrices += item.exchangeTotalSpoPrice.totalPrice;
            totalPrices += item.exchangeTotalPrice.totalPrice;
        });

        packages.options.map((items) => {
            if (items.quantity > 0) {

                if (items.priceType === 1) {
                    optionsTotal += items.exchangePrice * items.quantity;
                    optionsSpoTotal += items.exchangeSpoPrice * items.quantity;
                } else {
                    optionsTotal += items.exchangePrice;
                    optionsSpoTotal += items.exchangeSpoPrice;
                }
            }
        });



        return {
            totalPrices: totalPrices + optionsTotal,
            totalSpoPrices: totalSpoPrices + optionsSpoTotal,
            curr: packages.exchange.currInfo,
            spo: totalPrices === totalSpoPrices ? false : true,
            totalSpoRate: totalSpoPrices / totalPrices * 100,
            optionsTotal: optionsTotal,
            optionsSpoTotal: optionsSpoTotal
        }



    }
    minus = (y, i, ix, e) => {

        let currentQuantity = this.state.data.data[ix].options[i].quantity;

        if (currentQuantity > 0) {
            let cloneObj = Object.assign({}, this.state.data);
            cloneObj.data[ix].options[i].quantity = currentQuantity - 1

            this.setState({
                data: cloneObj

            }, () => {

            });
        }

    }

    plus = (y, i, ix, e) => {

        let currentQuantity = this.state.data.data[ix].options[i].quantity;

        if (currentQuantity === this.state.data.bookingSpect.maxReservation) {
        } else {
            let cloneObj = Object.assign({}, this.state.data);
            cloneObj.data[ix].options[i].quantity = currentQuantity + 1

            this.setState({
                data: cloneObj

            }, () => {
                //this.priceCalc()
            });
        }

    }

    openPanel = (ix) => {

        if (ix === this.state.selectedPackage) {

        } else {
            this.setState({
                selectedPackage: ix,
                time: false,
                clock: false,
                data: this.props.data
            });
        }


    }

    selectTime = (id, clock) => {

        this.setState({
            time: id,
            clock: clock
        });
    }
    selectedAlternative = (e) => {

        this.props.alternativeDate(e);
    }

    prevNavRef = React.createRef();
    nextNavRef = React.createRef();

    openDetail = (e) => {
        this.setState({
            openDetails: true,
            selectedId: e

        });
    }

    closeModal = () => {
        this.setState({
            openDetails: false,


        });
    }

    render() {

        const { data } = this.state.data;

        return (
            <>
                <div className="bookingResult">
                    {data.length > 0 ?
                        <>
                            
                            {data.map((packages, ix) => (
                                <>

                                    <div onClick={this.openPanel.bind(this, ix)} className={ix === this.state.selectedPackage ? "packages active" : 'packages'}>
                                        {this.calculateTotalPrice(packages).spo ?
                                            <div className='saveTo'>
                                                SAVE UP TO <CurrencyFormat value={100 - this.calculateTotalPrice(packages).totalSpoRate}
                                                    displayType={'text'}
                                                    decimalScale={0}
                                                    fixedDecimalScale={false}
                                                    thousandSeparator={true}
                                                    suffix={"% "} />

                                            </div>
                                            : ""
                                        }
                                        <div className='packageContents'>
                                            <div className='packageHeader'>
                                                <div className='photoGallery'>
                                                    <Fancybox >

                                                        <Swiper
                                                            modules={[Navigation]}
                                                            spaceBetween={7}
                                                            slidesPerView={1}
                                                            slidesPerGroup={1}
                                                            initialSlide={1}

                                                            navigation={{
                                                                prevEl: this.prevNavRef.current,
                                                                nextEl: this.nextNavRef.current,
                                                            }}
                                                            onInit={(swiper) => {
                                                                swiper.params.navigation.prevEl = this.prevNavRef.current;
                                                                swiper.params.navigation.nextEl = this.nextNavRef.current;
                                                                //    swiper.navigation.init(); // throws error here, navigation is undefined
                                                                //  swiper.navigation.update();


                                                            }}
                                                            loop={false}
                                                            lazy={false}
                                                        >
                                                            {packages.img.map((item, index) => (

                                                                <div key={index}>

                                                                    <SwiperSlide key={index}>

                                                                        <div className="sliderItem" style={{ backgroundImage: "url(" + process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/360/" + item.name + ")" }} data-fancybox="package"
                                                                            data-src={process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/1200/" + item.name}>

                                                                        </div>

                                                                    </SwiperSlide>

                                                                </div>
                                                            ))}

                                                        </Swiper>
                                                    </Fancybox>
                                                </div>



                                                <div className='left'>
                                                    <div className='likelyToSellOut'>
                                                        Likely to sell out
                                                    </div>
                                                    <div className='packageTitle'>
                                                        {packages.PackageTitle}
                                                    </div>
                                                    {packages.guideId !== null &&
                                                        <div className='guideLanguage'>
                                                            Language : {packages.guideLanguage}
                                                        </div>
                                                    }

                                                </div>
                                                <div className='right'>
                                                    <div className='totalPrice'>

                                                        <div className='title'>Total Price</div>
                                                        <div className='prices'>
                                                            {
                                                                this.calculateTotalPrice(packages).spo ?
                                                                    <div className='pr'>
                                                                        <div className='underlinePrice'>
                                                                            <CurrencyFormat value={this.calculateTotalPrice(packages).totalPrices}
                                                                                displayType={'text'}
                                                                                decimalScale={2}
                                                                                fixedDecimalScale={true}
                                                                                thousandSeparator={true}
                                                                                prefix={this.calculateTotalPrice(packages).curr + " "} />
                                                                        </div>
                                                                        <div className='price'>
                                                                            <CurrencyFormat value={this.calculateTotalPrice(packages).totalSpoPrices}
                                                                                displayType={'text'}
                                                                                decimalScale={2}
                                                                                fixedDecimalScale={true}
                                                                                thousandSeparator={true}
                                                                                prefix={this.calculateTotalPrice(packages).curr + " "} />
                                                                        </div>
                                                                    </div>
                                                                    : <div className='pr'>
                                                                        <div className='price'>
                                                                            <CurrencyFormat value={this.calculateTotalPrice(packages).totalSpoPrices}
                                                                                displayType={'text'}
                                                                                decimalScale={2}
                                                                                fixedDecimalScale={true}
                                                                                thousandSeparator={true}
                                                                                prefix={this.calculateTotalPrice(packages).curr + " "} />

                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                        <div className='tax'>All taxes and fees included</div>
                                                    </div>
                                                </div>
                                                {ix === this.state.selectedPackage ?

                                                    <div className='selectedPackageIcons'>
                                                        <img src="/Assets/img/icon/check.svg" alt='check' />
                                                    </div>

                                                    :
                                                    <div className='selectedPackageIcons'>
                                                        <img src="/Assets/img/icon/down-arrow.svg" alt='check' />
                                                    </div>
                                                }
                                            </div>
                                            {ix === this.state.selectedPackage &&
                                                <>
                                                    {packages.img.length > 0 &&
                                                        <div className='photo'>
                                                            <Fancybox >

                                                                <Swiper
                                                                    modules={[Navigation]}
                                                                    spaceBetween={7}
                                                                    slidesPerView={6}
                                                                    slidesPerGroup={1}
                                                                    initialSlide={1}

                                                                    navigation={{
                                                                        prevEl: this.prevNavRef.current,
                                                                        nextEl: this.nextNavRef.current,
                                                                    }}
                                                                    onInit={(swiper) => {
                                                                        swiper.params.navigation.prevEl = this.prevNavRef.current;
                                                                        swiper.params.navigation.nextEl = this.nextNavRef.current;
                                                                        //    swiper.navigation.init(); // throws error here, navigation is undefined
                                                                        //  swiper.navigation.update();


                                                                    }}
                                                                    loop={false}
                                                                    lazy={false}
                                                                >
                                                                    {packages.img.map((item, index) => (

                                                                        <div key={index}>

                                                                            <SwiperSlide key={index}>

                                                                                <div className="sliderItem" style={{ backgroundImage: "url(" + process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/360/" + item.name + ")" }} data-fancybox="package"
                                                                                    data-src={process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/1200/" + item.name}>

                                                                                </div>

                                                                            </SwiperSlide>

                                                                        </div>
                                                                    ))}

                                                                </Swiper>
                                                            </Fancybox>
                                                        </div>
                                                    }
                                                </>
                                            }

                                            <div className='options'>
                                                {packages.options.length > 0 &&
                                                    <div className='optionsTitle'>Add ons</div>
                                                }

                                                {packages.options.map((option, index) => (
                                                    <div className='option'>
                                                        <div className='name'>
                                                            <div className='title'>{option.optionsTitle}</div>
                                                            <div className='detail'>{option.optionsDetail}</div>
                                                        </div>
                                                        <div className="right">
                                                            <div className='optionPrice'>
                                                                {option.spoRate < 1 &&
                                                                    <div className='underlinePrice'>

                                                                        <CurrencyFormat value={option.exchangePrice}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            prefix={packages.exchange.currInfo + " "} />

                                                                    </div>
                                                                }
                                                                <div className='price'>
                                                                    <CurrencyFormat value={option.exchangeSpoPrice}
                                                                        displayType={'text'}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        thousandSeparator={true}
                                                                        prefix={packages.exchange.currInfo + " "} />



                                                                </div>
                                                            </div>
                                                            <div className="optionsPlus">
                                                                <div className="personNum">
                                                                    <div className="minus" onClick={this.minus.bind(this, "options" + index, index, ix)}>
                                                                        <img src="/Assets/img/icon/minus.svg" alt="Search" /></div>
                                                                    <div className="num">{option.quantity}</div>
                                                                    <div className="plus" data-vars="adultNum" onClick={this.plus.bind(this, "options" + index, index, ix)}>
                                                                        <img src="/Assets/img/icon/plus.svg" alt="Search" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='optionPrice'>
                                                                <div className='optionPrice'>
                                                                    {option.spoRate < 1 &&
                                                                        <div className='underlinePrice'>

                                                                            <CurrencyFormat value={
                                                                                option.priceType === 1 ?
                                                                                    option.exchangePrice * option.quantity : (option.priceType === 2 && option.quantity > 0) ? option.exchangePrice * 1 : option.exchangePrice * 0
                                                                            }
                                                                                displayType={'text'}
                                                                                decimalScale={2}
                                                                                fixedDecimalScale={true}
                                                                                thousandSeparator={true}
                                                                                prefix={packages.exchange.currInfo + " "} />

                                                                        </div>
                                                                    }
                                                                    <div className='price'>
                                                                        <CurrencyFormat value={
                                                                            option.priceType === 1 ?
                                                                                option.exchangeSpoPrice * option.quantity : (option.priceType === 2 && option.quantity > 0) ? option.exchangeSpoPrice * 1 : option.exchangeSpoPrice * 0
                                                                        }
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            prefix={packages.exchange.currInfo + " "} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='packageBody'>

                                                <div className='content'>
                                                    <div className='timeOption'>
                                                        <div className='selectStartTime'>Select a starting time</div>
                                                        <ul>
                                                            {
                                                                packages.times.map((times, i) => (

                                                                    <li className={times.id === this.state.time && "timeActive"} onClick={this.selectTime.bind(this, times.id, times.time)}>
                                                                        {(100 * times.lastQuota / times.quota) < 30 &&
                                                                            <></>
                                                                        }
                                                                        <div>{times.time}</div>
                                                                    </li>

                                                                ))
                                                            }
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dot">
                                            <div className='topCircle'></div>
                                            <div className='middle'></div>
                                            <div className='bottomCircle'></div>
                                        </div>
                                        <div className='resultTickets'>
                                            <div className='priceBreakDown'>
                                                <div className='unit'>Price break down</div>
                                                {packages.totalPrice.map((item, index) => (
                                                    <>
                                                        {item.exchangeTotalSpoPrice.num !== 0 &&
                                                            <div className='breakDown'>
                                                                <div className='blockName'>{
                                                                    packages.prices.filter(items => items.priceBlock === item.exchangeTotalSpoPrice.block).map(items => items.pricetitle)

                                                                } {item.exchangeTotalSpoPrice.num} x <CurrencyFormat value={item.exchangeTotalSpoPrice.price}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={true}
                                                                    prefix={packages.exchange.currInfo + " "} />

                                                                </div>
                                                                <div className='totalPrice'>
                                                                    <CurrencyFormat value={item.exchangeTotalSpoPrice.totalPrice}
                                                                        displayType={'text'}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        thousandSeparator={true}
                                                                        prefix={packages.exchange.currInfo + " "} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                ))}

                                                {packages.options.map((item, index) => (
                                                    <>
                                                        {item.quantity !== 0 &&
                                                            <div className='breakDown'>
                                                                <div className='blockName'>

                                                                    {item.optionsTitle} {item.quantity} x <CurrencyFormat value={item.exchangeSpoPrice}
                                                                        displayType={'text'}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        thousandSeparator={true}
                                                                        prefix={packages.exchange.currInfo + " "} />

                                                                </div>

                                                                <div className='totalPrice'>
                                                                    <CurrencyFormat value={item.priceType === 1 ? item.exchangeSpoPrice * item.quantity : item.exchangeSpoPrice}
                                                                        displayType={'text'}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        thousandSeparator={true}
                                                                        prefix={packages.exchange.currInfo + " "} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                ))}
                                            </div>

                                            <div className='packageDesc'>
                                                <div className='title'>Package description</div>
                                                {(packages.content.access !== null && packages.content.access.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Access</div>
                                                        <div className='subText'>
                                                            {packages.content.access.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }

                                                {(packages.content.activities !== null && packages.content.activities.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Activities</div>
                                                        <div className='subText'>
                                                            {packages.content.activities.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }

                                                {(packages.content.child !== null && packages.content.child.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Child</div>
                                                        <div className='subText'>
                                                            {packages.content.child.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }

                                                {(packages.content.driver !== null && packages.content.driver.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Driver</div>
                                                        <div className='subText'>
                                                            {packages.content.driver.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(packages.content.eligility !== null && packages.content.eligility.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Eligility</div>
                                                        <div className='subText'>
                                                            {packages.content.eligility.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }

                                                {(packages.content.excludes !== null && packages.content.excludes.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Excludes</div>
                                                        <div className='subText'>
                                                            {packages.content.excludes.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(packages.content.guides !== null && packages.content.guides.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Guides</div>
                                                        <div className='subText'>
                                                            {packages.content.guides.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }

                                                {(packages.content.limitations !== null && packages.content.limitations.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Limitations</div>
                                                        <div className='subText'>
                                                            {packages.content.limitations.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(packages.content.meals !== null && packages.content.meals.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Meals</div>
                                                        <div className='subText'>
                                                            {packages.content.meals.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(packages.content.pickup !== null && packages.content.pickup.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Pickup</div>
                                                        <div className='subText'>
                                                            {packages.content.pickup.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(packages.content.sightseeing !== null && packages.content.sightseeing.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Sightseeing</div>
                                                        <div className='subText'>
                                                            {packages.content.sightseeing.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(packages.content.stay !== null && packages.content.stay.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Stay</div>
                                                        <div className='subText'>
                                                            {packages.content.stay.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(packages.content.tickets !== null && packages.content.tickets.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Tickets</div>
                                                        <div className='subText'>
                                                            {packages.content.tickets.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(packages.content.transfer !== null && packages.content.transfer.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Transfer</div>
                                                        <div className='subText'>
                                                            {packages.content.transfer.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(packages.content.voucher !== null && packages.content.voucher.length > 0) &&
                                                    <>
                                                        <div className='subTitle'>Voucher</div>
                                                        <div className='subText'>
                                                            {packages.content.voucher.map((item, im) => (
                                                                <ul>
                                                                    <li>
                                                                        {item.text}
                                                                    </li>
                                                                </ul>
                                                            ))
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className='seeAll' onClick={this.openDetail.bind(this, ix)}>
                                                <div className="text">See all description</div><div className="icon"><img src="/Assets/img/icon/expands.svg" alt="Search" /></div>
                                            </div>
                                            <div className='buttons'>
                                                {!this.props.edit ?
                                                    <>
                                                        <div onClick={this.booking.bind(this, packages.packageId, "addToCart")} className={!this.state.time ? "addToCart disabled" : "addToCart"}>Add to cart</div>
                                                        <div onClick={this.booking.bind(this, packages.packageId, "checkOut")} className={!this.state.time ? "bookNow disabled" : "bookNow"}>Book Now</div>
                                                    </>
                                                    :
                                                    <div onClick={this.updateBooking.bind(this, packages.packageId, this.props.bookId)} className={!this.state.time ? "bookNow disabled" : "bookNow"}>Update Cart</div>
                                                }
                                            </div>
                                            {ix === this.state.selectedPackage &&
                                                <div className='cancelTime'>

                                                    {packages.cancelLeftTime.map((cancels, inx) => (
                                                        <>
                                                            {
                                                                cancels.map((cancel) => (
                                                                    <>
                                                                        {
                                                                            (cancel.status && cancel.clock === this.state.clock) ?
                                                                                <>
                                                                                    Cancel before {Moment(cancel.lastCancelDate).format('lll')}  for a %{cancel.refund} refund.<br></br>
                                                                                </>
                                                                                : ""
                                                                        }

                                                                    </>

                                                                ))
                                                            }
                                                        </>
                                                    ))
                                                    }

                                                </div>
                                            }
                                        </div>
                                    </div>



                                </>

                            ))}
                        </>
                        :
                        <>
                            <div className='error'>
                                Sorry, there's nothing available for that date and participant combination.
                            </div>
                            <div className="titleAlternative">Alternative dates</div>
                            <div className="alternative">
                                {this.state.data.before.map((item, index) => (
                                    <div className='item' onClick={this.selectedAlternative.bind(this, item)}>
                                        <div className='dates'>{this.selectedDate(item)}</div>
                                    </div>
                                ))}

                                {this.state.data.after.map((item, index) => (
                                    <div className='item' onClick={this.selectedAlternative.bind(this, item)}>
                                        <div className='dates'>{this.selectedDate(item)}</div>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                </div>
                {
                    this.state.openDetails &&
                    <>

                        <div className="modal">
                            <div className='modal-body'>

                                <div className="closeButton" onClick={this.closeModal.bind(this)}>
                                    <img src="/Assets/img/icon/cancel.svg" alt="icon" />
                                </div>

                                <div className='productPage'>
                                    <div className='packageDesc'>
                                        <div className='title'>Package description</div>


                                        {(data[this.state.selectedId].content.access !== null && data[this.state.selectedId].content.access.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/productIcon/10.svg" alt="icon" />Access</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.access.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }

                                        {(data[this.state.selectedId].content.activities !== null && data[this.state.selectedId].content.activities.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/icon/include.svg" alt="icon" />Activities</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.activities.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }

                                        {(data[this.state.selectedId].content.child !== null && data[this.state.selectedId].content.child.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/icon/user.svg" alt="icon" />Child</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.child.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }

                                        {(data[this.state.selectedId].content.driver !== null && data[this.state.selectedId].content.driver.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/icon/include.svg" alt="icon" />Driver</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.driver.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {(data[this.state.selectedId].content.eligility !== null && data[this.state.selectedId].content.eligility.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/icon/include.svg" alt="icon" />Eligility</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.eligility.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }

                                        {(data[this.state.selectedId].content.excludes !== null && data[this.state.selectedId].content.excludes.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/icon/notInclude.svg" alt="icon" />Excludes</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.excludes.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {(data[this.state.selectedId].content.guides !== null && data[this.state.selectedId].content.guides.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/productIcon/12.svg" alt="icon" />Guides</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.guides.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }

                                        {(data[this.state.selectedId].content.limitations !== null && data[this.state.selectedId].content.limitations.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/icon/include.svg" alt="icon" />Limitations</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.limitations.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {(data[this.state.selectedId].content.meals !== null && data[this.state.selectedId].content.meals.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/icon/include.svg" alt="icon" />Meals</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.meals.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {(data[this.state.selectedId].content.pickup !== null && data[this.state.selectedId].content.pickup.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/productIcon/4.svg" alt="icon" />Pickup</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.pickup.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {(data[this.state.selectedId].content.sightseeing !== null && data[this.state.selectedId].content.sightseeing.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/icon/include.svg" alt="icon" />Sightseeing</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.sightseeing.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {(data[this.state.selectedId].content.stay !== null && data[this.state.selectedId].content.stay.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/icon/include.svg" alt="icon" />Stay</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.stay.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {(data[this.state.selectedId].content.tickets !== null && data[this.state.selectedId].content.tickets.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/productIcon/9.svg" alt="icon" />Tickets</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.tickets.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {(data[this.state.selectedId].content.transfer !== null && data[this.state.selectedId].content.transfer.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/icon/include.svg" alt="icon" />Transfer</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.transfer.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {(data[this.state.selectedId].content.voucher !== null && data[this.state.selectedId].content.voucher.length > 0) &&
                                            <>
                                                <div className='subTitle'><img src="/Assets/img/productIcon/9.svg" alt="icon" />Voucher</div>
                                                <div className='subText'>
                                                    {data[this.state.selectedId].content.voucher.map((item, im) => (
                                                        <ul>
                                                            <li>
                                                                {item.text}
                                                            </li>
                                                        </ul>
                                                    ))
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-back-drop"></div>
                    </>
                }


            </>

        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(BookingResult)

