import React, { Component } from 'react'
import { connect } from 'react-redux'
import CurrencyFormat from 'react-currency-format';
import { updatePax, updatePrice } from "../Action/BookingEngineAction"


export class BookingPersonDetail extends Component {


    constructor(props) {
        super(props);
        const { engineData } = props;
        this.state = {
            maxContractPerson: [],
            minusDisable: false,
            plusDisable: false,
            query: {
                sessionId: engineData.selectedSession,
                priceId: engineData.sessionsData.filter(item => item.quotaId === engineData.selectedSession)[0].priceId,
                packageId: engineData.selectedPackage,
                productId: engineData.productId,
                date: engineData.selectedDate,
                block: props.data.id,

            }
        }

    }

    minus = () => {

        const { engineData } = this.props;
        if (!engineData.priceLoading) {


            let start = this.props.data.min > 0 ? this.props.data.min : 1
            if (this.props.data.pax > 0) {

                if (this.props.data.require) {

                    if (start < this.props.data.pax) {
                        const data = {
                            id: this.props.data.id,
                            pax: this.props.data.pax - 1
                        }
                        this.props.updatePax(data);
                        this.props.updatePrice(this.state.query, data.pax);
                    }


                } else {
                    let newPax;
                    if (this.props.data.pax === this.props.data.min) {
                        newPax = 0
                    } else {
                        newPax = this.props.data.pax - 1
                    }

                    const data = {
                        id: this.props.data.id,
                        pax: newPax
                    }

                    this.props.updatePax(data);
                    this.props.updatePrice(this.state.query, data.pax);


                }


            }
        }


    }

    plus = () => {
        const { engineData } = this.props;
        if (!engineData.priceLoading) {
            const data = {
                id: this.props.data.id,
                pax: this.props.data.pax + 1
            }
            const { engineData } = this.props;

            if (this.getTotalPax() < engineData.settingsData.maxReservation &&
                this.state.maxContractPerson > this.props.data.pax &&
                this.getTotalPax() < engineData.sessionsData.filter(item => item.quotaId === engineData.selectedSession)[0].quota
            ) {
                this.props.updatePax(data);
                this.props.updatePrice(this.state.query, data.pax);

            }
        }
    }

    getTotalPax = () => {

        const { block } = this.props.engineData;
        let totalPax = 0;
        block.map(item => {
            totalPax += item.pax;
        });

        return totalPax;
    }



    plusDisable = () => {
        const { engineData } = this.props;


        if (this.getTotalPax() === engineData.settingsData.maxReservation ||
            this.state.maxContractPerson === this.props.data.pax ||
            this.getTotalPax() === engineData.sessionsData.filter(item => item.quotaId === engineData.selectedSession)[0].quota
        ) {
            return true;
        }

    }




    componentDidMount() {

        const { engineData } = this.props;
        const { contracts } = this.props.data;

        let maxContractPerson = [];


        if (engineData.settingsData.confirmationType === 1) {
            contracts.map(items => {
                maxContractPerson.push(items.end);
            });

            maxContractPerson.sort(function (a, b) { return b - a })

            this.setState({
                maxContractPerson: maxContractPerson[0]
            })
        } else {

            contracts.map(items => {
                maxContractPerson.push(items);
            });

            maxContractPerson.sort(function (a, b) { return b - a })


            if (maxContractPerson[0].maxGroupPP) {

                this.setState({
                    maxContractPerson: engineData.settingsData.maxReservation
                })
            } else {
                this.setState({
                    maxContractPerson: maxContractPerson[0].end
                })
            }


        }

        this.props.updatePrice(this.state.query, this.props.data.pax);


    }


    getFirstPrice = () => {

        const { engineData } = this.props;

        let p = "";

        if (engineData.settingsData.confirmationType === 1) {

            this.props.data.contracts.map(items => {
                if (items.start <= this.props.data.pax &&
                    items.end >= this.props.data.pax) {

                    p = items;

                } else if (items.start === 1 && this.props.data.pax === 0) {

                    p = this.props.data.contracts[0]

                }
            })
        } else {



            this.props.data.contracts.map(items => {
                if (items.start <= this.props.data.pax &&
                    items.end >= this.props.data.pax) {

                    p = items;

                } else if (items.start === 1 && this.props.data.pax === 0) {

                    p = this.props.data.contracts[0]

                } else if (this.props.data.pax > items.end) {
                    p = this.props.data.contracts[this.props.data.contracts.length - 1]
                }
            })

        }

        return p;

    }




    render() {

        const { data, engineData } = this.props;

        return (
            <>

                <div className='option'>
                    <div className='name'>
                        <div className='title'>
                            {data.title} ({data.blockInfo.title} {data.blockInfo.start}  - {data.blockInfo.end})
                        </div>
                    </div>
                    <div className="right">
                        <div className='optionPrice'>

                            {this.getFirstPrice().spo &&

                                <div className='spo'>

                                    {

                                        <CurrencyFormat value={this.getFirstPrice().nonSpoPrice}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                            prefix={this.getFirstPrice().exchange.toSymbol + " "} />

                                    }
                                </div>
                            }
                            <div className='price'>
                                <CurrencyFormat value={this.getFirstPrice().price}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    prefix={this.getFirstPrice().exchange.toSymbol + " "} />
                            </div>
                        </div>
                        <div className="optionsPlus">
                            <div className="personNum">
                                <div className="minus" onClick={this.minus.bind(this)} >

                                    <img src="/Assets/img/icon/minus.svg" alt="Search" /></div>
                                <div className="num">
                                    {engineData.blockLoading &&

                                        <div className='lds-ellipsis' style={{
                                            top: "7px",

                                        }}>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    }
                                    {data.pax} {data.quantity.title}

                                </div>
                                <div className="plus" onClick={this.plus.bind(this)}>

                                    {(engineData.priceLoading || this.plusDisable()) ?
                                        <img src="/Assets/img/icon/plusDisable.svg" alt="Search" />
                                        :
                                        <img src="/Assets/img/icon/plus.svg" alt="Search" />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='optionPrice end'>
                            <div className='price'>
                                {(engineData.priceLoading && engineData.priceLoadingBlock === this.props.data.id) ?

                                    <div className='lds-ellipsis' style={{
                                        top: "-5px",
                                        left: "auto",
                                        right: "10px"
                                    }}>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    :
                                    <>
                                        {this.getFirstPrice().spo &&

                                            <div className='spo'>

                                                {

                                                    <CurrencyFormat value={data.nonSpoTotal}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        prefix={this.getFirstPrice().exchange.toSymbol + " "} />

                                                }
                                            </div>
                                        }
                                        <CurrencyFormat value={data.total}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                            prefix={this.getFirstPrice().exchange.toSymbol + " "} />
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>


            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    engineData: state.engineData,


});



export default connect(mapStateToProps, {
    updatePax,
    updatePrice
})(BookingPersonDetail)