import { BOOKING_GET } from '../../types'

const initialState = {
    bookingsData: [],
    loading: true
}



function BookingReducer(state = initialState, action) {

    switch (action.type) {

        case BOOKING_GET:
            return {
                ...state,
                bookingsData: action.payload,
                loading: false

            }
        default:
            return state
    }

}




export default BookingReducer;