import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/flatpickr.min.css'

class BookingPersonInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            person: [],
            sameAsBiling:props.sameAsBiling

        }

    }

    componentDidMount() {
       
        this.setPerson();

      

    }

    componentDidUpdate() {
        console.log(this.props.sameAsBiling);
        this.props.parentUpdate(this.state.person, this.props.id)
        


    }


    test =()=> {
        this.setState({
                a:1
            })
    }


    setBirthYear = () => {
        var dt = new Date();
        let birthYear = [];
        for (let i = 1930; i <= dt.getFullYear(); i++) {
            let obj = {
                label: i,
                value: i
            }

            birthYear.push(obj)
        }

        return birthYear;
    }

    setDay = () => {
        let days = [];
        for (let i = 1; i <= 31; i++) {
            let day = i;
            if (i < 10) {
                day = "0" + i
            }
            let obj = {
                label: day,
                value: day
            }

            days.push(obj)
        }

        return days;
    }

    setPerson = () => {

        if (this.props.data.guestInfo.masterPerson) {
            const obj = {
                item: 0,
                productId: this.props.productId,
                block: "lead",
                blockName: "Lead",
                name: "",
                surname: "",
                birthDay: "",
                birthMonth: "",
                birthYear: "",
                nationality: "",
                weight: "",
                size: "",
                gender: "",
                passportNumber: "",
                arrivalDate: "",
                arrivalTime: "",
                arrivalFlightNumber: "",
                departureDate: "",
                departureTime: "",
                departureFlightNumber: "",
            }
            this.setState(prevState => ({
                person: [...prevState.person, obj]
            }));
        } else {
            let ind = 0;
            this.props.data.prices.pricesDetail.map((item, index) => {

                [...Array(item.pax)].map((e, i) => {


                    const obj = {
                        item: ind,
                        block: item.block,
                        blockTitle: item.blockTitle,
                        productId: this.props.productId,
                        name: "",
                        surname: "",
                        birthDay: "",
                        birthMonth: "",
                        birthYear: "",
                        nationality: "",
                        weight: "",
                        size: "",
                        gender: "",
                        passportNumber: "",
                        arrivalDate: "",
                        arrivalTime: "",
                        arrivalFlightNumber: "",
                        departureDate: "",
                        departureTime: "",
                        departureFlightNumber: "",
                    }
                    this.setState(prevState => ({
                        person: [...prevState.person, obj]
                    }));
                    ind++;
                })
            })

        }

    }





    setWeight = () => {
        let weight = [];
        for (let i = 5; i <= 180; i++) {

            let obj = {
                label: i + " Kg",
                value: i
            }

            weight.push(obj)
        }

        return weight;
    }
    setSize = () => {
        let size = [];
        for (let i = 30; i <= 220; i++) {

            let obj = {
                label: i + " Cm",
                value: i
            }

            size.push(obj)
        }

        return size;
    }


    updatePerson = (e, x) => {


        let person = [...this.state.person];
        person[e] = {
            ...this.state.person[e], [x.target.name]: x.target.value
        };
        this.setState({
            person: person
        });
    }

    updatePersonSelect = (e, m) => {
        let person = [...this.state.person];
        person[m.name.i] = {
            ...this.state.person[m.name.i], [m.name.names]: e
        };
        this.setState({
            person: person
        });

    }


    updatePersonCalendar = (e, i) => {
        let person = [...this.state.person];
        person[i] = {
            ...this.state.person[i], [e.name]: e.value
        };
        this.setState({
            person: person
        });

    }


    render() {

        console.log(this.state)
        const { data } = this.props;

        return (
            <>


                <div className="needMoreInfo">We need more information to complete your booking </div>
                <div className="needMoreInfoProduct">"{data.product.productTitle}"</div>
                {this.state.person.map((item, index) => (

                    <div className="guestInfo" key={index}>
                        <div className="title">{data.guestInfo.masterPerson ? item.blockTitle : item.blockTitle + " " + (index + 1)}</div>
                        {index === 0 && this.props.sameAsBiling === true ?
                            <div className="row">
                                <div className="inputParent">
                                    <div className="title">Traveler Name *</div>
                                    <input type="text" maxLength="30" name="name" value={this.props.name} onChange={this.updatePerson.bind(this, index)} placeholder="Name" />
                                </div>

                                <div className="inputParent">
                                    <div className="title">Surname *</div>
                                    <input type="text" maxLength="30" name="surname" value={this.props.surname} onChange={this.updatePerson.bind(this, index)} placeholder="Surname"/>
                                </div>
                            </div>

                            :
                            <div className="row">
                                <div className="inputParent">
                                    <div className="title">Traveler Name *</div>
                                    <input type="text" maxLength="30" name="name" onChange={this.updatePerson.bind(this, index)} placeholder="Name" />
                                </div>

                                <div className="inputParent">
                                    <div className="title">Surname *</div>
                                    <input type="text" maxLength="30" name="surname" onChange={this.updatePerson.bind(this, index)} placeholder="Surname" />
                                </div>
                            </div>
                        }


                        {data.guestInfo.birthDate &&
                            <div className="row">
                                <div className="inputParent">
                                    <div className="title">Birth Day *</div>
                                    <Select
                                        value={item.birthDay}
                                        onChange={this.updatePersonSelect}
                                        options={this.setDay()}

                                        name={{
                                            names: "birthDay",
                                            i: index
                                        }}
                                        placeholder="DD"
                                        className="react-select" classNamePrefix="react-select"
                                    />
                                </div>
                                <div className="inputParent">
                                    <div className="title"></div>
                                    <Select
                                        value={item.birthMonth}
                                        onChange={this.updatePersonSelect}
                                        options={[
                                            { value: '01', label: '01 - Jan' },
                                            { value: '02', label: '02 - Feb' },
                                            { value: '03', label: '03 - Mar' },
                                            { value: '04', label: '04 - Apr' },
                                            { value: '05', label: '05 - May' },
                                            { value: '06', label: '06 - Jun' },
                                            { value: '07', label: '07 - Jul' },
                                            { value: '08', label: '08 - Agu' },
                                            { value: '09', label: '09 - Sep' },
                                            { value: '10', label: '10 - Oct' },
                                            { value: '11', label: '11 - Nov' },
                                            { value: '12', label: '12 - Dec' },
                                        ]}
                                        name={{
                                            names: "birthMonth",
                                            i: index
                                        }}
                                        placeholder="MM"
                                        className="react-select" classNamePrefix="react-select"
                                    />
                                </div>
                                <div className="inputParent">
                                    <div className="title"></div>
                                    <Select
                                        value={item.birthYear}
                                        onChange={this.updatePersonSelect}
                                        options={this.setBirthYear()}
                                        name={{
                                            names: "birthYear",
                                            i: index
                                        }}
                                        placeholder="YYYY"
                                        className="react-select" classNamePrefix="react-select"
                                    />
                                </div>
                            </div>
                        }
                        {data.guestInfo.nationality &&
                            <div className="inputParent">
                                <div className="title">Country *</div>
                                <Select
                                    value={item.nationality}
                                    onChange={this.updatePersonSelect}
                                    name={{
                                        names: "nationality",
                                        i: index
                                    }}
                                    options={[
                                        { label: "Afghanistan", value: "AF" },
                                        { label: "Åland Islands", value: "AX" },
                                        { label: "Albania", value: "AL" },
                                        { label: "Algeria", value: "DZ" },
                                        { label: "American Samoa", value: "AS" },
                                        { label: "Andorra", value: "AD" },
                                        { label: "Angola", value: "AO" },
                                        { label: "Anguilla", value: "AI" },
                                        { label: "Antarctica", value: "AQ" },
                                        { label: "Antigua and Barbuda", value: "AG" },
                                        { label: "Argentina", value: "AR" },
                                        { label: "Armenia", value: "AM" },
                                        { label: "Aruba", value: "AW" },
                                        { label: "Australia", value: "AU" },
                                        { label: "Austria", value: "AT" },
                                        { label: "Azerbaijan", value: "AZ" },
                                        { label: "Bahamas (the)", value: "BS" },
                                        { label: "Bahrain", value: "BH" },
                                        { label: "Bangladesh", value: "BD" },
                                        { label: "Barbados", value: "BB" },
                                        { label: "Belarus", value: "BY" },
                                        { label: "Belgium", value: "BE" },
                                        { label: "Belize", value: "BZ" },
                                        { label: "Benin", value: "BJ" },
                                        { label: "Bermuda", value: "BM" },
                                        { label: "Bhutan", value: "BT" },
                                        { label: "Bolivia (Plurinational State of)", value: "BO" },
                                        { label: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
                                        { label: "Bosnia and Herzegovina", value: "BA" },
                                        { label: "Botswana", value: "BW" },
                                        { label: "Bouvet Island", value: "BV" },
                                        { label: "Brazil", value: "BR" },
                                        { label: "British Indian Ocean Territory (the)", value: "IO" },
                                        { label: "Brunei Darussalam", value: "BN" },
                                        { label: "Bulgaria", value: "BG" },
                                        { label: "Burkina Faso", value: "BF" },
                                        { label: "Burundi", value: "BI" },
                                        { label: "Cabo Verde", value: "CV" },
                                        { label: "Cambodia", value: "KH" },
                                        { label: "Cameroon", value: "CM" },
                                        { label: "Canada", value: "CA" },
                                        { label: "Cayman Islands (the)", value: "KY" },
                                        { label: "Central African Republic (the)", value: "CF" },
                                        { label: "Chad", value: "TD" },
                                        { label: "Chile", value: "CL" },
                                        { label: "China", value: "CN" },
                                        { label: "Christmas Island", value: "CX" },
                                        { label: "Cocos (Keeling) Islands (the)", value: "CC" },
                                        { label: "Colombia", value: "CO" },
                                        { label: "Comoros (the)", value: "KM" },
                                        { label: "Congo (the Democratic Republic of the)", value: "CD" },
                                        { label: "Congo (the)", value: "CG" },
                                        { label: "Cook Islands (the)", value: "CK" },
                                        { label: "Costa Rica", value: "CR" },
                                        { label: "Croatia", value: "HR" },
                                        { label: "Cuba", value: "CU" },
                                        { label: "Curaçao", value: "CW" },
                                        { label: "Cyprus", value: "CY" },
                                        { label: "Czechia", value: "CZ" },
                                        { label: "Côte d'Ivoire", value: "CI" },
                                        { label: "Denmark", value: "DK" },
                                        { label: "Djibouti", value: "DJ" },
                                        { label: "Dominica", value: "DM" },
                                        { label: "Dominican Republic (the)", value: "DO" },
                                        { label: "Ecuador", value: "EC" },
                                        { label: "Egypt", value: "EG" },
                                        { label: "El Salvador", value: "SV" },
                                        { label: "Equatorial Guinea", value: "GQ" },
                                        { label: "Eritrea", value: "ER" },
                                        { label: "Estonia", value: "EE" },
                                        { label: "Eswatini", value: "SZ" },
                                        { label: "Ethiopia", value: "ET" },
                                        { label: "Falkland Islands (the) [Malvinas]", value: "FK" },
                                        { label: "Faroe Islands (the)", value: "FO" },
                                        { label: "Fiji", value: "FJ" },
                                        { label: "Finland", value: "FI" },
                                        { label: "France", value: "FR" },
                                        { label: "French Guiana", value: "GF" },
                                        { label: "French Polynesia", value: "PF" },
                                        { label: "French Southern Territories (the)", value: "TF" },
                                        { label: "Gabon", value: "GA" },
                                        { label: "Gambia (the)", value: "GM" },
                                        { label: "Georgia", value: "GE" },
                                        { label: "Germany", value: "DE" },
                                        { label: "Ghana", value: "GH" },
                                        { label: "Gibraltar", value: "GI" },
                                        { label: "Greece", value: "GR" },
                                        { label: "Greenland", value: "GL" },
                                        { label: "Grenada", value: "GD" },
                                        { label: "Guadeloupe", value: "GP" },
                                        { label: "Guam", value: "GU" },
                                        { label: "Guatemala", value: "GT" },
                                        { label: "Guernsey", value: "GG" },
                                        { label: "Guinea", value: "GN" },
                                        { label: "Guinea-Bissau", value: "GW" },
                                        { label: "Guyana", value: "GY" },
                                        { label: "Haiti", value: "HT" },
                                        { label: "Heard Island and McDonald Islands", value: "HM" },
                                        { label: "Holy See (the)", value: "VA" },
                                        { label: "Honduras", value: "HN" },
                                        { label: "Hong Kong", value: "HK" },
                                        { label: "Hungary", value: "HU" },
                                        { label: "Iceland", value: "IS" },
                                        { label: "India", value: "IN" },
                                        { label: "Indonesia", value: "ID" },
                                        { label: "Iran (Islamic Republic of)", value: "IR" },
                                        { label: "Iraq", value: "IQ" },
                                        { label: "Ireland", value: "IE" },
                                        { label: "Isle of Man", value: "IM" },
                                        { label: "Israel", value: "IL" },
                                        { label: "Italy", value: "IT" },
                                        { label: "Jamaica", value: "JM" },
                                        { label: "Japan", value: "JP" },
                                        { label: "Jersey", value: "JE" },
                                        { label: "Jordan", value: "JO" },
                                        { label: "Kazakhstan", value: "KZ" },
                                        { label: "Kenya", value: "KE" },
                                        { label: "Kiribati", value: "KI" },
                                        { label: "Korea (the Democratic People's Republic of)", value: "KP" },
                                        { label: "Korea (the Republic of)", value: "KR" },
                                        { label: "Kuwait", value: "KW" },
                                        { label: "Kyrgyzstan", value: "KG" },
                                        { label: "Lao People's Democratic Republic (the)", value: "LA" },
                                        { label: "Latvia", value: "LV" },
                                        { label: "Lebanon", value: "LB" },
                                        { label: "Lesotho", value: "LS" },
                                        { label: "Liberia", value: "LR" },
                                        { label: "Libya", value: "LY" },
                                        { label: "Liechtenstein", value: "LI" },
                                        { label: "Lithuania", value: "LT" },
                                        { label: "Luxembourg", value: "LU" },
                                        { label: "Macao", value: "MO" },
                                        { label: "Madagascar", value: "MG" },
                                        { label: "Malawi", value: "MW" },
                                        { label: "Malaysia", value: "MY" },
                                        { label: "Maldives", value: "MV" },
                                        { label: "Mali", value: "ML" },
                                        { label: "Malta", value: "MT" },
                                        { label: "Marshall Islands (the)", value: "MH" },
                                        { label: "Martinique", value: "MQ" },
                                        { label: "Mauritania", value: "MR" },
                                        { label: "Mauritius", value: "MU" },
                                        { label: "Mayotte", value: "YT" },
                                        { label: "Mexico", value: "MX" },
                                        { label: "Micronesia (Federated States of)", value: "FM" },
                                        { label: "Moldova (the Republic of)", value: "MD" },
                                        { label: "Monaco", value: "MC" },
                                        { label: "Mongolia", value: "MN" },
                                        { label: "Montenegro", value: "ME" },
                                        { label: "Montserrat", value: "MS" },
                                        { label: "Morocco", value: "MA" },
                                        { label: "Mozambique", value: "MZ" },
                                        { label: "Myanmar", value: "MM" },
                                        { label: "Namibia", value: "NA" },
                                        { label: "Nauru", value: "NR" },
                                        { label: "Nepal", value: "NP" },
                                        { label: "Netherlands (the)", value: "NL" },
                                        { label: "New Caledonia", value: "NC" },
                                        { label: "New Zealand", value: "NZ" },
                                        { label: "Nicaragua", value: "NI" },
                                        { label: "Niger (the)", value: "NE" },
                                        { label: "Nigeria", value: "NG" },
                                        { label: "Niue", value: "NU" },
                                        { label: "Norfolk Island", value: "NF" },
                                        { label: "Northern Mariana Islands (the)", value: "MP" },
                                        { label: "Norway", value: "NO" },
                                        { label: "Oman", value: "OM" },
                                        { label: "Pakistan", value: "PK" },
                                        { label: "Palau", value: "PW" },
                                        { label: "Palestine, State of", value: "PS" },
                                        { label: "Panama", value: "PA" },
                                        { label: "Papua New Guinea", value: "PG" },
                                        { label: "Paraguay", value: "PY" },
                                        { label: "Peru", value: "PE" },
                                        { label: "Philippines (the)", value: "PH" },
                                        { label: "Pitcairn", value: "PN" },
                                        { label: "Poland", value: "PL" },
                                        { label: "Portugal", value: "PT" },
                                        { label: "Puerto Rico", value: "PR" },
                                        { label: "Qatar", value: "QA" },
                                        { label: "Republic of North Macedonia", value: "MK" },
                                        { label: "Romania", value: "RO" },
                                        { label: "Russian Federation (the)", value: "RU" },
                                        { label: "Rwanda", value: "RW" },
                                        { label: "Réunion", value: "RE" },
                                        { label: "Saint Barthélemy", value: "BL" },
                                        { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
                                        { label: "Saint Kitts and Nevis", value: "KN" },
                                        { label: "Saint Lucia", value: "LC" },
                                        { label: "Saint Martin (French part)", value: "MF" },
                                        { label: "Saint Pierre and Miquelon", value: "PM" },
                                        { label: "Saint Vincent and the Grenadines", value: "VC" },
                                        { label: "Samoa", value: "WS" },
                                        { label: "San Marino", value: "SM" },
                                        { label: "Sao Tome and Principe", value: "ST" },
                                        { label: "Saudi Arabia", value: "SA" },
                                        { label: "Senegal", value: "SN" },
                                        { label: "Serbia", value: "RS" },
                                        { label: "Seychelles", value: "SC" },
                                        { label: "Sierra Leone", value: "SL" },
                                        { label: "Singapore", value: "SG" },
                                        { label: "Sint Maarten (Dutch part)", value: "SX" },
                                        { label: "Slovakia", value: "SK" },
                                        { label: "Slovenia", value: "SI" },
                                        { label: "Solomon Islands", value: "SB" },
                                        { label: "Somalia", value: "SO" },
                                        { label: "South Africa", value: "ZA" },
                                        { label: "South Georgia and the South Sandwich Islands", value: "GS" },
                                        { label: "South Sudan", value: "SS" },
                                        { label: "Spain", value: "ES" },
                                        { label: "Sri Lanka", value: "LK" },
                                        { label: "Sudan (the)", value: "SD" },
                                        { label: "Suriname", value: "SR" },
                                        { label: "Svalbard and Jan Mayen", value: "SJ" },
                                        { label: "Sweden", value: "SE" },
                                        { label: "Switzerland", value: "CH" },
                                        { label: "Syrian Arab Republic", value: "SY" },
                                        { label: "Taiwan (Province of China)", value: "TW" },
                                        { label: "Tajikistan", value: "TJ" },
                                        { label: "Tanzania, United Republic of", value: "TZ" },
                                        { label: "Thailand", value: "TH" },
                                        { label: "Timor-Leste", value: "TL" },
                                        { label: "Togo", value: "TG" },
                                        { label: "Tokelau", value: "TK" },
                                        { label: "Tonga", value: "TO" },
                                        { label: "Trinidad and Tobago", value: "TT" },
                                        { label: "Tunisia", value: "TN" },
                                        { label: "Turkey", value: "TR" },
                                        { label: "Turkmenistan", value: "TM" },
                                        { label: "Turks and Caicos Islands (the)", value: "TC" },
                                        { label: "Tuvalu", value: "TV" },
                                        { label: "Uganda", value: "UG" },
                                        { label: "Ukraine", value: "UA" },
                                        { label: "United Arab Emirates (the)", value: "AE" },
                                        { label: "United Kingdom of Great Britain and Northern Ireland (the)", value: "GB" },
                                        { label: "United States Minor Outlying Islands (the)", value: "UM" },
                                        { label: "United States of America (the)", value: "US" },
                                        { label: "Uruguay", value: "UY" },
                                        { label: "Uzbekistan", value: "UZ" },
                                        { label: "Vanuatu", value: "VU" },
                                        { label: "Venezuela (Bolivarian Republic of)", value: "VE" },
                                        { label: "Viet Nam", value: "VN" },
                                        { label: "Virgin Islands (British)", value: "VG" },
                                        { label: "Virgin Islands (U.S.)", value: "VI" },
                                        { label: "Wallis and Futuna", value: "WF" },
                                        { label: "Western Sahara", value: "EH" },
                                        { label: "Yemen", value: "YE" },
                                        { label: "Zambia", value: "ZM" },
                                        { label: "Zimbabwe", value: "ZW" },

                                    ]}
                                    placeholder="Nationality"
                                    className="react-select" classNamePrefix="react-select"
                                />
                            </div>
                        }
                        <div className="row">
                            {data.guestInfo.weight &&

                                <div className="inputParent">
                                    <div className="title">Weight *</div>
                                    <Select
                                        value={item.weight}
                                        onChange={this.updatePersonSelect}
                                        name={{
                                            names: "weight",
                                            i: index
                                        }}
                                        options={this.setWeight()}
                                        placeholder="Weight"
                                        className="react-select" classNamePrefix="react-select"
                                    />
                                </div>
                            }

                            {data.guestInfo.size &&
                                <div className="inputParent">
                                    <div className="title">Height *</div>
                                    <Select
                                        value={item.size}
                                        onChange={this.updatePersonSelect}
                                        name={{
                                            names: "size",
                                            i: index
                                        }}
                                        options={this.setSize()}
                                        placeholder="Height"
                                        className="react-select" classNamePrefix="react-select"
                                    />
                                </div>
                            }

                            {data.guestInfo.gender &&
                                <div className="inputParent">
                                    <div className="title">Gender *</div>
                                    <Select
                                        value={item.gender}
                                        onChange={this.updatePersonSelect}
                                        name={{
                                            names: "gender",
                                            i: index
                                        }}
                                        options={[
                                            { value: 'Male', label: 'Male' },
                                            { value: 'Female', label: 'Female' },
                                        ]}
                                        placeholder="Gender"
                                        className="react-select" classNamePrefix="react-select"
                                    />
                                </div>
                            }



                        </div>
                        {data.guestInfo.arrival &&
                            <div className="inputParent">
                                <div className="title">Passport Number *</div>
                                <input type="text" onChange={this.updatePerson.bind(this, index)} name="passportNumber" placeholder="Passport Number" />
                            </div>
                        }
                        {data.guestInfo.arrival &&
                            <>
                                <div className="flightTitle">Arrival</div>
                                <div className="row">
                                    <div className="inputParent mt0">
                                        <div className="title">Date *</div>
                                        <Flatpickr
                                            placeholder="Select date"
                                            options={{
                                                minDate: "today",
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d",
                                            }}
                                            onChange={(selectedDates, dateStr, instance) => {
                                                let e = {
                                                    name: "departureDate",
                                                    value: dateStr
                                                }
                                                this.updatePersonCalendar(e, index)

                                            }}
                                        />
                                    </div>
                                    <div className="inputParent mt0">
                                        <div className="title">Time *</div>
                                        <Flatpickr
                                            placeholder="Select time"
                                            options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true
                                            }}
                                            onChange={(selectedDates, dateStr, instance) => {
                                                let e = {
                                                    name: "departureTime",
                                                    value: dateStr
                                                }
                                                this.updatePersonCalendar(e, index)

                                            }}
                                        />
                                    </div>
                                    <div className="inputParent mt0">
                                        <div className="title">Flight number *</div>
                                        <input type="text" onChange={this.updatePerson.bind(this, index)} name="arrivalFlightNumber" placeholder="Flight number" />
                                    </div>

                                </div>
                            </>
                        }

                        {data.guestInfo.departure &&
                            <>
                                <div className="flightTitle">Departure</div>
                                <div className="row">
                                    <div className="inputParent mt0">
                                        <div className="title">Date *</div>
                                        <Flatpickr
                                            placeholder="Select date"
                                            options={{
                                                minDate: "today",
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d",
                                            }}
                                        />
                                    </div>
                                    <div className="inputParent mt0">
                                        <div className="title">Time *</div>
                                        <Flatpickr
                                            placeholder="Select time"
                                            options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true
                                            }}
                                        />
                                    </div>
                                    <div className="inputParent mt0">
                                        <div className="title">Flight number *</div>
                                        <input type="text" onChange={this.updatePerson.bind(this, index)} name="departureFlightNumber" placeholder="Flight number" />
                                    </div>

                                </div>
                            </>
                        }


                    </div>

                ))}

            </>
        )
    }
}

const mapStateToProps = (state) => ({

    appData: state.appData,
});

export default connect(mapStateToProps)(BookingPersonInfo)
