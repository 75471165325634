import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoadingSmall from '../../../Helpers/LoadingSmall';
import axios from "axios";
import Book from './Book';
import "../../../Assets/cart.css"
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            checked: true,

        }
    }


    componentDidMount() {
        this.getBook()

    }

    getBook = async () => {
        this.setState({

            loading: true
        })


        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getCart`)

            if (resp.status === 200) {
                const result = resp.data

                this.setState({
                    data: result,
                    loading: false
                })

                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        } catch (err) {
            console.error(err);
        }
    }


    refresh = () => {
        this.getBook();
    }

    render() {
        const { data } = this.state
 

        return (
            <>

                <div className="cart">

                    <div className="content">
                        <div className="cartResult">
                            <div className="title">Shopping cart</div>
                            <div className="subTitle"><b>Create an account</b> or <b>log in</b> for faster checkout.</div>
                            {this.state.loading ? <LoadingSmall /> :
                                <>
                                    {(data.return !== false && data.bookings.length > 0) ?
                                        <>
                                            {this.state.data.bookings.map((item, index) => (
                                                <Book key={index} data={item} refresh={this.refresh.bind(this)} />
                                            ))}
                                        </>
                                        :
                                        <div className='emptyCart'>
                                            <div className='emptyTitle'>
                                                Your cart is empty
                                            </div>
                                            <div className='emptyText'>Once you add an activity to your cart, we will save your spot for 60 minutes.</div>
                                        </div>
                                    }
                                </>
                            }

                        </div>
                        {this.state.loading ? <LoadingSmall /> :
                            <>
                                {(data.return !== false && data.bookings.length > 0) &&
                                    <div className="bullet">

                                        <div className="checkOut">
                                            <div className="grandTotal">Grand Total</div>
                                            {this.state.loading ? <LoadingSmall /> :
                                                <>
                                                    <div className="priceDisc">
                                                        {(data.bookings.length > 0 &&
                                                            data.cartGrand.cartGrandTotal !== data.cartGrand.cartGrandTotalSpo) &&
                                                            <>

                                                                <CurrencyFormat value={data.cartGrand.cartGrandTotal}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={true}
                                                                    prefix={data.bookings[0].exchangeInfo.symbol + " "} />

                                                            </>
                                                        }
                                                    </div>
                                                    <div className="price">
                                                        {data.bookings.length > 0 &&
                                                            <>

                                                                <CurrencyFormat value={data.cartGrand.cartGrandTotalSpo}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={true}
                                                                    prefix={data.bookings[0].exchangeInfo.symbol + " "} />

                                                            </>
                                                        }
                                                    </div>

                                                </>
                                            }



                                            <div className='tax'>All taxes and fees included</div>
                                            <Link to="/CheckOut/AllBooking">
                                                <div className="CheckOut">Check out</div>
                                            </Link>
                                        </div>
                                        <div className='login'><b>Create an account</b> or <b>log in</b> for faster checkout.</div>
                                        <div className="securePayment">
                                            <div className="title">Secure Payment</div>
                                            <div className="detail">Your info's safe with us. All data is encrypted and transmitted securely with an SSL protocol.
                                                Tourzone respects your privacy. We do not sell your personal information to anyone. Privacy Statement »</div>
                                        </div>

                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>

            </>
        )
    }
}

const mapStateToProps = (state) => ({

    appData: state.appData,
});

export default connect(mapStateToProps)(Cart)
