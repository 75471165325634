import React, { Component } from 'react'
import { connect } from 'react-redux'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import 'swiper/swiper.min.css'


class TravelTips extends Component {
    componentDidMount() {
        const sliderEl = document.querySelector('.carousel-slider');

        this.createCarouselSlider(sliderEl)
    }
    createCarouselSlider = (el) => {
        const swiperEl = el.querySelector('.swipers');
        const swiper = new SwiperCore(swiperEl, {
            modules: [Autoplay, Navigation, Pagination],
            grabCursor: true,
            watchSlidesProgress: true,
            loop: true,
            loopedSlides: 10,
            slidesPerView: 'auto',
            centeredSlides: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            pagination: {
                el: '.swiper-pagination',
            },
            autoplay: {
                delay: 5000,
            },
            on: {

                // eslint-disable-next-line
                progress(swiper) {
                    const scaleStep = 0.2;
                    const zIndexMax = swiper.slides.length;
                    for (let i = 0; i < swiper.slides.length; i += 1) {
                        const slideEl = swiper.slides[i];
                        const slideProgress = swiper.slides[i].progress;
                        const absProgress = Math.abs(slideProgress);
                        let modify = 1;
                        if (absProgress > 1) {
                            modify = (absProgress - 1) * 0.3 + 1;
                        }
                        const opacityEls = slideEl.querySelectorAll(
                            '.carousel-slider-animate-opacity',
                        );
                        const translate = `${slideProgress * modify * 50}%`;
                        const scale = 1 - absProgress * scaleStep;
                        const zIndex = zIndexMax - Math.abs(Math.round(slideProgress));
                        slideEl.style.transform = `translateX(${translate}) scale(${scale})`;
                        slideEl.style.zIndex = zIndex;
                        if (absProgress > 3) {
                            slideEl.style.opacity = 0;
                        } else {
                            slideEl.style.opacity = 1;
                        }

                        opacityEls.forEach((opacityEl) => {
                            opacityEl.style.opacity = 1 - absProgress / 3;
                        });
                    }
                },
                // eslint-disable-next-line
                setTransition(swiper, duration) {
                    for (let i = 0; i < swiper.slides.length; i += 1) {
                        const slideEl = swiper.slides[i];
                        const opacityEls = slideEl.querySelectorAll(
                            '.carousel-slider-animate-opacity',
                        );
                        slideEl.style.transitionDuration = `${duration}ms`;
                        opacityEls.forEach((opacityEl) => {
                            opacityEl.style.transitionDuration = `${duration}ms`;
                        });
                    }
                },
            },
        });






        //return swiper;
    }
    render() {
        const { subRegion, importantToKnow } = this.props.data;
        console.log(this.props.data)
        return (
            <>


                <div className='content'>
                <div className='travelTipsTitle'>TRAVEL TIPS</div>
                    <div className='travelTips'  dangerouslySetInnerHTML={{ __html: importantToKnow.travelTips }}/>
                    <div className="tips">
                        <div className='items'>
                            <div className='icon'><img src="/Assets/img/icon/weather.svg" alt='icon' /></div>
                            <div className='title'>Local Weather</div>
                            <div className='text'>23 C° / 73 F°</div>
                        </div>
                        <div className='items'>
                            <div className='icon'><img src="/Assets/img/icon/time-zone.svg" alt='icon' /></div>
                            <div className='title'>Timezone</div>
                            <div className='text'>Europe/Athens (GMT+02:00)</div>
                        </div>

                        <div className='items'>
                            <div className='icon'><img src="/Assets/img/icon/language_icon.svg" alt='icon' /></div>
                            <div className='title'>Language</div>
                            <div className='text'>TR</div>
                        </div>
                        <div className='items'>
                            <div className='icon'><img src="/Assets/img/icon/money.svg" alt='icon' /></div>
                            <div className='title'>Currency</div>
                            <div className='text'>TRY</div>
                        </div>
                        <div className='items'>
                            <div className='icon'><img src="/Assets/img/icon/peakseason.svg" alt='icon' /></div>
                            <div className='title'>Peak seasons</div>
                            <div className='text'>Summer Times (April - October)</div>
                        </div>
                        <div className='items'>
                            <div className='icon'><img src="/Assets/img/icon/plug.svg" alt='icon' /></div>
                            <div className='title'>Travel Adaptor</div>
                            <div className='text'>220 V - 50 Hz - Type F</div>
                        </div>
                    </div>
                    <div className='travelTips'  dangerouslySetInnerHTML={{ __html: importantToKnow.importInfo }}/>
                    <div className='topRegion'>
                        <div className='title'>TOP REGION</div>
                        <div className="carousel-slider">
                            <div className="swipers">
                                <div className="swiper-wrapper">
                                    {subRegion.map((item, index) => (

                                        <div className="swiper-slide" key={index}>
                                            <div className="carousel-slider-animate-opacity">
                                                <img src={process.env.REACT_APP_ASSETS_ADDRESS + "images/destinations/" + item.img} alt="" />
                                                <div className="slide-content">
                                                    <h2>{item.name}</h2>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>

                                <div className="swiper-button-prev"></div>
                                <div className="swiper-button-next"></div>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                    <div className='importantToKnow'>
                        
                        <div className='importantContent'>
                            <div className='icons'><img src='/Assets/img/icon/open-box.svg' alt="icon" /></div>
                            <div className='detail'>
                                <div className='title'>WHAT TO PACK</div>
                                <div className='text' dangerouslySetInnerHTML={{ __html: importantToKnow.whatToPack }}></div>
                            </div>
                        </div>

                        <div className='importantContent'>
                            <div className='icons'><img src='/Assets/img/icon/book3.svg' alt="icon" /></div>
                            <div className='detail'>
                                <div className='title'>KNOW BEFORE YOU GO</div>
                                <div className='text' dangerouslySetInnerHTML={{ __html: importantToKnow.knowBeforeYou }}></div>
                            </div>
                        </div>

                        <div className='importantContent'>
                            <div className='icons'><img src='/Assets/img/icon/usefultips.svg' alt="icon" /></div>
                            <div className='detail'>
                                <div className='title'>ADDITIONAL INFORMATION</div>
                                <div className='text' dangerouslySetInnerHTML={{ __html: importantToKnow.additionalInfo }}></div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
})

export default connect(mapStateToProps)(TravelTips)
