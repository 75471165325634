import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setGuides, setSessions, BookingGetBlockInfo } from "../Action/BookingEngineAction"
export class BookingSesssionAndGuide extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    setGuide = (i) => {

        this.props.setGuides(i);

    }


    setSession = (i) => {


        const { engineData } = this.props;




        this.props.setSessions(i);

        const data = {

            sessionId: engineData.selectedSession,
            priceId: engineData.sessionsData.filter(item => item.quotaId === engineData.selectedSession)[0].priceId,
            packageId: engineData.selectedPackage,
            productId: engineData.productId,
            date: engineData.selectedDate
        }

        this.props.BookingGetBlockInfo(data);

    }


    render() {

        const { guidesData, sessionsData } = this.props.engineData
        return (
            <>
                {guidesData.length > 0 &&
                    <div className='guides'>
                        <div className='guideTitle'>
                            Preferred Language
                        </div>
                        <div className='guideItem'>
                            {guidesData.map((item, index) => (
                                <div onClick={this.setGuide.bind(this, item.id)} key={index}
                                    className={!item.status ? "guide disabled" : (
                                        this.props.engineData.selectedGuide === item.id ? "guide active" : "guide default")}
                                >{item.language} {!item.status && " (Not Available)"}</div>

                            ))}
                        </div>
                    </div>
                }
                {sessionsData.length > 0 &&
                    <div className='sessions'>
                        <div className='sessionTitle'>
                            Preferred Session time
                        </div>
                        <div className='sessionItem'>
                            {sessionsData.map((item, index) => (

                                <>

                                    {item.status ?
                                        <div onClick={this.setSession.bind(this, item.quotaId)} key={index}
                                            className={this.props.engineData.selectedSession === item.quotaId ? "session active" : "session default"}
                                        >
                                            {item.time}
                                            {item.percent > 50 &&
                                                <>
                                                    <div className='soldOut'>
                                                        !
                                                    </div>
                                                    <div className="tooltip">
                                                    Likely to sell out
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        : <div key={index} className="session disabled">
                                            {item.time} (Not Available)
                                        </div>
                                    }

                                </>


                            ))}
                        </div>


                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    engineData: state.engineData

});

export default connect(mapStateToProps, {
    setGuides,
    setSessions,
    BookingGetBlockInfo,
    /*
    setGuides, setSessions
*/
})(BookingSesssionAndGuide)