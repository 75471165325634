import React, { Component } from 'react'
import { connect } from 'react-redux'

import LoadingSmall from '../../../Helpers/LoadingSmall'
import { bookingSettingAction } from "../Action/BookingEngineAction"
import BookingCalendar from './BookingCalendar';
import BookingOptions from './BookingOptions';
import BookingPackage from './BookingPackage';
import BookingPackageDetail from './BookingPackageDetail';
import BookingPerson from './BookingPerson';
import BookingSesssionAndGuide from './BookingSesssionAndGuide';
import axios from "axios"
import CurrencyFormat from 'react-currency-format';
import BookingFixedBar from './BookingFixedBar';

class BookingEngine extends Component {

    constructor(props) {
        super(props);

        this.state = {
            productId: props.productId,
            confirmBooking: false,
            actions: "",
            steps: 1,

        }

    }

    closeConfirmBooking = () => {
        this.setState({
            confirmBooking: false
        })
    }


    componentDidMount() {
        this.props.bookingSettingAction(this.state.productId);
    }



    getProductDetail = () => {

    }

    setBookingProcess = (actions) => {

        this.setState({
            confirmBooking: true,
            actions: actions
        })

    }

    getTotalPrice = () => {

        const { engineData } = this.props
        let total = 0;
        let nonSpoTotal = 0;

        let optionsTotal = 0;
        let nonSpoOptionsTotal = 0;

        engineData.block.map(item => {
            total += item.total;
            nonSpoTotal += item.nonSpoTotal
        });


        engineData.options.map(item => {
            optionsTotal += item.total;
            nonSpoOptionsTotal += item.nonSpoTotal;
        });



        return {
            total: total + optionsTotal,
            nonSpoTotal: nonSpoTotal + nonSpoOptionsTotal,
            // exchange:engineData.block[0].exchange

        }

    }



    getReorgBlock = () => {







    }



    getBookingProcess = async (types) => {


        const { engineData } = this.props;

        let priceId = engineData.sessionsData.filter(item => item.quotaId === engineData.selectedSession)[0].priceId;


        const data = {
            productId: engineData.productId,
            packageId: engineData.selectedPackage,
            guideId: engineData.selectedGuide,
            sessionId: engineData.selectedSession,
            priceId: priceId,
            reservationDate: engineData.selectedDate,
            bookingPerson: engineData.block,
            options: engineData.options,
            bookId: this.props.bookId


            /*

            result:{
                packageId:engineData.selectedPackage,
                priceId:priceId,
                guideId:engineData.selectedGuide,
                time:engineData.selectedSession,
                reservationDate:engineData.selectedDate,
                packageSpect:[],
                options:engineData.options
            }
            */


        }

        if (this.getTotalPax() > 0) {
            try {

                const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/actionCart`, data)

                if (resp.status === 200) {


                    this.setState({
                        cartLoading: false
                    });

                    const data = resp.data
                    if (data.status) {

                        if (this.props.edit) {
                            this.props.complete()
                        } else {

                            if (types === "bookNow") {
                                this.props.history.push('/checkout/' + resp.data.reservationId)
                            } else {
                                this.props.history.push('/Cart');
                            }
                        }
                    }


                }
            } catch (err) {
                console.error(err);
            }

        }


        // this.props.getBookingProcessProps(actions)
    }


    getTotalPax = () => {

        const { block } = this.props.engineData;
        let totalPax = 0;
        block.map(item => {
            totalPax += item.pax;
        });

        return totalPax;
    }
    getNextStep = () => {
        if (this.getTotalPax() > 0) {
            this.setState({
                steps: this.state.steps + 1
            });
        }
    }

    getBackStep = () => {
        this.setState({
            steps: this.state.steps - 1
        })
    }



    render() {

        const { engineData } = this.props

        console.log(this.props)

        return (
            <div className='bookingNew spy' id="bookingSpy">
                {!this.props.edit &&
                <div className='bookingNewTitle'>
                    Booking Details
                </div>
    }

                {engineData.settingsLoading ? <LoadingSmall /> :

                    <div className='bookingBody'>
                        <div className='bookingEngineBody'>
                            <div className='wizard'>
                                {engineData.options.length > 0 &&
                                    <div className='wizardHeard'>
                                        <ul className='step'>
                                            <li className={this.state.steps === 1 ? 'active' : ""}>
                                                <div className='number'>1</div>
                                                <div className='stepTitle'>Booking Detail</div>
                                            </li>
                                            <li className={this.state.steps === 2 ? 'active' : ""}>
                                                <div className='number'>2</div>
                                                <div className='stepTitle'>Add Ons</div>
                                            </li>
                                        </ul>

                                    </div>
                                }
                                <div className='wizardBody'>

                                    <div className='stepsBody' style={{ display: this.state.steps === 1 ? "block" : "none" }}>
                                        {(engineData.selectedPackage !== null && 
                                        !engineData.packagesLoading) &&
                                            <BookingCalendar />
                                        }
                                        <BookingPackage />
                                        {(engineData.selectedDate !== "" && engineData.selectedPackage !== null) &&
                                            <BookingSesssionAndGuide />
                                        }

                                        {(engineData.selectedSession !== null && 
                                        engineData.sessionsData.length > 0 && engineData.selectedPackage !== null) &&
                                            <BookingPerson getBookingProcessProps={this.setBookingProcess} />
                                        }

                                    </div>


                                    <div className='stepsBody' style={{ display: this.state.steps === 2 ? "block" : "none" }}>
                                        {(engineData.selectedSession !== null && engineData.sessionsData.length > 0) &&
                                            <BookingOptions />
                                        }
                                    </div>

                                </div>

                            </div>



                            {(engineData.selectedSession !== null && engineData.sessionsData.length > 0) &&
                                <div className='bookingEngineFooter'>
                                    <div className='totalPrices'>

                                        {(engineData.priceLoading || engineData.optionsPriceLoading) ?
                                            <div className='lds-ellipsis' style={{
                                                top: "7px",
                                                left: "15px",
                                                right: "auto"

                                            }}>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                            :
                                            <CurrencyFormat value={this.getTotalPrice().total}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                prefix={" "} />
                                        }
                                    </div>
                                    {engineData.options.length > 0 ?
                                        <>
                                            {this.state.steps === 1 &&
                                                <div className='buttons' style={{ justifyContent: "flex-end" }}>


                                                    <div className={this.getTotalPax() > 0 ? "reserve" : "reserve disable"} onClick={this.getNextStep.bind(this)}>
                                                        Reserve <img src="/Assets/img/icon/arrows.svg" alt="arrow" />
                                                    </div>
                                                </div>
                                            }
                                            {this.state.steps === 2 &&

                                                <div className='buttons'>
                                                    <div className='left'>
                                                        <div className={"back"} style={{ marginRight: "15" }} onClick={this.getBackStep.bind(this)}><img src="/Assets/img/icon/arrows_back.svg" alt="arrow" /> Back</div>
                                                    </div>
                                                    <div className='right'>
                                                        {this.props.edit ?
                                                            <>
                                                                <div className={"bookNow"} onClick={this.getBookingProcess.bind(this, "bookNow")}>Update</div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className={"addToCart"} onClick={this.getBookingProcess.bind(this, "addToCart")}>Add to cart</div>
                                                                <div className={"bookNow"} onClick={this.getBookingProcess.bind(this, "bookNow")}>Book Now</div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </>
                                        :
                                        <div className='buttons'>
                                            {this.props.edit ?
                                                <>
                                                    <div className={"bookNow"} onClick={this.getBookingProcess.bind(this, "bookNow")}>Update</div>
                                                </>
                                                :
                                                <>
                                                    <div className={this.getTotalPax() > 0 ? "addToCart" : "addToCart disable"} onClick={this.getBookingProcess.bind(this, "addToCart")}>Add to cart</div>
                                                    <div className={this.getTotalPax() > 0 ? "bookNow" : "bookNow disable"} onClick={this.getBookingProcess.bind(this, "bookNow")}>Book Now</div>
                                                </>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className='packageDetails'>
                            {(engineData.selectedPackage !== null && engineData.packagesData.length > 0 && !engineData.packagesLoading) &&
                                <BookingPackageDetail productData={this.props.productData} />
                            }
                        </div>


                    </div>
                }

                <>
                    <BookingFixedBar data={this.props.productData} />
                </>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    engineData: state.engineData


});

export default connect(mapStateToProps, {
    bookingSettingAction
})(BookingEngine)