import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from "axios";
import LoadingSmall from '../../../Helpers/LoadingSmall';

class Wishlist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            sendRequestLoading: true,
            productId: props.productId,
            wishlistTitle: "",
            createDataReturn: true,
            addWishListForm: false
        }

    }

    closeModal = () => {
        this.props.closeModal()
    }

    componentDidMount() {
        this.getWishlist();

    }


    getWishlist = async () => {
        this.setState({
            sendRequestLoading: true
        });


        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getWishlist`)

            if (resp.status === 200) {

                this.setState({
                    data: resp.data,
                    sendRequestLoading: false
                })
            }
        } catch (err) {
            console.error(err);
        }
    };


    addWishList = () => {

        this.setState({
            addWishListForm: !this.state.addWishListForm
        });


    }

    onChangeTitle = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }



    createWishListTitle = async () => {
        this.setState({
            sendRequestLoading: true
        });


        try {

            let data = {
                wishlistTitle: this.state.wishlistTitle,
                productId: this.props.productId,
            }

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/createWishlist`, data)

            if (resp.status === 200) {

                this.setState({
                    createDataReturn: resp.data,
                    sendRequestLoading: false
                });
                this.getWishlist()
            }
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        return (
            <>
                <div className="modals modals-small">
                    <div className="modal-wapper">
                        <div className="modalHeader">
                            <div className="closeModal" onClick={this.closeModal.bind(this)}>
                                <img src="/Assets/img/icon/cancel.svg" alt="icon" />
                            </div>
                        </div>
                        <div className="modalBody">
                            <div className="title">
                                ADD TO WISHLIST
                            </div>
                            <div className="addPlanList" onClick={this.addWishList.bind(this)}>
                                <div className="addImg"><img src="/Assets/img/icon/heart.svg" alt="plus" /></div>
                                <div className="text">Create new wishlist folder</div>
                            </div>
                            {this.state.addWishListForm &&
                                <div className="addWisList">
                                    <div className="title">Name</div>
                                    <input type="text" onChange={this.onChangeTitle.bind(this)} name="wishlistTitle" id="wishlistTitle"></input>
                                    <div className="buttonSet">
                                        <div className="cancel" onClick={this.addWishList.bind(this)}> Cancel</div>
                                        <div className="create" onClick={this.createWishListTitle.bind(this)}>Create</div>
                                    </div>
                                </div>
                            }



                            {this.state.data.length === 0 ?
                                <><div className="empty">Wishlist folder empty</div></>
                                :
                                <>
                                    <div className="titleSub">
                                        Wishlist folders
                                    </div>

                                    <div className="listFolder">

                                        {this.state.data.map((item, i) => (
                                            <div className="addPlanList" key={i}>
                                                <div className="addImgs">
                                                    {item.product[0].img.filter((item) => item.location === "thumb").map((item, i, arra) => (
                                                        <div key={i}>
                                                            <>
                                                                {
                                                                    i === 0 &&
                                                                    <>

                                                                        <img src={process.env.REACT_APP_ASSETSNEW_ADDRESS + "tours/" + item.name + "-ResizeTT" + item.ext} alt="productImg" />
                                                                    </>
                                                                }
                                                            </>
                                                        </div>
                                                    ))
                                                    }

                                                </div>
                                                <div className="text">{item.title}</div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>
                <div className="modal-back-drop"></div>
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(Wishlist)
