import {
    B_ENGINE_PRODUCTID_SET,
    B_ENGINE_SETTING_LOADING,
    B_ENGINE_SETTING_GET,
    B_ENGINE_PACKAGE_LOADING,
    B_ENGINE_PACKAGE_GET,
    B_ENGINE_PACKAGE_SET,

    B_ENGINE_CALENDAR_LOADING,
    B_ENGINE_CALENDAR_GET,
    B_ENGINE_CALENDAR_SET,

    B_ENGINE_GUIDE_GET,
    B_ENGINE_SESSION_GET,
    B_ENGINE_GUIDE_SESSION_LOADING,

    B_ENGINE_SESSION_SET,
    B_ENGINE_GUIDE_SET,
    B_ENGINE_BLOCK_GET,
    B_ENGINE_UPDATE_PAX,
    B_ENGINE_BLOCK_LOADING,
    B_ENGINE_UPDATE_PRICE,
    B_ENGINE_UPDATE_PRICE_LOADING,
    B_ENGINE_PACKAGE_DETAIL_GET,
    B_ENGINE_CANCEL_GET,
    B_ENGINE_OPTIONS_GET,
    B_ENGINE_UPDATE_OPTIONS_PAX,
    B_ENGINE_UPDATE_OPTIONS_LOADING,
    B_ENGINE_UPDATE_OPTION_PRICE,
    B_ENGINE_RESET



} from '../../types'

const initalPackageState = {
    productId: null,
    settingsLoading: true,
    settingsData: [],

    packagesLoading: true,
    packagesData: [],
    packageDetailData: [],
    selectedPackage: null,

    selectedDate: "",
    calendarLoading: true,
    calendarList: [],

    sessionAndGuideLoading: true,
    sessionsData: [],
    guidesData: [],

    selectedSession: null,
    selectedGuide: null,
    blockLoading: true,
    block: [],
    priceLoading: true,
    priceLoadingBlock: "",
    cancelPolicy: [],
    options: [],
    optionsPriceLoading: false


}

function BookingEngineReducer(state = initalPackageState, action) {
    switch (action.type) {

        case B_ENGINE_PRODUCTID_SET:
            return {
                ...state,

                productId: action.payload,
            }

        case B_ENGINE_SETTING_LOADING:
            return {
                ...state,
                settingsData: [],

                packagesLoading: true,
                packagesData: [],
                packageDetailData: [],
                selectedPackage: null,

                selectedDate: "",
                calendarLoading: true,
                calendarList: [],

                sessionAndGuideLoading: true,
                sessionsData: [],
                guidesData: [],

                selectedSession: null,
                selectedGuide: null,
                blockLoading: true,
                block: [],
                priceLoading: true,
                priceLoadingBlock: "",
                cancelPolicy: [],
                options: [],
                optionsPriceLoading: false,
                settingsLoading: true
            }

        case B_ENGINE_SETTING_GET:
            return {
                ...state,
                settingsLoading: false,
                settingsData: action.payload
            }

        case B_ENGINE_PACKAGE_LOADING:
            return {
                ...state,
                packagesData: [],
                packageDetailData: [],
                selectedPackage: null,

                selectedDate: "",
                calendarLoading: true,
                calendarList: [],

                sessionAndGuideLoading: true,
                sessionsData: [],
                guidesData: [],

                selectedSession: null,
                selectedGuide: null,
                blockLoading: true,
                block: [],
                priceLoading: true,
                priceLoadingBlock: "",
                cancelPolicy: [],
                options: [],
                optionsPriceLoading: false,
                packagesLoading: true,
            }

        case B_ENGINE_PACKAGE_GET:
            return {
                ...state,
                packagesLoading: false,
                packagesData: action.payload
            }

        case B_ENGINE_PACKAGE_SET:
            return {
                ...state,
                selectedDate: "",
                sessionsData: [],
                guidesData: [],
                block: [],
                selectedPackage: action.payload,
            }
        case B_ENGINE_CALENDAR_LOADING:
            return {
                ...state,
                calendarLoading: action.payload,
            }
        case B_ENGINE_CALENDAR_GET:
            return {
                ...state,
                calendarLoading: false,
                calendarList: action.payload,
            }
        case B_ENGINE_CALENDAR_SET:
            return {
                ...state,
                selectedDate: action.payload,
                sessionsData: [],
                guidesData: [],
                block: [],
                blockLoading: true,
                selectedSession: null,
                selectedGuide: null
            }

        case B_ENGINE_GUIDE_SESSION_LOADING:
            return {
                ...state,
                sessionAndGuideLoading: action.payload,
            }

        case B_ENGINE_SESSION_GET:
            return {
                ...state,
                sessionsData: action.payload,
                sessionAndGuideLoading: false
            }

        case B_ENGINE_GUIDE_GET:
            return {
                ...state,
                guidesData: action.payload,
                sessionAndGuideLoading: false
            }

        case B_ENGINE_SESSION_SET:
            return {
                ...state,
                selectedSession: action.payload,
            }

        case B_ENGINE_GUIDE_SET:
            return {
                ...state,
                selectedGuide: action.payload,
            }
        case B_ENGINE_BLOCK_GET:
            return {
                ...state,
                block: action.payload,
                blockLoading: false
            }

        case B_ENGINE_BLOCK_LOADING:
            return {
                ...state,
                blockLoading: true
            }



        case B_ENGINE_UPDATE_PAX:
            let data = state.block.map(
                obj => (obj.id === action.payload.id ? Object.assign(obj, { pax: action.payload.pax }) : obj)
            )
            return {
                ...state,
                block: data,
            }

        case B_ENGINE_UPDATE_PRICE_LOADING:
            return {
                ...state,
                priceLoading: true,
                priceLoadingBlock: action.payload
            }


        case B_ENGINE_UPDATE_PRICE:

            let priceData = state.block.map(
                obj => (obj.id === action.payload.id ? Object.assign(obj, {
                    nonSpoTotal: action.payload.nonSpoTotal,
                    total: action.payload.total,
                }) : obj)
            )
            return {
                ...state,
                block: priceData,
                priceLoading: false

            }

        case B_ENGINE_PACKAGE_DETAIL_GET:
            return {
                ...state,
                packageDetailData: true
            }

        case B_ENGINE_CANCEL_GET:
            return {
                ...state,
                cancelPolicy: action.payload
            }

        case B_ENGINE_OPTIONS_GET:
            return {
                ...state,
                options: action.payload
            }

        case B_ENGINE_UPDATE_OPTIONS_PAX:
            let quantity = state.options.map(
                obj => (obj.id === action.payload.id ? Object.assign(obj, { quantity: action.payload.quantity }) : obj)
            )
            return {
                ...state,
                options: quantity,
            }

        case B_ENGINE_UPDATE_OPTIONS_LOADING:
            return {
                ...state,
                optionsPriceLoading: action.payload
            }


        case B_ENGINE_UPDATE_OPTION_PRICE:

            let optionspriceData = state.options.map(
                obj => (obj.id === action.payload.id ? Object.assign(obj, {
                    nonSpoTotal: action.payload.nonSpoTotal,
                    total: action.payload.total,
                }) : obj)
            )
            return {
                ...state,
                options: optionspriceData,
                optionsPriceLoading: false

            }
        case B_ENGINE_RESET:
            return {
                ...state,
                settingsLoading: true,
                settingsData: [],

                packagesLoading: true,
                packagesData: [],
                packageDetailData: [],
                selectedPackage: null,

                selectedDate: "",
                calendarLoading: true,
                calendarList: [],

                sessionAndGuideLoading: true,
                sessionsData: [],
                guidesData: [],

                selectedSession: null,
                selectedGuide: null,
                blockLoading: true,
                block: [],
                priceLoading: true,
                priceLoadingBlock: "",
                cancelPolicy: [],
                options: [],
                optionsPriceLoading: false
            }





        default:
            return state
    }
}


export default BookingEngineReducer;