import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import axios from "axios";
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import BookingPersonInfo from './BookingPersonInfo';
import { MeetingPoint } from './MeetingPoint';
import "../../../Assets/checkout.css"
import CurrencyFormat from 'react-currency-format';
import Moment from 'moment';
import detectBrowserLanguage from 'detect-browser-language'

import { osVersion, osName, fullBrowserVersion, browserName, mobileVendor, mobileModel, deviceType, getUA } from "react-device-detect";
import LoadingSmall from '../../../Helpers/LoadingSmall';
import GetAutoComplateGoogle from '../../../Helpers/newGoogleAutoComplete';

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reservationId: props.match.params.code,
            data: [],
            person: [],
            loading: true,
            sameBilling: false,
            promoCode: false,
            addPromoCode: "",
            googleReturn: [],
            cardUid: "",
            billingName: "",
            billingSurname: "",
            billingEmail: "",
            billingConfimEmail: "",
            billingPhone: "",
            billingIDnumber: "",

            billingAddress: "",
            billingCountry: "",
            billingCity: "",
            billingDistrict: "",
            billingPostCode: "",

            pickupPoint: [],
            cardNumber: "",
            cardName: "",
            cardMonth: "",
            cardYear: "",
            cardCv2: "",
            reservationLoading: false,


            billingPhoneError: false,

            cardMonthError: "",
            cardYearError: "",

            cardNumberShow: "**** **** **** ****",
            cardMonthShow: "XX",
            cardYearShow: "20XX",
            cardNameShow: "NAME SURNAME",
            cardDetail: {
                status: false
            },
            errorModal: false,
            errorModalText: "",


        }
    }

    getSelectedAddress = (e) => {
        console.log(e);

        this.setState({
            billingAddress: e[0].formatted_address,
        });

        e[0].address_components.forEach(element => {

            if (element.types[0] === "postal_code") {
                this.setState({
                    billingPostCode: element.long_name,
                });
            }

            if (element.types[0] === "country") {
                this.setState({
                    billingCountry: element.long_name,
                });
            }

            if (element.types[0] === "administrative_area_level_1") {
                this.setState({
                    billingCity: element.long_name,
                });
            }
            if (element.types[0] === "administrative_area_level_2") {
                this.setState({
                    billingDistrict: element.long_name,
                });
            }
        });
    }


    selectedDate = (e) => {
        return moment(e).format("ll")
    }


    cancelDateFormat = (e) => {
        return moment(e).format("lll")
    }

    componentDidMount() {
        this.getReservationDetails()

    }

    getReservationDetails = async () => {
        this.setState({

            loading: true
        })
        const data = {
            reservationId: this.state.reservationId
        }

        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getReservation`, data)

            if (resp.status === 200) {
                const result = resp.data

                this.setState({
                    data: result,
                    loading: false,
                    cardUid: result.cartUid
                })


            }
        } catch (err) {
            console.error(err);
        }
    }



    setErrors = (item, type) => {

        const yOffset = -10;
        let element = "";
        if (type === "name") {
            element = document.getElementsByName(item)[0];
            element.classList.add('errorInputs');
        } else {
            element = document.getElementById(item);
        }

        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y - 200, behavior: 'smooth' });

    }


    setCheckout = async () => {



        let status = true;

        if (this.state.billingName === "") {
            this.setErrors("billingName", "name");
            status = false;
        } else if (this.state.billingSurname === "") {
            this.setErrors("billingSurname", "name");
            status = false;
        } else if (this.state.billingEmail === "") {
            this.setErrors("billingEmail", "name");
            status = false;
        }
        else if (!this.validMail(this.state.billingConfimEmail)) {
            this.setErrors("billingConfimEmail", "name");
            status = false;
        } else if (!this.validMail(this.state.billingConfimEmail)) {
            this.setErrors("billingConfimEmail", "name");
            status = false;
        } else if (this.state.billingConfimEmail !== this.state.billingEmail) {
            this.setErrors("billingConfimEmail", "name");
            this.setErrors("billingEmail", "name");
            status = false;
        } else if (this.state.billingPhone.length < 4) {
            this.setErrors("phones", "id");
            this.setState({
                billingPhoneError: true
            })
            status = false;
        } else if (this.state.cardName === "") {
            this.setErrors("cardName", "name");
            status = false;
        } else if (this.state.billingIDnumber === "") {
            this.setErrors("billingIDnumber", "name");
            status = false;
        } else if (this.state.cardName === "") {
            this.setErrors("cardNumber", "name");
            status = false;
        } else if (this.state.cardMonth === "") {
            this.setErrors("cardMonth", "id");
            this.setState({
                cardMonthError: true
            })
            status = false;
        } else if (this.state.cardYear === "") {
            this.setErrors("cardYear", "id");
            this.setState({
                cardYearError: true
            })
            status = false;
        } else if (this.state.cardCv2 === "") {
            this.setErrors("cardCv2", "name");
            status = false;
        }


        if (status) {
            this.setState({
                reservationLoading: true
            });


            const data = {
                reservationId: this.state.reservationId,
                cardUid: this.state.cardUid,
                reservationDetail: this.state.data,
                billingName: this.state.billingName,
                billingSurname: this.state.billingSurname,
                billingEmail: this.state.billingEmail,
                billingConfimEmail: this.state.billingConfimEmail,
                billingPhone: this.state.billingPhone,
                billingIDnumber: this.state.billingIDnumber,
                billingAddress: this.state.billingAddress,
                billingCountry: this.state.billingCountry,
                billingCity: this.state.billingCity,
                billingDistrict: this.state.billingDistrict,
                billingPostCode: this.state.billingPostCode,
                person: this.state.person,
                sameBilling: this.state.sameBilling,
                pickupPoint: this.state.pickupPoint,
                cardNumber: this.state.cardNumber,
                cardName: this.state.cardName,
                cardMonth: this.state.cardMonth,
                cardYear: this.state.cardYear,
                cardCv2: this.state.cardCv2,
                language: detectBrowserLanguage(),
                osVersion: osVersion,
                osName: osName,
                fullBrowserVersion: fullBrowserVersion,
                browserName: browserName,
                mobileVendor: mobileVendor,
                mobileModel: mobileModel,
                deviceType: deviceType,
                getUA: getUA,
                sName:"",
                sSurname:"",
            }

            try {

                const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/finishCheckout`, data)

                if (resp.status === 200) {
                    const result = resp.data
                    this.setState({
                        reservationLoading: false
                    });


                    if (result.status) {
                          this.props.history.push('/CompletedBooking/' + result.payment);
                    } else {



                        if (result.errorType === "cart") {

                        } else if (result.errorType === "payment") {

                            this.setState({
                                errorModal: true,
                                errorModalText: result.error
                            });

                        }

                    }



                }
            } catch (err) {
                console.error(err);
            }
        }
    }


    closePaymentModal = () => {

        this.setState({
            errorModal: false,
            errorModalText: ""
        });
    }

    getPay= () => {
        let elembooking = document.getElementsByClassName("getPayAnchor")[0];

        window.scrollTo({
            top: elembooking.offsetTop - 190,
            behavior: "smooth"
        });

    }


    validMail = (mail) => {

        return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(mail);
    }


    personString = (e) => {
        let strings = [];
        e.map((item, i) => {
            strings.push([item.pax + "x" + item.blockTitle]);
        });

        return strings;

    }

    promoCode = () => {
        this.setState({
            promoCode: !this.state.promoCode,

        })
    }

    addPromoCodes = (e) => {
        this.setState({
            addPromoCode: e.target.value,

        })
    }

    setSameBiling = () => {

        this.setState({
            sameBilling: !this.state.sameBilling,
            sName:this.state.billingName,
            sSurname:this.state.billingSurname

        });
    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })

        document.getElementsByName(e.target.name)[0].classList.remove('errorInputs')

        if (e.target.name === "cardNumber" && e.target.value.length === 7) {
            this.getCardCheck();
        }

    }



    getCardCheck = async () => {

        const data = {
            cardUid: this.state.cardUid,
            cardNumber: this.state.cardNumber,
            price: this.state.data.cartGrandTotalSpo,
            currency: this.state.data.exchangeInfo.exchangeCurr
        }

        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getCreditCardCheck`, data)

            if (resp.status === 200) {
                const result = resp.data

                this.setState({
                    cardDetail: result
                })


            }
        } catch (err) {
            console.error(err);
        }
    }




    personInfo = (e, i) => {
        const newItems = [...this.state.person];
        newItems[i] = e;
        this.setState({ person: newItems });
    }

    updatePickup = (e, m, i, p) => {

        const newItems = [...this.state.pickupPoint];
        newItems[i] = {
            type: m,
            data: e,
            productId: p
        };
        this.setState({ pickupPoint: newItems });

    }

    handleChangeM = (e) => {
        this.setState({
            cardMonth: e,
            cardMonthError: false,

        })
    }

    handleChangeY = (e) => {
        this.setState({
            cardYear: e,
            cardYearError: false,
        })
    }

    getCardData = (e) => {
        let logo = "";
        switch (e) {
            case "MASTER_CARD": logo = "ccmastercard.svg"; break;
            case "AMERICAN_EXPRESS": logo = "ccamex.svg"; break;
            case "VISA": logo = "ccvisa.svg"; break;
            case "TROY": logo = "cctroy.svg"; break;
            case "MAESTRO": logo = "ccmaestro.svg"; break;
            default: logo = ""; break;
        }

        
        return logo;

    }



    render() {
        const { data } = this.state
        //console.log(this.state);
        return (
            <>
                {!this.state.loading &&
                    <div className="checkout">

                        <div className="content">
                            <div className="bookingResult">
                                <div className="title">Checkout</div>
                                <div className="subTitle">Checkout securely - it takes only a few minutes</div>
                                <div className="productInfo">
                                    <div className="title">Billing details</div>
                                    <div className="line"></div>
                                    <div className="require">* Required fields</div>
                                    <div className="inputParent">
                                        <div className="title">Name *</div>
                                        <input type="text" id="billingName" className={this.state.billingNameError && "errorInputs"} maxLength="30" name="billingName" value={this.state.billingName} onChange={this.onChange.bind(this)} placeholder="Name" />
                                    </div>

                                    <div className="inputParent">
                                        <div className="title">Surname *</div>
                                        <input type="text" maxLength="30" id="billingSurname" name="billingSurname" value={this.state.billingSurname} onChange={this.onChange.bind(this)} placeholder="Surname" />
                                    </div>

                                    <div className="inputParent">
                                        <div className="title">Email *</div>
                                        <input type="email" name="billingEmail" value={this.state.billingEmail} onChange={this.onChange.bind(this)} placeholder="Email" />
                                    </div>

                                    <div className="inputParent">
                                        <div className="title">Confirm Email *</div>
                                        <input type="email" name="billingConfimEmail" value={this.state.billingConfimEmail} onChange={this.onChange.bind(this)} placeholder="Confirm Email" />
                                    </div>
                                    <div className="inputParent" id="phones">
                                        <div className="title"></div>
                                        <PhoneInput
                                            placeholder="Mobile Phone *"
                                            country={'tr'}
                                            value={this.state.billingPhone}
                                            onChange={phone => this.setState({ billingPhone: phone, billingPhoneError: false })}
                                        />
                                        {this.state.billingPhoneError &&
                                            <div className='errorInputs'>Please enter phone number</div>
                                        }
                                    </div>

                                    <div className="inputParent">
                                        <div className="title">ID number *</div>
                                        <input type="text" maxLength="30" id="billingIDnumber" name="billingIDnumber" value={this.state.billingIDnumber} onChange={this.onChange.bind(this)} placeholder="ID Number" />
                                    </div>

                                    <div className="inputParent">
                                        <div className="title">Billing Address *</div>
                                        <GetAutoComplateGoogle selectedAddress={this.getSelectedAddress} oldValue={this.state.googleReturn} />
                                    </div>

                                    <div className="title mt30px">Traveler details</div>
                                    <div className="line"></div>
                                    <div className="sameasbiling">
                                        <div className="checkbox">
                                            <input type="checkbox" id="sameBiling" onClick={this.setSameBiling.bind(this)} name="sameBiling" value="1" defaultChecked={this.state.checked} />
                                            <label htmlFor="sameBiling"> Same as billing details</label>
                                        </div>

                                    </div>

                                    {data.booking.map((items, index) => (
                                        <>
                                            <BookingPersonInfo name={this.state.sName} surname={this.state.sSurname} productId={items.prices.productId} id={index} parentUpdate={this.personInfo} data={items} sameAsBiling={this.state.sameBilling} />
                                            <MeetingPoint productId={items.prices.productId} id={index} data={items} parentUpdate={this.updatePickup} />
                                        </>
                                    ))}



                                    <div className="title mt30px getPayAnchor">Payment Details</div>
                                    <div className="line"></div>
                                    <div className="payment">Pay securely - we use SSL encryption to keep your data safe</div>
                                    <div className="guestInfo card flex">

                                        <div className='cardInfo'>
                                            <div className="inputParent mt0">
                                                <input type="text" id="cardName" name="cardName" value={this.state.cardName} onChange={this.onChange.bind(this)} placeholder="Cardholder Name *" />
                                            </div>
                                            <div className="inputParent">
                                                <input type="text" name="cardNumber" value={this.state.cardNumber} onChange={this.onChange.bind(this)} placeholder="Credit Card Number *" />
                                            </div>
                                            <div className="row flex-start flex">
                                                <div className="inputParent mbr15" id="cardMonth">
                                                    <div className="title">Expiration Month*</div>
                                                    <Select
                                                        value={this.state.cardMonth}
                                                        onChange={this.handleChangeM}
                                                        options={[
                                                            { value: '01', label: '01 - Jan' },
                                                            { value: '02', label: '02 - Feb' },
                                                            { value: '03', label: '03 - Mar' },
                                                            { value: '04', label: '04 - Apr' },
                                                            { value: '05', label: '05 - May' },
                                                            { value: '06', label: '06 - Jun' },
                                                            { value: '07', label: '07 - Jul' },
                                                            { value: '08', label: '08 - Agu' },
                                                            { value: '09', label: '09 - Sep' },
                                                            { value: '10', label: '10 - Oct' },
                                                            { value: '11', label: '11 - Nov' },
                                                            { value: '12', label: '12 - Dec' },
                                                        ]}
                                                        placeholder="MM"
                                                        className="react-select" classNamePrefix="react-select"
                                                    />
                                                    {this.state.cardMonthError &&
                                                        <div className='errorInputs'>Please enter expiration Month</div>
                                                    }
                                                </div>
                                                <div className="inputParent mbr15" id="cardYear">
                                                    <div className="title">Expiration Year*</div>
                                                    <Select
                                                        value={this.state.cardYear}
                                                        onChange={this.handleChangeY}
                                                        options={[
                                                            { value: '2022', label: '2022' },
                                                            { value: '2023', label: '2023' },
                                                            { value: '2024', label: '2024' },
                                                            { value: '2025', label: '2025' },
                                                            { value: '2026', label: '2026' },
                                                            { value: '2027', label: '2027' },
                                                            { value: '2028', label: '2028' },
                                                            { value: '2029', label: '2029' },
                                                            { value: '2030', label: '2030' },
                                                            { value: '2031', label: '2031' },
                                                            { value: '2032', label: '2032' },


                                                        ]}
                                                        placeholder="YYYY"
                                                        className="react-select" classNamePrefix="react-select"
                                                    />
                                                    {this.state.cardYearError &&
                                                        <div className='errorInputs'>Please enter expiration Year</div>
                                                    }
                                                </div>
                                                <div className="inputParent">
                                                    <div className="title">Security Code*</div>
                                                    <input type="text" name="cardCv2" value={this.state.cardCv2} onChange={this.onChange.bind(this)} placeholder="CV2 *" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='creditCard'>
                                            <div class="cards">
                                                <div className="card__front card__part">
                                                    <img className="card__front-square card__square" src="/Assets/img/icon/chip.svg" alt="" />
                                                    {this.state.cardDetail.status &&
                                                        <>

                                                            <img className="card__front-logo card__logo" src={"/Assets/img/icon/" + this.getCardData(this.state.cardDetail.data.cardAssociation)} alt="" />
                                                        </>
                                                    }
                                                    <p className="card_numer">{this.state.cardNumber}</p>
                                                    <div className="card__space-75">
                                                        <span className="card__label">Card holder</span>
                                                        <p className="card__info">{this.state.cardName}</p>
                                                    </div>
                                                    <div className="card__space-25">
                                                        <span className="card__label">Expires</span>
                                                        <p className="card__info">{this.state.cardMonth.value}/{this.state.cardYear.label}</p>
                                                    </div>
                                                </div>

                                                <div className="card__back card__part">
                                                    <div className="card__black-line"></div>
                                                    <div className="card__back-content">
                                                        <div className="card__secret">
                                                            <p className="card__secret--last">{this.state.cardCv2}</p>
                                                        </div>
                                                        <img className="card__back-square card__square" src="/Assets/img/icon/chip.svg" alt="" />
                                                        <img className="card__back-logo card__logo" src="/Assets/img/tourzoneIcon.svg" alt="" />

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="paymentNow" onClick={this.setCheckout.bind(this)}> Pay Now</div>

                                    <div className="information">
                                        <ul>
                                            <li> You will be charged the total amount once your order is confirmed.</li>
                                            <li> If confirmation isn't received instantly, an authorization for the total amount will be held until your booking is confirmed.</li>
                                            <li>Cut-off times are based on the experience’s local time.</li>
                                            <li>By clicking ‘Book Now,’ you are acknowledging that you have read and are bound by Tourzone's Customer Terms of Use, Privacy Policy, plus the tour operator’s rules & regulations (see the listing for more details).</li>
                                        </ul>
                                    </div>

                                </div>

                            </div>
                            <div className="bullet">


                                <div className="grandTotal">
                                    <div className="grandTotalTitle">Grand Total</div>
                                    <div className="price">
                                        <>

                                            <CurrencyFormat value={data.cartGrandTotal}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                prefix={data.exchangeInfo.symbol + " "} />

                                        </>
                                    </div>
                                    <div className="spoPrice">
                                        <>

                                            <CurrencyFormat value={data.cartGrandTotalSpo}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                prefix={data.exchangeInfo.symbol + " "} />

                                        </>
                                    </div>
                                </div>
                                <div className="tax">All taxes and fees included</div>

                                <div className="paymentNow" onClick={this.getPay.bind()}> Pay Now</div>
                                <div className="promoCode" onClick={this.promoCode.bind(this)}>Add gift or promo code</div>
                                {this.state.promoCode &&
                                    <div className="inputParent">
                                        <div className="title">Enter Code *</div>
                                        <input type="text" maxLength="30" onChange={this.addPromoCodes.bind(this)} name="promoCode" placeholder="Promo or gift code" />
                                        <div className="addPromoCode">
                                            <img src={this.state.addPromoCode.length > 6 ? "/Assets/img/icon/plus.svg" : "/Assets/img/icon/plusDisable.svg"} alt="plus" />
                                        </div>
                                    </div>
                                }
                                <div className="orders">
                                    <div className="title">Order summary</div>

                                    {data.booking.map((item, index) => (
                                        <div className='bookingContainer'>
                                            <div className='photo'>
                                                {item.product.img.filter(items => items.type === 'image').slice(0, 1).map((img, i) => (
                                                    <img src={process.env.REACT_APP_ASSETSNEW_ADDRESS + "products/360/" + img.name} alt="product" />
                                                ))}
                                            </div>
                                            <div className='content'>
                                                <div className='title'>{item.product.productTitle}</div>
                                                <div className='title'>{Moment(item.prices.cart.reservationDate).format("DD.MM.YYYY")} {item.prices.cart.time}</div>
                                                <div className='pr'>
                                                    <div className='pDetail'>
                                                        ( {this.personString(item.prices.pricesDetail).join(", ")} )
                                                    </div>
                                                    <div className='p'>
                                                        <CurrencyFormat value={item.prices.priceTotal.grandTotalExchangePrice}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}
                                                            prefix={item.prices.exchangeInfo.symbol + " "} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>



                                <div className="securePayment">
                                    <div className="title">Secure Payment</div>
                                    <div className="detail">Your info's safe with us. All data is encrypted and transmitted securely with an SSL protocol.
                                        Tourzone respects your privacy. We do not sell your personal information to anyone. Privacy Statement »</div>
                                </div>

                            </div>
                        </div>
                    </div>

                }
                {
                    this.state.reservationLoading &&
                    <div className='loadingCheckout'>
                        <LoadingSmall />
                    </div>
                }

                {this.state.errorModal &&
                    <>
                        <div className="modals modals-small">
                            <div className="modal-wapper">
                                <div className="modalHeader">
                                   
                                </div>
                                <div className="modalBody">
                                    <div className='errorImg'>
                                        <img src="/Assets/img/icon/errorPayment.svg" alt='error' />
                                    </div>
                                    <div className="title text-center error">
                                        Payment Failed
                                    </div>
                                    <div className="subTitle text-center">
                                        Payment was unsuccessful!
                                    </div>
                                    <div className="subTitle text-center">
                                      
                                        {
                                            this.props.appData.appData.sites.data.language.translate[this.state.errorModalText]
                                        }
                                    </div>
                                    <div className='tryAgainButton' onClick={this.closePaymentModal.bind(this)}>
                                        Try again
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-back-drop'></div>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({

    appData: state.appData,
});

export default connect(mapStateToProps)(Checkout)
