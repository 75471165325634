import {
    B_ENGINE_PRODUCTID_SET,

    B_ENGINE_SETTING_LOADING,
    B_ENGINE_SETTING_GET,

    B_ENGINE_PACKAGE_LOADING,
    B_ENGINE_PACKAGE_GET,
    B_ENGINE_PACKAGE_SET,

    B_ENGINE_CALENDAR_LOADING,
    B_ENGINE_CALENDAR_GET,
    B_ENGINE_CALENDAR_SET,

    B_ENGINE_GUIDE_GET,
    B_ENGINE_SESSION_GET,
    B_ENGINE_GUIDE_SESSION_LOADING,

    B_ENGINE_SESSION_SET,
    B_ENGINE_GUIDE_SET,
    B_ENGINE_BLOCK_GET,
    B_ENGINE_UPDATE_PAX,
    B_ENGINE_BLOCK_LOADING,
    B_ENGINE_UPDATE_PRICE,
    B_ENGINE_UPDATE_PRICE_LOADING,
    B_ENGINE_PACKAGE_DETAIL_GET,
    B_ENGINE_CANCEL_GET,
    B_ENGINE_OPTIONS_GET,
    B_ENGINE_UPDATE_OPTIONS_PAX,
    B_ENGINE_UPDATE_OPTION_PRICE,
    B_ENGINE_UPDATE_OPTIONS_LOADING,
    B_ENGINE_RESET




} from '../../types';
import axios from "axios";



export const bookingSettingAction = (data) => async dispatch => {

    const query = {
        productId: data,
    }

    dispatch({
        type: B_ENGINE_PRODUCTID_SET,
        payload: data
    });
    
    dispatch({
        type: B_ENGINE_RESET,
        payload: ""
    });




    dispatch({
        type: B_ENGINE_SETTING_LOADING,
        payload: true
    });



    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getProductSettings`, query)

        if (resp.status === 200) {

            const { response } = resp.data
            dispatch({
                type: B_ENGINE_SETTING_GET,
                payload: response
            });
        }
    } catch (err) {
        console.error(err);
    }

}


export const BookingPackageGet = (id) => async dispatch => {

    const data = {
        productId: id,
    }

    dispatch({
        type: B_ENGINE_PACKAGE_LOADING,
        payload: true
    });

    try {
        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getProductPackage`, data)
        if (resp.status === 200) {
            const { packages } = resp.data
            dispatch({
                type: B_ENGINE_PACKAGE_GET,
                payload: packages
            })

            for (let i = 0; i < packages.length; i++) {
                if (packages[i].status) {

                    dispatch({
                        type: B_ENGINE_PACKAGE_SET,
                        payload: packages[i].id
                    });


                    break;
                }
            }

        }
    } catch (err) {
        console.error(err);
    }

}

export const BookingPackageSetId = (id) => async dispatch => {

    dispatch({
        type: B_ENGINE_PACKAGE_SET,
        payload: id
    });
}

export const BookingEngineCalendarGet = (datas) => async dispatch => {


    const data = {
        packageId: datas.packageId,
        productId: datas.productId,
        startDate: datas.startDate,
        endDate: datas.endDate
    }

    dispatch({
        type: B_ENGINE_CALENDAR_LOADING,
        payload: true
    })



    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getCalendar`, data)

        if (resp.status === 200) {

            dispatch({
                type: B_ENGINE_CALENDAR_GET,
                payload: resp.data.calendar
            });



            // BookingEngineSetDate();

        }
    } catch (err) {
        console.error(err);
    }
}




export const BookingEngineSetDate = (data) => async dispatch => {



    const datas = {
        packageId: data.packageId,
        productId: data.productId,
        date: data.dates,
    }

    dispatch({
        type: B_ENGINE_CALENDAR_SET,
        payload: datas.date
    });

    dispatch({
        type: B_ENGINE_GUIDE_SESSION_LOADING,
        payload: true
    });


    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getGuidesAndSessions`, data)

        if (resp.status === 200) {
            dispatch({
                type: B_ENGINE_SESSION_GET,
                payload: resp.data.sessions
            });
            dispatch({
                type: B_ENGINE_GUIDE_GET,
                payload: resp.data.guides
            });


            for (let i = 0; i < resp.data.sessions.length; i++) {
                if (resp.data.sessions[i].status) {
                    dispatch({
                        type: B_ENGINE_SESSION_SET,
                        payload: resp.data.sessions[i].quotaId
                    });
                    break;
                }
            }

            if (resp.data.guides.length > 0) {

                for (let i = 0; i < resp.data.guides.length; i++) {
                    if (resp.data.guides[i].status) {
                        dispatch({
                            type: B_ENGINE_GUIDE_SET,
                            payload: resp.data.guides[i].id
                        });
                        break;
                    }
                }
            }







        }
    } catch (err) {
        console.error(err);
    }
}

export const setGuides = (data) => dispatch => {
    dispatch({
        type: B_ENGINE_GUIDE_SET,
        payload: data
    });
}

export const setSessions = (data) => dispatch => {
    dispatch({
        type: B_ENGINE_SESSION_SET,
        payload: data
    });
}

export const BookingGetBlockInfo = (data) => async dispatch => {

    dispatch({
        type: B_ENGINE_BLOCK_LOADING,
        payload: true
    });

    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getQuantityAndPrices`, data)

        if (resp.status === 200) {

            dispatch({
                type: B_ENGINE_BLOCK_GET,
                payload: resp.data.data
            });

            dispatch({
                type: B_ENGINE_CANCEL_GET,
                payload: resp.data.cancels

            });

            dispatch({
                type: B_ENGINE_OPTIONS_GET,
                payload: resp.data.options

            });

        }
    } catch (err) {
        console.error(err);
    }

}


export const updatePax = (data) => async dispatch => {


    dispatch({
        type: B_ENGINE_UPDATE_PAX,
        payload: data
    });
}

export const updatePrice = (query, pax) => async dispatch => {


    const data = {
        sessionId: query.sessionId,
        priceId: query.priceId,
        packageId: query.packageId,
        productId: query.productId,
        date: query.date,
        block: query.block,
        pax: pax
    }

    dispatch({
        type: B_ENGINE_UPDATE_PRICE_LOADING,
        payload: data.block
    });


    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getPersonBlockPrices`, data)

        if (resp.status === 200) {

            dispatch({
                type: B_ENGINE_UPDATE_PRICE,
                payload: resp.data.data[0]
            });

        }
    } catch (err) {
        console.error(err);
    }

}


export const updateOptionsPax = (data) => async dispatch => {


    dispatch({
        type: B_ENGINE_UPDATE_OPTIONS_PAX,
        payload: data
    });
}


export const updateOptionsPrice = (data) => async dispatch => {



    const datas = {
        optionsId: data.id,
        quantity: data.quantity,
        productId: data.productId,
        packageId: data.packageId,
        dates: data.dates

    }

    dispatch({
        type: B_ENGINE_UPDATE_OPTIONS_LOADING,
        payload: true
    });


    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getOptionsPrices`, datas)

        if (resp.status === 200) {

            dispatch({
                type: B_ENGINE_UPDATE_OPTION_PRICE,
                payload: resp.data.data
            })

        }
    } catch (err) {
        console.error(err);
    }

}




