import React, { Component } from 'react'
import { connect } from 'react-redux'

class Currency extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }


    }

    changeCurrency = (currency, symbol) => {


        localStorage.setItem("currency", currency);
        localStorage.setItem("currSymbol", symbol);
        window.location.reload();
    }


    render() {
        const { appData } = this.props.appData;

        return (
            <>
                <div className="CurrencyPopup">
                    <div className="CurrencyList">

                        <div className="list">
                            <ul>
                                {appData.sites.data.exchange.slice(0, 8).map((item, index) => (
                                    <li key={index} onClick={this.changeCurrency.bind(this, item.code, item.symbol)}>
                                        <div className="currencyIcon">{item.symbol}</div>
                                        <div className="CurrencyText">{item.name} ({item.code}) </div>
                                    </li>
                                ))}

                            </ul>
                        </div>
                        <div className="list">
                            <ul>
                                {appData.sites.data.exchange.slice(9, 18).map((item, index) => (
                                    <li key={index} onClick={this.changeCurrency.bind(this, item.code, item.symbol)}>
                                        <div className="currencyIcon">{item.symbol}</div>
                                        <div className="CurrencyText">{item.name} ({item.code}) </div>
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(Currency)