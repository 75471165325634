import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'

export class CreateDrawer extends Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');

        // The <div> is a necessary container for our
        // content, but it should not affect our layout.
        // Only works in some browsers, but generally
        // doesn't matter since this is at
        // the end anyway. Feel free to delete this line.
    }




    componentDidMount() {
        document.body.appendChild(this.el);
        if (this.props.show) {
            document.body.classList.add('noscroll')
        }

    }

    componentWillUnmount() {
        document.body.removeChild(this.el);
        document.body.classList.remove('noscroll')
    }

    close = () => {
        this.props.close();
        document.body.classList.remove('noscroll')
    }

    componentDidUpdate = () => {
        if (this.props.show) {
            this.delay(800).then(() => document.body.classList.add('noscroll'))
            
        } else {
            document.body.classList.remove('noscroll')
        }
    }

    delay = (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    



    render() {

      
        return ReactDOM.createPortal(
            <>

                <div className={this.props.show ? 'leftModal open' : "leftModal"}>

                    <div className={this.props.show ? 'modalwrapper open' : 'modalwrapper'}>
                        <div className='modalContent'>
                            <div className='modalHeader'>
                                <div className='close' onClick={this.close.bind(this)}><img src="/Assets/img/icon/cancel.svg" alt="icon" /></div>
                                <div className='title'>{this.props.title}</div>
                            </div>
                            <div className='modalBody'>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </>,
            this.el,
        );
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDrawer)