import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Vimeo } from 'vimeo';
import Fancybox from '../../../Helpers/fancybox';
import Wishlist from './Wishlist';
import {
    FacebookShareButton,
    FacebookIcon,
    EmailShareButton,
    EmailIcon,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
} from "react-share";

import ReactTooltip from 'react-tooltip';

class ProductSlider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            imageInfos: props.data.productImg,
            wishlist: false,
            sharePopup: false,
        }



    }

    openSharePopup = () => {
        this.setState({
            sharePopup: !this.state.sharePopup
        });
    }

    copyLink = () => {
        this.setState({
            linkCopy: "Link Copied"
        })
        setTimeout(() => {
            this.setState({
                linkCopy: "Copy Link"
            })
        }, 3000);

        navigator.clipboard.writeText(window.location.href);
    }

    modal = () => {
        this.setState({
            wishlist: !this.state.wishlist
        })
    }

    componentDidMount() {
        this.state.data.productImg.map((item, index) => {
            if (item.type === "video") {
                this.getListVideoPictures(item.name, index);
            }
        })
    }

    getListVideoPictures = (uri, i) => {


        let client = new Vimeo("e4e3cc0f241568e0a77af18e3e24e02e05c28fe8",
            "yp4EGWuKFX61p0EJt8sgC9Ua5TkD9S5+GWqxAcGmFDxtqPxLZGnAvXU1N13u8CIDbcKUOalUbKKRAprhh7YeYS9Fp4oPC5MN5A+mCx078OLwRrZ5aUjGWwEZWnFLh6az",
            "ecca4d5e02577016fbe495501d49834d");
        let _this = this;
        client.request({
            path: uri,
            query: {
                fields: 'uri,name,description,duration,created_time,modified_time,pictures'
            }
        }, function (error, body, status_code, headers) {

            _this.setState({
                imageInfos: _this.state.imageInfos.map((
                    obj, index) => (index === i ? Object.assign(obj, {
                        videoImg: body.pictures.base_link,
                        videoId: uri.split("/")
                    }) : obj)
                )
            });
        });
    }




    render() {

        return (

            <div className="productSlider">
                <Fancybox >
                    {this.state.data.productImg.slice(0, 4).map((item, index) => (
                        <div key={index} className={'sliderImgs image--' + index} >
                            {item.type === "image" ?
                                <img data-fancybox="gallery2" data-src={process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/1200/" + item.name} src={process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/1200/" + item.name} alt="Gallery" />
                                :
                                <>
                                    <img data-fancybox="gallery2" data-src={"https://vimeo.com/" + item.videoId[2]} src={item.videoImg} alt="Gallery" />
                                    <div className='play'  data-src={"https://vimeo.com/" + item.videoId[2]}><img src="/Assets/img/icon/playIcon.png" alt="galleryPlay" /></div>
                                </>
                            }

                            {index === 0 &&
                                <div className='mediaButton'>
                                    <button type="button" className='button' onClick={this.modal.bind(this)}>
                                        <img src="/Assets/img/icon/heart.svg" alt="wishlist" />
                                        <span className='buttonTitle'>Add Wishlist</span>
                                    </button>
                                    {this.state.sharePopup &&
                                        <div className="sharePopup">
                                            <div data-tip={this.state.linkCopy} className="copyLink" onClick={this.copyLink.bind(this)}>
                                                <img src="/Assets/img/icon/link.svg" alt="link" />
                                            </div>
                                            <ReactTooltip effect='solid' />
                                            <EmailShareButton data-tip="Email Share"
                                                subject={"http://www.tourzone.com"}
                                                body={"tourzone - World is yours to explore"}

                                            >
                                                <EmailIcon size={32} round />
                                            </EmailShareButton>
                                            <FacebookShareButton data-tip="Facebook Share"
                                                url={"http://www.tourzone.com"}
                                                quote={"tourzone - World is yours to explore"}
                                                hashtag="#tourzone"
                                            >
                                                <FacebookIcon size={32} round />
                                            </FacebookShareButton>
                                            <FacebookMessengerShareButton data-tip="Facebook Messenger Share"
                                                url={"http://www.tourzone.com"}
                                                appId="521270401588372"
                                            >
                                                <FacebookMessengerIcon size={32} round />
                                            </FacebookMessengerShareButton>

                                           
                                            <WhatsappShareButton data-tip="Whatsapp Share"
                                                title={"http://www.tourzone.com"}
                                                url={"http://www.tourzone.com"}
                                            >
                                                <WhatsappIcon size={32} round />
                                            </WhatsappShareButton>
                                        </div>
                                    }

                                    <button type="button" onClick={this.openSharePopup.bind(this)} className='button'>
                                        <img src="/Assets/img/icon/share.svg" alt="wishlist" />
                                        <span className='buttonTitle'>Share</span>
                                    </button>
                                    {this.state.wishlist &&

                                        <Wishlist closeModal={this.modal.bind(this)} productId={this.props.productId} />
                                    }
                                </div>

                            }



                        </div>


                    ))}

                </Fancybox>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(ProductSlider)