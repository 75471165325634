import React, { Component } from 'react'
import { connect } from 'react-redux'
import LanguageSet from '../../../Helpers/LanguageSet';

export class TrustIcon extends Component {
    render() {
        return (
            <>
            
            <div className="turstIcons">
                <div className="items">
                    <div className="icon">
                        <img src="/Assets/img/icon/iconset1New.png" alt="icon" />
                    </div>
                    <div className="detail">
                        <div className="title"><LanguageSet code="HOME_TURST_ICON1_TITLE" /></div>
                        <div className="text"><LanguageSet code="HOME_TURST_ICON1_TEXT" /></div>
                    </div>
                </div>

                <div className="items">
                    <div className="icon">
                        <img src="/Assets/img/icon/iconset2New.png" alt="icon" />
                    </div>
                    <div className="detail">
                        <div className="title"><LanguageSet code="HOME_TURST_ICON2_TITLE" /></div>
                        <div className="text"><LanguageSet code="HOME_TURST_ICON2_TEXT" /></div>
                    </div>
                </div>

                <div className="items">
                    <div className="icon">
                        <img src="/Assets/img/icon/iconset3New.png" alt="icon" />
                    </div>
                    <div className="detail">
                        <div className="title"><LanguageSet code="HOME_TURST_ICON3_TITLE" /></div>
                        <div className="text"><LanguageSet code="HOME_TURST_ICON3_TEXT" /></div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});


export default connect(mapStateToProps)(TrustIcon)
