import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setGuides, setSessions } from "../../Action/BookingAction"

export class BookingSesssionAndGuide extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    setGuide = (i) => {

        this.props.setGuides(i);

    }


    setSession = (i) => {


        const { engineData } = this.props;


        const data = {
            sessionId: engineData.sessions[i].quotaId,
            priceId: engineData.sessions[engineData.selectedSession].priceId,
            packageId: engineData.packageData[engineData.selectedPackage].id,
            productId: this.props.productId,
            date: engineData.selectedDate,
            selectedId:i

        }

        this.props.setSessions(data);



    }


    render() {

        const { guides, sessions } = this.props.engineData
        return (
            <>
                {guides.length > 0 &&
                    <div className='guides'>
                        <div className='guideTitle'>
                            Preferred Language
                        </div>
                        <div className='guideItem'>
                            {guides.map((item, index) => (
                                <div onClick={this.setGuide.bind(this, index)} key={index}
                                    className={!item.status ? "guide disabled" : (
                                        this.props.engineData.selectedGuide === index ? "guide active" : "guide default")}
                                >{item.language} {!item.status && " (Not Available)"}</div>

                            ))}
                        </div>
                    </div>
                }
                {sessions.length > 0 &&
                    <div className='sessions'>
                        <div className='sessionTitle'>
                            Preferred Session time
                        </div>
                        <div className='sessionItem'>
                            {sessions.map((item, index) => (
                                <div onClick={this.setSession.bind(this, index)} key={index}
                                    className={!item.status ? "session disabled" : (
                                        this.props.engineData.selectedSession === index ? "session active" : "session default")}
                                >{item.time} {!item.status && " (Not Available)"}</div>

                            ))}
                        </div>


                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    engineData: state.engineData

});

export default connect(mapStateToProps, { setGuides, setSessions })(BookingSesssionAndGuide)