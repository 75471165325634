import React, { Component } from 'react'

import { connect } from 'react-redux'

import { Link } from 'react-router-dom';
import { DropdownMenu } from '../../../Helpers/DropDownMenu';
import Language from './Language';
import Currency from './Currency';
import ManageBooking from './ManageBooking';

class Header extends Component {


    constructor(props) {
        super(props);

        this.state = {
            helpMenu: false,
            whiteAffix: false


        }
    }


    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }



    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {


        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        if (winScroll > 140) {
            this.setState({
                whiteAffix: true
            });
        } else {
            this.setState({
                whiteAffix: false
            });
        }
    }



    getLanguageName = () => {
        const lng = localStorage.getItem("langCode");
        let lngLabel = "";
        switch (lng) {
            case "en-US": lngLabel = "English"; break;
            case "de-DE": lngLabel = "Deutsch"; break;
            case "ru-RU": lngLabel = "Русский"; break;
            case "tr-TR": lngLabel = "Türkçe"; break;
            default: lngLabel = "English"; break;
        }
        return lngLabel;
    }

    getCurrency = () => {
        return this.capitalizeFirstLetter(localStorage.getItem("currency")) + " (" + localStorage.getItem("currSymbol") + ")"
    }

    capitalizeFirstLetter = ([first, ...rest], locale) => {
        return first.toUpperCase() + rest.join('').toLowerCase();
    }

    render() {

        return (
            <div className={this.state.whiteAffix ? "header sticky" : "header"}>

                <div className='left'>
                    <Link to="/">
                        <div className='logo'>
                            <img src="/Assets/img/logo_white.svg" className='desktop' alt="logo" />
                            <img src="/Assets/img/tourzoneIcon.svg" className='mobile' alt="logo" />
                        </div>
                    </Link>
                    <div className='hamburger'>
                        <img src="/Assets/img/icon/hamburgerMenu.svg" alt="menu" />
                    </div>
                </div>
                <div className='middle'></div>
                <div className='right'>
                    <ul>

                        <li className='desktop'>
                            <DropdownMenu title={this.getLanguageName()} menu={<Language />} />
                        </li>

                        <li className='desktop'>


                            <DropdownMenu title={this.getCurrency()} menu={<Currency />} />
                        </li>

                        <li>
                            <div className='topMenu'>
                                <div className='icon'>
                                    <img src="/Assets/img/icon/wishlist2.svg" alt="menu" />
                                </div>
                                <div className='menuTitle'>
                                    Wishlist
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className='topMenu'>
                                <div className='icon'>
                                    <img src="/Assets/img/icon/cart.svg" alt="menu" />
                                </div>
                                <div className='menuTitle'>
                                    <Link to="/Cart"> Cart</Link>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className='topMenu'>
                                <div className='icon'>
                                    <img src="/Assets/img/icon/manage.svg" alt="menu" />
                                </div>
                                <div className='menuTitle'>
                                    <Link to="/Cart">Manage Booking</Link>
                                    
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='topMenu'>
                                <div className='icon'>
                                    <img src="/Assets/img/icon/user.svg" alt="menu" />
                                </div>
                                <div className='menuTitle'>
                                    Login
                                </div>
                                <div className='chevron desktop'>
                                    <img src="/Assets/img/icon/down.svg" alt="down" />
                                </div>
                            </div>
                        </li>
                        <li className='desktop'>
                            <div className='signUp'>
                                Sign Up
                            </div>
                        </li>
                    </ul>

                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    appData: state.appData
});

export default connect(mapStateToProps)(Header)