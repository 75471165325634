import React, { Component } from 'react'



export default class MapMeetingPoint extends Component {
    map = false;

    
    constructor(props) {
        super(props);
        // this.onScriptLoad = this.onScriptLoad.bind(this)
        this.state = {
            options: {
                zoom: 2,
                panControl: true,
                center: { lat: 39.95808269729412, lng: 32.76679522600676 },
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                overviewMapControl: true,
                rotateControl: true
            },
            poly: [],
            poligons: [],
            oldSelected: props.data,
            loadingMap: true,
            errorMp: false,
            markers: "",
            randoms:Math.floor(Math.random()) 
        }


    }

    onScriptLoad() {

        if (window.google) {

            this.setState({
                loadingMap: false
            }, () => {
                this.map = new window.google.maps.Map(
                    document.getElementById("maps"+this.props.id),
                    this.state.options);



                this.setPolygons();
            });

        }
    }











    setPolygons = () => {

        const bounds = new window.google.maps.LatLngBounds();

        let _this = this;
        let polygon = []
        this.state.oldSelected.map((item, index) => {
            let coor = [];
            item.forEach((xy, i) => {
                let obj = {
                    lat: xy[0],
                    lng: xy[1]
                }
                coor.push(obj)
            });


            polygon[index] = new window.google.maps.Polygon({
                paths: coor,
                strokeColor: '#000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: 'green',
                fillOpacity: 0.35,
                editable: false,

            });



            polygon[index].setMap(this.map);


            polygon[index].getPath().forEach(function (path, index) {

                bounds.extend(path);
            });
        });

        this.setState({
            poly: this.state.poly.concat(polygon)
        }, () => {
            this.map.fitBounds(bounds);

        })
    }




    loadScript = () => {
        if (!window.google) {
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://maps.google.com/maps/api/js?key=AIzaSyAnrJeRkJego6p9bhXDJ6Dm1hpCuDvAd9k&libraries=places,geometry`;
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
            s.addEventListener('load', e => {
                this.onScriptLoad()
            })
        } else {
            console.log("asd");
            this.onScriptLoad()
        }
    }



    componentDidMount() {
        this.loadScript();

    }

    autocomplate = () => {

        let _this = this;
        const options = {
            fields: ["formatted_address", "geometry", "name"],
            strictBounds: true,
            types: ["establishment"],
        };
        const input = document.getElementById("autocomplates"+this.props.id)
        const autocomplete = new window.google.maps.places.Autocomplete(input, options);


        autocomplete.addListener("place_changed", () => {

           
            if (_this.state.markers !== "") {
                _this.state.markers.setMap(null);
            }

       
            const place = autocomplete.getPlace();

            let rst = false;
            _this.state.poly.map((item, index) => {
                const checker = window.google.maps.geometry.poly.containsLocation(
                    new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()), item);

                if (checker) {
                    rst = true;

                }
            });

            let marker = new window.google.maps.Marker({
                position: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                },
                map: _this.map,

            });

            _this.setState({
                markers: marker
            });


            if (!rst) {
                _this.setState({
                    errorMp: true
                })
            } else {
                _this.setState({
                    errorMp: false
                },()=> {
                    _this.props.setMapData(place)
                })
            }
        });
    }








    render() {

 
        return (

            <>
                {!this.state.loadingMap &&
                    <>
                        <div className="inputParent">
                            <div className="title">Enter Pickup location</div>
                            <input type="text" onChange={this.autocomplate} maxLength="30" id={"autocomplates"+this.props.id} name="autocomplates" placeholder="e.g. your hotel name" />
                        </div>
                        {this.state.errorMp &&
                            <div className='errorMp'>The pickup address you’ve entered is far away from the tour. Please modify your pickup address, or you will have to coordinate with the local partner after booking.</div>
                        }
                        <div className="maps" id={"maps"+this.props.id} />

                        
                    </>
                }
            </>
        )
    }
}
