import React, { Component } from 'react'
import { connect } from 'react-redux'

class Overview extends Component {


    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            readMore: false
        }
    }

    readMore = () => {
        this.setState({
            readMore: !this.state.readMore
        })
    }
    render() {
        const { data } = this.state

        return (
            <div className='overview'>
                <div className='linkMap'>
                    <ul>
                        {data.linkMaps.map((item, index) => (
                            <li key={index}>{item.name}</li>
                        ))}
                    </ul>
                </div>
                <div className='destinationName'>{data.destinationName}</div>
                <div className={this.state.readMore ? "overviewDetail full" : "overviewDetail less"} dangerouslySetInnerHTML={{ __html: data.overview }}>
                </div>
                <div className='readMore'>
                    <div className='readMoreText' onClick={this.readMore.bind(this)}>{this.state.readMore ? "Read Less" : "Read More"}</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(Overview)
