import { data } from 'dom7';
import React, { Component } from 'react'
import { connect } from 'react-redux'

class IncludeAndNotInclude extends Component {



    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,

        };
    }
    render() {
        return (
            <div className="includeAndNotInclude">
                <div className="include">
                    <div className="title">What's included</div>
                    <div className="content">
                        <ul>
                            {
                            this.state.data.include.map((item, index) => (
                                <li key={index}>
                                    <div className="icon"><img src="/Assets/img/icon/include.svg" alt="icon" /></div>
                                    <div className="text">{item.title}</div>
                                </li>
                            ))
                            }

                        </ul>
                    </div>
                </div>
                <div className="notInclude">
                    <div className="title">What's not included</div>
                    <div className="content">
                        <ul>
                        {
                            this.state.data.notInclude.map((item, index) => (
                                <li key={index}>
                                    <div className="icon"><img src="/Assets/img/icon/notInclude.svg" alt="icon" /></div>
                                    <div className="text">{item.title}</div>
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                </div>


            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(IncludeAndNotInclude)


