import React, { Component } from 'react'


export default class Language extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }


    }


    changeLanguage = (langCode, e) => {

        localStorage.setItem("langCode", langCode);
        window.location.reload();

    }


   
  


    render() {
        return (
            <>
                <div className="LanguagePopup">

                    <div className="LanguageList">
                        <div className="list">
                            <ul>
                                <li onClick={this.changeLanguage.bind(this, "en-US")}>
                                    <div className="lngIcon engFlag"></div>
                                    <div className="lngText"> English (United Kingdom) </div>
                                </li>
                                <li onClick={this.changeLanguage.bind(this, "de-DE")}>
                                    <div className="lngIcon deFlag"></div>
                                    <div className="lngText">  Deutsch (Deutschland)  </div>
                                </li>
                                <li onClick={this.changeLanguage.bind(this, "ru-RU")}>
                                    <div className="lngIcon ruFlag"></div>
                                    <div className="lngText">  Русский (Russian)  </div>
                                </li>
                                <li onClick={this.changeLanguage.bind(this, "tr-TR")}>
                                    <div className="lngIcon trFlag"></div>
                                    <div className="lngText"> Türkçe (Turkish) </div>
                                </li>

                            </ul>
                        </div>
                        

                    </div>


                </div>
            </>
        )
    }
}
