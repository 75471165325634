import React, { Component } from 'react'
import { connect } from 'react-redux'
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/flatpickr.min.css'


import { BookingPerson } from './BookingPerson';
import { BookingResult } from './BookingResult';
import axios from "axios";
import { BookingsAction } from "../Action/BookingAction"
import LoadingSmall from '../../../Helpers/LoadingSmall';
import BookingFixedBar from './BookingFixedBar';
import Moment from 'moment';



class Booking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bookingSpect: [],
            date: "",
            priceType: 1,
            bookingPerson: [],
            personText: "Quantity",
            personPopup: false,
            selectedDate: false,
            sendRequestLoading: 0,
            optionsPrice: [],
            returnDatas: [],
            showChekNow: true,
            languages: [],
            selectedGuideLanguage: "",
            lngPopup: false,
            cartLoading: false,
            productId: props.productId,
            calendarPrice: [],
            bookingLoading: true,
            availability: "",


        };
    }
    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside, false);
        this.getBookingSpect()


    }

    getBookingSpect = async () => {
        let data = {
            productId: this.state.productId
        }

        this.setState({
            bookingLoading: true
        });

        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getBookingSpect`, data)

            if (resp.status === 200) {
                this.setState({
                    bookingLoading: false,
                    bookingSpect: resp.data,
                    date: resp.data.minReservationDate.date,
                    priceType: resp.data.priceType,
                    languages: resp.data.guideLanguage,
                    selectedGuideLanguage: resp.data.guideLanguage.length > 0 ? resp.data.guideLanguage[0] : "",
                    calendarPrice: resp.data.calendarPrice,
                    availability: resp.data.availability
                }, () => {
                    if (this.props.edit) {
                        this.setState({
                            selectedDate: this.props.selectedDate
                        }, () => {
                            //  this.sendPrices();
                            this.calendar.flatpickr.setDate(this.props.selectedDate)

                        })
                    }
                });

            }
        } catch (err) {
            console.error(err);
        }

    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside, false);
    }
    myRef = React.createRef();
    languagePopup = React.createRef();
    calendar = React.createRef();



    handleClickOutside = e => {

        if (!this.myRef.current.contains(e.target)) {

            this.setState({
                personPopup: false
            })
        }

        if (this.state.languages.length > 0) {

            if (!this.languagePopup.current.contains(e.target)) {
                this.setState({
                    lngPopup: false
                });
            }
        }


    }


    openPersonPanel = () => {
        this.setState({
            personPopup: !this.state.personPopup
        })
    }

    getBookingPerson = (e) => {
        this.setState({
            bookingPerson: e,
        }, () => {
            this.bookingResultAction()
        });
    }

    bookingResultAction = () => {
        let nums = this.state.bookingPerson
        if (!this.state.selectedDate) {

            if (nums.totalNum > 0) {
                this.calendar.flatpickr.open();
            } else {

            }
        } else if (nums.totalNum < 1) {

        } else {
            this.setState({
                showChekNow: false
            })
            this.sendPrices()
        }
    }

    sendPrices = async () => {



        this.setState({
            sendRequestLoading: 1
        });

        const data = {
            productId: this.props.productId,
            selectedDate: this.state.selectedDate,
            bookingPerson: this.state.bookingPerson,
            selectedGuideLanguage: this.state.selectedGuideLanguage
        }


        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getBookingPrice`, data)

            if (resp.status === 200) {

                this.setState({
                    returnDatas: resp.data,
                    sendRequestLoading: 2
                })

                let elembooking = document.getElementsByClassName("booking")[0];



                window.scrollTo({
                    top: elembooking.offsetTop + 160,
                    behavior: "smooth"
                });




            }
        } catch (err) {
            console.error(err);
        }
    };


    book = (e, types) => {

        let data = {
            productId: this.props.productId,
            bookingPerson: this.state.bookingPerson,
            result: e
        }

        this.checkOut(data, types);
    }




    updateBook = async (e) => {


        let data = {
            bookId: this.props.bookId,
            productId: this.props.productId,
            bookingPerson: this.state.bookingPerson,
            result: e

        }


        this.setState({
            cartLoading: true
        });

        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/actionCart`, data)

            if (resp.status === 200) {


                this.setState({
                    cartLoading: false
                });

                const data = resp.data
                if (data.status) {
                    this.props.complete();

                }


            }
        } catch (err) {
            console.error(err);
        }
    }


    checkOut = async (data, types) => {

        this.setState({
            cartLoading: true
        });

        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/actionCart`, data)

            if (resp.status === 200) {


                this.setState({
                    cartLoading: false
                });

                const data = resp.data
                if (data.status) {

                    if (types === "checkOut") {
                        this.props.history.push('/checkout/' + resp.data.reservationId)
                    } else {
                        this.props.history.push('/Cart');
                    }
                }


            }
        } catch (err) {
            console.error(err);
        }
    }

    specialOffers = (day, weekDay, packageId) => {

        const { calendarPrice } = this.state;

        let weekDays
        switch (weekDay) {
            case 0: weekDays = "offerSun"; break;
            case 1: weekDays = "offerMon"; break;
            case 2: weekDays = "offerTue"; break;
            case 3: weekDays = "offerWed"; break;
            case 4: weekDays = "offerThu"; break;
            case 5: weekDays = "offerFri"; break;
            case 6: weekDays = "offerSat"; break;

            default: weekDays = "offerMon"; break
        }
        let discount = 1;
        calendarPrice.specialOffers.map((item) => {


            const startDate = Date.parse(item.startDate);
            const endDate = Date.parse(item.endDate);
            const days = Date.parse(Moment(day).format('YYYY-MM-DD'));


            if (days >= startDate && days <= endDate && (packageId === item.packageId || item.packageId === "0")) {
                if (item[weekDays] === "1") {

                    if (this.state.priceType === 1) {
                        discount = 1 - parseFloat(item.adult);
                    } else if (this.state.priceType === 2) {
                        discount = 1 - parseFloat(item.groups);
                    }
                }
            }
        });

        return discount;


    }

    enableDate = () => {
        const { calendarPrice } = this.state;

        let enableDate = [];


        calendarPrice.dates.map(item => {
            let obj = {
                from: item.startDate,
                to: item.endDate
            }

            enableDate.push(obj)
        });

        return enableDate
    }

    selectGuideLanguage = (e) => {
        this.setState({
            selectedGuideLanguage: e,
            lngPopup: false
        }, () => {
            this.sendPrices();
        });
    }

    openLngPopup = () => {
        this.setState({
            lngPopup: true
        });
    }

    alternativeDate = (e) => {

        this.setState({
            selectedDate: e
        }, () => {
            this.sendPrices();
            this.calendar.flatpickr.setDate(e)

        });

    }


    helperDay = (e) => {

        let dayName;

        switch (e) {
            case 0: dayName = "sun"; break;
            case 1: dayName = "mon"; break;
            case 2: dayName = "tue"; break;
            case 3: dayName = "wed"; break;
            case 4: dayName = "thu"; break;
            case 5: dayName = "fri"; break;
            case 6: dayName = "sat"; break;
            default: dayName = "sun"; break;
        }

        return dayName;

    }


    render() {
        const { calendarPrice } = this.state;

        return (
            <>

                <div className="booking spy" id="bookingSpy">
                    {this.props.edit ?
                        <div className="title">Update Booking</div>
                        : <div className="title">Booking</div>
                    }
                    {!this.props.edit &&
                        <div className="titleSub">Select participants and date</div>
                    }

                    {
                        this.state.bookingLoading ? <LoadingSmall /> :


                            <div className="bookingPanel">
                                <div className="dates">
                                    <Flatpickr
                                        ref={calendar => { this.calendar = calendar; }}
                                        placeholder="Select date"
                                        options={{
                                            minDate: Moment(this.state.date).format('YYYY-MM-DD'),
                                            maxDate: this.state.availability,
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "Y-m-d",
                                            enable: this.enableDate(),
                                            locale: {
                                                firstDayOfWeek: 1
                                            }
                                        }}
                                        onChange={(selectedDates, dateStr, instance) => {
                                            this.setState({
                                                selectedDate: dateStr
                                            }, () => {
                                                let nums = this.state.bookingPerson
                                                if (nums.groupPriceNum < 1 && nums.totalNum < 1) {
                                                    this.setState({
                                                        personPopup: !this.state.personPopup
                                                    })
                                                }

                                                this.bookingResultAction();
                                            })
                                        }}
                                        onDayCreate={(dObj, dStr, fp, dayElem) => {

                                            if (calendarPrice.dates.length > 0) {

                                                var cDate, minD;
                                                cDate = Date.parse(Moment(dayElem.dateObj).format('YYYY-MM-DD'));
                                                //dateNow = Date.parse(Moment(Date.now()).format('YYYY-MM-DD'));

                                                minD = Date.parse(Moment(this.state.date).format('YYYY-MM-DD'));

                                                let dayPrice = [];
                                                let currency = "";
                                                // if (cDate > minD) {
                                                calendarPrice.dates.map((item) => {
                                                    var fDate, lDate
                                                    fDate = Date.parse(Moment(item.startDate).format('YYYY-MM-DD'));
                                                    lDate = Date.parse(Moment(item.endDate).format('YYYY-MM-DD'));
                                                    if ((cDate <= lDate && cDate >= fDate)) {

                                                        if (item[this.helperDay(dayElem.dateObj.getDay())] === 1 &&
                                                            item.price[0][this.helperDay(dayElem.dateObj.getDay())] !== null) {
                                                            let discount = this.specialOffers(cDate, dayElem.dateObj.getDay(), item.packageId)
                                                            let dayPriceInfo = {
                                                                price: parseFloat(item.price[0][this.helperDay(dayElem.dateObj.getDay())]),
                                                                packageId: item.packageId,
                                                                discountRate: discount,
                                                                discountPrice: parseFloat(item.price[0][this.helperDay(dayElem.dateObj.getDay())]) * discount
                                                            }


                                                            dayPrice.push(dayPriceInfo);
                                                            currency = item.price[0].currSymbol

                                                        } else {

                                                            if (dayElem.className.includes("prevMonthDay")) {
                                                                dayElem.className = ' flatpickr-day prevMonthDay flatpickr-disabled'
                                                            } else if (dayElem.className.includes("nextMonthDay")) {
                                                                dayElem.className = ' flatpickr-day nextMonthDay flatpickr-disabled'
                                                            } else {
                                                                dayElem.className = ' flatpickr-day flatpickr-disabled'
                                                            }

                                                        }
                                                    }
                                                });


                                                if (dayPrice.length > 0) {

                                                    dayPrice.sort(function (a, b) { return a.discountPrice - b.discountPrice });

                                                    dayElem.innerHTML += "<span class='event'>" + currency + " " + parseFloat(dayPrice[0].discountPrice).toFixed(0) + "</span>";

                                                    if (dayElem.className.includes("prevMonthDay")) {
                                                        dayElem.className = ' flatpickr-day prevMonthDay flatpickr-disabled'
                                                    } else if (dayElem.className.includes("nextMonthDay")) {
                                                        dayElem.className = ' flatpickr-day nextMonthDay flatpickr-disabled'
                                                    } else {
                                                        dayElem.className = ' flatpickr-day'
                                                    }

                                                    if (dayPrice[0].discountRate < 1) {
                                                        let minDates = Date.parse(Moment(this.state.date).format('YYYY-MM-DD'));
                                                        if (cDate >= minDates) {
                                                            dayElem.className += ' discountColor';
                                                        }
                                                    }
                                                }

                                                if (cDate < minD) {
                                                    dayElem.className = ' flatpickr-day flatpickr-disabled'
                                                }
                                            }
                                        }}
                                    />
                                    <div className="icon">
                                        <img src="/Assets/img/icon/calendar.svg" alt="Search" />
                                    </div>
                                </div>
                                <div className="person" ref={this.myRef}>
                                    <div className="personInput" onClick={this.openPersonPanel.bind(this)}>{this.state.bookingPerson.personText}</div>
                                    <div className="icon">
                                        <img src="/Assets/img/icon/profile.svg" alt="Search" />
                                    </div>

                                    <div className="personPopup" style={{
                                        display: this.state.personPopup ? "block" : "none"
                                    }} >
                                        <BookingPerson spect={this.state.bookingSpect} edit={this.props.edit} getBookingPerson={this.getBookingPerson} close={this.openPersonPanel.bind(this)} />

                                    </div>

                                </div>
                                {
                                    this.state.languages.length > 0 &&

                                    <div className="guideLanguage" ref={this.languagePopup} onClick={this.openLngPopup.bind(this)}>
                                        <div className="guideInput">
                                            {this.state.selectedGuideLanguage.language}
                                        </div>
                                        <div className="icon">
                                            <img src="/Assets/img/productIcon/2.svg" alt="Search" />
                                        </div>
                                        {this.state.lngPopup &&
                                            <div className='languagePopup' >
                                                <ul>
                                                    {this.state.languages.map((item, index) => (
                                                        <li key={index}>

                                                            <label htmlFor={"f-option" + index} className="l-radio">
                                                                <input type="radio" id={"f-option" + index}
                                                                    onChange={this.selectGuideLanguage.bind(this, item)}
                                                                    name="selector"
                                                                    tabIndex={index + 1}
                                                                    checked={this.state.selectedGuideLanguage.id === item.id ? true : false}
                                                                />
                                                                <span>{item.language}</span>
                                                            </label>

                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                    }
                    {this.state.sendRequestLoading === 2 ?
                        <div className='bookingResultParent'>
                            <div className="title">Choose Package</div>
                            <BookingResult productSpect={this.props.data} data={this.state.returnDatas} personText={this.state.bookingPerson.personText} book={this.book.bind(this)} updateBook={this.updateBook} edit={this.props.edit} alternativeDate={this.alternativeDate} />
                        </div>
                        : this.state.sendRequestLoading === 1 ?
                            <div className="priceLoading"><LoadingSmall /></div>
                            : ""
                    }



                </div>
                {!this.props.edit &&
                    <>
                        <BookingFixedBar data={this.props.data} />
                    </>
                }
                {this.state.cartLoading &&
                    <div className='loadingBack'>
                        <LoadingSmall />
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    bookingsData: state.bookingsData
});

export default connect(mapStateToProps, { BookingsAction })(Booking)