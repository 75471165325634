import React, { Component } from 'react'
import { connect } from 'react-redux'
import CurrencyFormat from 'react-currency-format';

import { updateOptionsPax, updateOptionsPrice } from "../Action/BookingEngineAction"

export class BookingOptionItems extends Component {


  minus = () => {

    if (this.props.items.quantity > 0) {

      const data = {
        id: this.props.items.id,
        quantity: this.props.items.quantity - 1,
        productId: this.props.engineData.productId,
        packageId: this.props.engineData.selectedPackage,
        dates: this.props.engineData.selectedDate

      }

      this.props.updateOptionsPax(data);

      this.props.updateOptionsPrice(data);

    }

  }

  plus = () => {
    const { engineData } = this.props;


    if (engineData.settingsData.maxReservation > this.props.items.quantity) {

      const data = {
        id: this.props.items.id,
        quantity: this.props.items.quantity + 1,
        productId: this.props.engineData.productId,
        packageId: this.props.engineData.selectedPackage,
        dates: this.props.engineData.selectedDate

      }
      this.props.updateOptionsPax(data);
      this.props.updateOptionsPrice(data);

    }


  }
  render() {

    const { items, engineData } = this.props;


    return (
      <div className='addOns'>

        <div className='img'>
          <img src={process.env.REACT_APP_ASSETSNEW_ADDRESS + 'images/nophotoThumb.jpg'} alt='thumb' />
          <div className='priceBar'>
            {items.spo &&
              <div className='spo'>

                <CurrencyFormat value={items.nonSpoPrice}
                  displayType={'text'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={items.exchange.toSymbol + " "} />
              </div>

            }
            <CurrencyFormat value={items.price}
              displayType={'text'}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={items.exchange.toSymbol + " "} />

          </div>
          {items.spo &&
            <div className='discountTriangle'>
              <div className='rates'>
                Save up to
                <CurrencyFormat value={(1 - items.spoRate) * 100}
                  displayType={'text'}
                  decimalScale={0}
                  fixedDecimalScale={false}
                  thousandSeparator={true}
                  prefix={" % "} />
              </div>
            </div>
          }
        </div>
        <div className='optTitle'>{items.optionsTitle}</div>
        <div className='optDetail'>{items.optionsDetail}</div>
        <div className='quantity'>
          <div className="optionsPlus">
            <div className="personNum">
              <div className="minus" onClick={this.minus.bind(this)} >

                <img src="/Assets/img/icon/minus.svg" alt="Search" /></div>
              <div className="num">

                {items.quantity}

              </div>
              <div className="plus" onClick={this.plus.bind(this)}>

                {(engineData.optionsPriceLoading) ?
                  <img src="/Assets/img/icon/plusDisable.svg" alt="Search" />
                  :
                  <img src="/Assets/img/icon/plus.svg" alt="Search" />
                }
              </div>
            </div>
          </div>{items.quantity > 0 &&
          <div className='totalPrice'>

            {items.spo &&
              <div className='spo'>

                <CurrencyFormat value={items.nonSpoTotal}
                  displayType={'text'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={items.exchange.toSymbol + " "} />
              </div>

            }
            <CurrencyFormat value={items.total}
              displayType={'text'}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={items.exchange.toSymbol + " "} />
          </div>
        }
        </div>
        
      </div>

    )
  }
}

const mapStateToProps = (state) => ({
  appData: state.appData,
  engineData: state.engineData,


});


export default connect(mapStateToProps, { updateOptionsPax, updateOptionsPrice })(BookingOptionItems)