import React, { Component } from 'react'
import { connect } from 'react-redux'

class ImportantToknow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,

        };
    }
    render() {
        return (
            <div className="importantToKnow spy" id="importantoknowSpy">
                <div className="title">Important to know</div>
                <div className="content">
                    <ul>
                        {this.state.data.info.knowBeforeYouBook !== false &&
                            <li>
                                <div className="icon"><img src="/Assets/img/icon/book2.svg" alt="icon" /></div>
                                <div className="text">
                                    <div className="title">KNOW BEFORE YOU BOOK</div>
                                    <ul>
                                        {this.state.data.info.knowBeforeYouBook.map((item, index) => (
                                            <li key={index}>{item.text}</li>
                                        ))}

                                    </ul>
                                </div>
                            </li>
                        }

                        {this.state.data.info.notAllowed !== false &&
                            <li>
                                <div className="icon"><img src="/Assets/img/icon/notallowed.svg" alt="icon" /></div>
                                <div className="text">
                                    <div className="title">NOT ALLOWED</div>
                                    <ul>
                                        {this.state.data.info.notAllowed.map((item, index) => (
                                            <li key={index}>{item.text}</li>
                                        ))}

                                    </ul>
                                </div>
                            </li>
                        }

                        {this.state.data.info.usefullTips !== false &&
                            <li>
                                <div className="icon"><img src="/Assets/img/icon/notallowed.svg" alt="icon" /></div>
                                <div className="text">
                                    <div className="title">KNOW BEFORE YOU BOOK</div>
                                    <ul>
                                        {this.state.data.info.usefullTips.map((item, index) => (
                                            <li key={index}>{item.text}</li>
                                        ))}

                                    </ul>
                                </div>
                            </li>
                        }

                        {this.state.data.info.additionalInfo !== false &&
                            <li>
                                <div className="icon"><img src="/Assets/img/icon/additionalInfo.svg" alt="icon" /></div>
                                <div className="text">
                                    <div className="title">ADDITIONAL INFORMATION</div>
                                    <ul>
                                        {this.state.data.info.additionalInfo.map((item, index) => (
                                            <li key={index}>{item.text}</li>
                                        ))}

                                    </ul>
                                </div>
                            </li>
                        }

                        {this.state.data.info.howItWorks !== false &&
                            <li>
                                <div className="icon"><img src="/Assets/img/icon/book2.svg" alt="icon" /></div>
                                <div className="text">
                                    <div className="title">KNOW BEFORE YOU BOOK</div>
                                    <ul>
                                        {this.state.data.info.howItWorks.map((item, index) => (
                                            <li key={index}>{item.text}</li>
                                        ))}

                                    </ul>
                                </div>
                            </li>
                        }

                        {this.state.data.info.whatToRemember !== false &&
                            <li>
                                <div className="icon"><img src="/Assets/img/icon/book2.svg" alt="icon" /></div>
                                <div className="text">
                                    <div className="title">KNOW BEFORE YOU BOOK</div>
                                    <ul>
                                        {this.state.data.info.whatToRemember.map((item, index) => (
                                            <li key={index}>{item.text}</li>
                                        ))}

                                    </ul>
                                </div>
                            </li>
                        }
                    </ul>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(ImportantToknow)
