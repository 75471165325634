import React, { Component } from 'react'
import { connect } from 'react-redux'
import TravelTips from './TravelTips';
import TopThingsTodo from './TopThingsTodo';
import Experience from './Experience';
class Buttons extends Component {

    constructor(props) {
        super(props);
        this.state = {
            taps: 1
        }
    }

    setTaps = (taps) => {
        this.setState({
            taps: taps
        })
    }
    render() {
        return (
            <>
                <div className='buttonSet'>
                    <div className={this.state.taps === 1 ? "item active" : "item"} onClick={this.setTaps.bind(this, 1)}>
                        <div className="icon"><img src="/Assets/img/icon/tourzoneIcons-gray.svg" alt="Search" /></div>
                        <div className="text">Travel Guide</div>
                    </div>

                    <div className='item'>
                        <div className="icon"><img src="/Assets/img/icon/location.svg" alt="Search" /></div>
                        <div className="text">Eat & Drink</div>
                    </div>
                    <div className='item'>
                        <div className="icon"><img src="/Assets/img/icon/location.svg" alt="Search" /></div>
                        <div className="text">See & Do</div>
                    </div>
                    <div className='item'>
                        <div className="icon"><img src="/Assets/img/icon/location.svg" alt="Search" /></div>
                        <div className="text">Shop & Smile</div>
                    </div>

                    <div className={this.state.taps === 5 ? "item active" : "item"} onClick={this.setTaps.bind(this, 5)}>
                        <div className="icon"><img src="/Assets/img/icon/location.svg" alt="Search" /></div>
                        <div className="text">Top Things To Do</div>
                    </div>

                    <div className={this.state.taps === 6 ? "item active" : "item"} onClick={this.setTaps.bind(this, 6)}>
                        <div className="icon"><img src="/Assets/img/icon/ticket.svg" class="icon" alt="Facebook" /></div>
                        <div className="text">Experience</div>
                    </div>
                </div>
                {this.state.taps === 1 &&
                    <>
                       
                        <TravelTips data={this.props.data} />
                    </>
                }
                {this.state.taps === 5 &&
                    <TopThingsTodo data={this.props.data} />
                }
                {this.state.taps === 6 &&
                    <Experience data={this.props.data} destinationId={this.props.destinationId} />
                }
            </>

        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
})
export default connect(mapStateToProps)(Buttons)
