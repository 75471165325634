import React, { Component } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Autoplay } from 'swiper';
import { connect } from 'react-redux'
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import LanguageSet from '../../../Helpers/LanguageSet';

class DestinationSlider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            getSwiperIndex: 0
        }
        SwiperCore.use([EffectFade]);
        SwiperCore.use([Autoplay]);
    }

    nextSlide = (e) => {
        //console.log(e);
    }

    progress = (e, progress) => {

    }

    startSlide = (e) => {
        setTimeout(
            () => this.setState({
                getSwiperIndex: e.realIndex
            }),
            1000
        );



    }

    endSlide = (e) => {

    }
    getClass = (e) => {

    }

    render() {

        return (
            <div className="productSlider">


                <Swiper

                    spaceBetween={15}
                    slidesPerView={1}
                    centeredSlides={true}
                    effect={"fade"}
                    fadeEffect={{ crossFade: true }}
                    loop={false}
                    autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                    }}
                    onSlideChange={(e) => this.nextSlide(e)}
                    onProgress={(e, progress) => this.progress(e, progress)}
                    onSlideChangeTransitionEnd={
                        (e) => this.endSlide(e)
                    }
                    onSlideChangeTransitionStart={
                        (e) => this.startSlide(e)
                    }

                >
                    {this.state.data.destinationImg.filter(item => item.location === "story").map((item, index) => (



                        <SwiperSlide key={index}>
                            <div className="sliderItem">
                                <img src={process.env.REACT_APP_ASSETS_ADDRESS + "images/destinations/" + item.name + "-ResizeS" + item.ext} alt="slider img" />
                            </div>
                        </SwiperSlide>

                    ))}
                    <div className="progress-container">
                        <div className="progress"></div>
                    </div>
                </Swiper>

            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(DestinationSlider)