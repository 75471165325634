import React, { Component } from 'react'
import { connect } from 'react-redux'
import BookingPersonDetail from './BookingPersonDetail';



export class BookingPerson extends Component {



    render() {


        const { settingsData, priceListLoading } = this.props.engineData;


        return (
            <>
                <div className="personDiv">
                    <div className="title">Quantity</div>
                    {!priceListLoading &&

                        <>
                            {settingsData.block.map((item, index) => (

                                <>
                                    {item.status &&
                                        <BookingPersonDetail productId={this.props.productId} data={item} />
                                    }
                                </>
                            ))
                            }
                        </>
                    }
                </div>


            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    engineData: state.engineData,


});



export default connect(mapStateToProps)(BookingPerson)