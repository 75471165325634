import React, { Component } from 'react'
import { connect } from 'react-redux'
import CurrencyFormat from 'react-currency-format';
import { UpdatePax, UpdateBlockPrice } from "../../Action/BookingAction"


export class BookingPersonDetail extends Component {


    constructor(props) {
        super(props);
        this.state = {
            maxContractPerson: []
        }

    }

    minus = () => {
        const { engineData } = this.props;
        let start = this.props.data.min > 0 ? this.props.data.min : 1
        if (this.props.data.pax > 0) {
            if (this.props.data.require) {

                if (start < this.props.data.pax) {
                    let data = this.props.engineData.pax.map(
                        obj => (obj.id === this.props.data.id ? Object.assign(obj, { pax: this.props.data.pax - 1 }) : obj)
                    )

                    const datas = {
                        newPriceList: data,
                        sessionId: engineData.sessions[engineData.selectedSession].quotaId,
                        priceId: engineData.sessions[engineData.selectedSession].priceId,
                        packageId: engineData.packageData[engineData.selectedPackage].id,
                        productId: this.props.productId,
                        date: engineData.selectedDate,
                        block: this.props.data.id,
                        pax: this.props.data.pax
                    }


                    this.props.UpdatePax(datas)
                }


            } else {
                let newPax;
                if (this.props.data.pax === this.props.data.min) {
                    newPax = 0
                } else {
                    newPax = this.props.data.pax - 1
                }
                let data = this.props.engineData.pax.map(
                    obj => (obj.id === this.props.data.id ? Object.assign(obj, { pax: newPax }) : obj)
                )


                const datas = {
                    newPriceList: data,
                    sessionId: engineData.sessions[engineData.selectedSession].quotaId,
                    priceId: engineData.sessions[engineData.selectedSession].priceId,
                    packageId: engineData.packageData[engineData.selectedPackage].id,
                    productId: this.props.productId,
                    date: engineData.selectedDate,
                    block: this.props.data.id,
                    pax: this.props.data.pax
                }

                console.log(datas)


                this.props.UpdatePax(datas)
            }
        }


    }

    plus = () => {

        const { settingsData } = this.props.engineData;


        if (this.getTotalPax() < settingsData.maxReservation) {

            if (this.state.maxContractPerson > this.props.data.pax) {

                let data = this.props.engineData.pax.map(
                    obj => (obj.id === this.props.data.id ? Object.assign(obj, { pax: this.props.data.pax + 1 }) : obj)
                )
                const { engineData } = this.props;

                const datas = {
                    newPriceList: data,
                    sessionId: engineData.sessions[engineData.selectedSession].quotaId,
                    priceId: engineData.sessions[engineData.selectedSession].priceId,
                    packageId: engineData.packageData[engineData.selectedPackage].id,
                    productId: this.props.productId,
                    date: engineData.selectedDate,
                    block: this.props.data.id,
                    pax: this.props.data.pax
                }


                this.props.UpdatePax(datas);


            }



        }
    }

    getTotalPax = () => {


        let totalPax = 0;

        this.props.engineData.pax.map(item => {
            totalPax += item.pax;

        });



        return totalPax;

    }

    getFirstPrice = () => {



        const { priceList } = this.props.engineData;




        let p = null;

        priceList.filter(item => item.id === this.props.data.id).map(items => {



            if (items.start <= this.props.data.pax &&
                items.end >= this.props.data.pax) {

                p = items;

            } else if (items.start === 1 && this.props.data.pax === 0) {

                p = {

                    price: priceList[this.props.data.id].price,
                    nonSpoPrice: priceList[this.props.data.id].nonSpoPrice,
                    spo: priceList[this.props.data.id].spo,
                    exchange: priceList[this.props.data.id].exchange,
                };

            }


        });




        return p


    }


    componentDidMount() {
        const { priceList } = this.props.engineData;

        let maxContractPerson = []
        priceList.filter(item => item.id === this.props.data.id).map(items => {
            maxContractPerson.push(items.end);
        });

        maxContractPerson.sort(function (a, b) { return b - a })

        this.setState({
            maxContractPerson: maxContractPerson[0]
        })

    }




    render() {

        const { data, engineData } = this.props;
        console.log(this.props)
        return (
            <>
                <div className='option'>
                    <div className='name'>
                        <div className='title'>
                            {data.title} ({data.blockInfo.title} {data.blockInfo.start}  - {data.blockInfo.end})
                        </div>
                    </div>
                    <div className="right">
                        <div className='optionPrice'>
                            {this.getFirstPrice() !== null &&
                                <>
                                    {engineData.priceListLoading &&

                                        <div className='lds-ellipsis' style={{ top: "-5px" }}>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    }

                                    {this.props.data.spo &&

                                        <div className='spo'>

                                            {

                                                <CurrencyFormat value={this.getFirstPrice().nonSpoPrice}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                    prefix={this.getFirstPrice().exchange.toSymbol + " "} />

                                            }
                                        </div>
                                    }

                                    <div className='price'>
                                        <CurrencyFormat value={this.getFirstPrice().price}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                            prefix={this.getFirstPrice().exchange.toSymbol + " "} />
                                    </div>
                                </>
                            }
                        </div>
                        <div className="optionsPlus">
                            <div className="personNum">
                                <div className="minus" onClick={this.minus.bind(this, "adult")} >
                                    <img src="/Assets/img/icon/minus.svg" alt="Search" /></div>
                                <div className="num">{data.pax} {data.quantity.title}</div>
                                <div className="plus" onClick={this.plus.bind(this, "adult")}>
                                    <img src="/Assets/img/icon/plus.svg" alt="Search" />
                                </div>
                            </div>
                        </div>
                        <div className='optionPrice end'>
                            <div className='price'>
                                <CurrencyFormat value={""}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    prefix={""} />
                            </div>
                        </div>
                    </div>
                </div>




            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    engineData: state.engineData,


});



export default connect(mapStateToProps, { UpdatePax, UpdateBlockPrice })(BookingPersonDetail)