import { useState, useEffect, useRef } from "react";


export const useDetectOutsideClick = (el, initialState) => {
    const [isActives, setIsActives] = useState(initialState);

    useEffect(() => {
        const onClick = e => {
            // If the active element exists and is clicked outside of
         //   if (el.current !== null && !el.current.contains(e.target)) {
                setIsActives(!isActives);
          //  }

            
        };

        // If the item is active (ie open) then listen for clicks outside
        if (isActives) {
            document.addEventListener("click", onClick);

        }

        return () => {
            document.removeEventListener("click", onClick);
        };
    }, [isActives, el]);

    return [isActives, setIsActives];
};


export const DropdownMenu = (props) => {
    const dropdownRef = useRef(null);
    const [isActives, setIsActives] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActives(!isActives);

   // console.log(isActives)

    return (
        <div className="menu-container">
            <div className='menu-Button' onClick={onClick}>


                <div className='menuTitle'>{props.title}</div>
                <div className='chevron'>
                    <img src="/Assets/img/icon/down.svg" alt="down" />
                </div>
            </div>
            <nav
                ref={dropdownRef}
                className={`menu ${isActives ? "active" : "inactive"}`}
            >
                {props.menu}
            </nav>



        </div>
    )
}