import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from 'moment';
class BookingFixedBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bookingBottom: false,
            minPrice: false,

        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
     
    }



    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);

    }

    listenToScroll = () => {


        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop
        let elembooking = document.getElementsByClassName("bookingNew")[0].getBoundingClientRect();


        let height = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        )



        let barWidth = Math.round((winScroll + window.innerHeight) * 100 / height);
        if (elembooking.bottom < 200 && barWidth < 99) {
            this.setState({
                bookingBottom: true
            });
        } else {
            this.setState({
                bookingBottom: false
            });
        }





    }



    getBooking = () => {
        let elembooking = document.getElementsByClassName("bookingNew")[0];

        window.scrollTo({
            top: elembooking.offsetTop - 110,
            behavior: "smooth"
        });

    }

   
    render() {

        const { engineData, data } = this.props;


       
        return (
            <div className="bookingFixedBar" style={{
                visibility: this.state.bookingBottom ? "visible" : "hidden"
            }}>



                <div className="checkAvailability">


                    <>

                        <div className="title">
                            <div className="productName">{data.productTitle}</div>
                            <div className="pricesGroup">
                               
                                    <>
                                        <div className='prices discountPrices'>

                                            <div className="price discount">
                                            
                                            </div>
                                            <div className="currency discount">
                                            
                                            </div>
                                        </div>
                                        <div className='prices lastPrices'>
                                            <div className="currency">
                                            
                                            </div>
                                            <div className="price">
                                            
                                            </div>
                                        </div>

                                    </>
                                 
                               
                            </div>
                            <div className='fixedButtons'>

                                <div className='bookButton' onClick={this.getBooking.bind(this)}>Check Availability</div>

                            </div>
                        </div>



                    </>


                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    engineData: state.engineData
});

export default connect(mapStateToProps)(BookingFixedBar)