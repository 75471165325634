import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom"
import { connect } from 'react-redux'
import { AppsAction } from "../Action/AppAction"

/*
Components
*/

import Header from "../../Header/Component/Header"
import Home from '../../Home/Component/Home'
import Product from '../../Product/Component/Product'
import Destination from '../../Destination/Component/Destination'
import Checkout from '../../Checkout/Component/Checkout'
import CompletedBooking from '../../CompletedBooking/Component/CompletedBooking'
import Footer from '../../Header/Component/Footer'
import Search from '../../Search/Component/Search'
import Cart from '../../Cart/Component/Cart'

class App extends Component {


    componentDidMount() {

        if (localStorage.getItem("guid")) {
            this.props.AppsAction(localStorage.getItem("guid"));
        } else {
            this.props.AppsAction();
        }

    }

    render() {
        const { loading } = this.props.appData

        return (

            <Router>
                {!loading ?
                    <>
                        <Header history={this.props.history}/>
                        <Route path="/" exact strict component={Home} />
                        <Route path="/Product/:code" exact strict component={Product} />
                        <Route path="/Destination/:code" exact strict component={Destination} />
                        <Route path="/Checkout/:code" exact strict component={Checkout} />
                        <Route path="/Cart" exact strict component={Cart} />
                        <Route path="/Search/:q" exact strict component={Search} />
                        <Route path="/CompletedBooking/:code" exact strict component={CompletedBooking} />
                        <Footer />
                    </>
                    :
                    ""
                }
            </Router>
        )
    }
}
const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps, { AppsAction })(App)