import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import axios from "axios";
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';

import Moment from 'moment';
Moment.locale('en')
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiZW1yZWNldGUiLCJhIjoiY2tqM2N3ZGQxMmFqbDJ0bnhtenRvZW1kcCJ9.Y2wLTA5RF0gzpqtcM8kzRA';

class CompletedBooking extends Component {


    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.code,
            loading: false,
            data: [],
            lng: -70.9,
            lat: 42.35,
            zoom: 13,
            maxZoom: 13,
            sendRequestLoading: true,
            maps: ""

        };
        this.mapContainer = React.createRef();
    }



    componentDidMount() {

        //  this.getDetail()


    }




    getDetail = async () => {


        let data = {
            id: this.state.id
        }

        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getCompletedBooking`, data)

            if (resp.status === 200) {
                const result = resp.data
                this.setState({
                    loading: false,
                    data: resp.data
                });

                if (resp.data.pickup.type === "map") {
                    this.setState({
                        lng: resp.data.pickup.data.geometry.location.lng,
                        lat: resp.data.pickup.data.geometry.location.lat,
                    }, () => {
                        this.initMap();
                    });

                }


            }
        } catch (err) {
            console.error(err);
        }
    }





    render() {

        // console.log(this.state)
        const { data } = this.state;
        return (
            <>
                {!this.state.loading &&
                    <div className="completedBooking">
                        <div className='img'>
                        <img src="/Assets/img/icon/check.svg" alt="check" />
                        </div>
                        <div className='title'>
                            Your payment has been made successfully.
                        </div>
                      
                        <div className='message'>
                            All the details you need about the activity you purchased are attached to the e-mail.
                            <br />

                            Before you go, you can print out the attachment or download the e-ticket to your mobile phone.
                            <br />
                            <br />
                            Have fun!
                            <br />
                            <br />
                            If you need to make changes to your reservation, you will need the reference number and PIN code. If you are a Tourzone member, you can use the Manage My Booking tab.
                            <br />
                            <br />
                        </div>


                    </div>
                }
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(CompletedBooking)

