import React, { Component } from 'react';
import { connect } from 'react-redux';


import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiZW1yZWNldGUiLCJhIjoiY2tqM2N3ZGQxMmFqbDJ0bnhtenRvZW1kcCJ9.Y2wLTA5RF0gzpqtcM8kzRA';

export class TopThingsTodo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: -70.9,
            lat: 42.35,
            zoom: 7,
            maxZoom: 13,
            data: this.props.data.mapTopThingsToDo,
            sendRequestLoading: true,
            maps: ""

        };
        this.mapContainer = React.createRef();

    }

    getMap = () => {



        this.setState({

            sendRequestLoading: false,

        }, () => {

            this.initMap()

        });




    };

    initMap = () => {
        const { lng, lat, zoom } = this.state;
        const map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/emrecete/cklz59m697xfa17mwet3yi7sr',
            center: [lng, lat],
            zoom: zoom
        });

        map.scrollZoom.disable();

        this.setState(
            {
                maps: map
            }
        )
        map.addControl(new mapboxgl.NavigationControl());

        let _this = this;


        map.once('style.load', function (e) {

        });


        map.on('load', function () {
            /*
            
            marker
            */


            _this.state.data.data.features.forEach((marker, i) => {
                let el = document.createElement('div');
                el.className = 'marker';
                let total = _this.state.data.data.features.length

                el.innerHTML = '<span><b>' + (i + 1) + '</b></span>';

                new mapboxgl.Marker(el)
                    .setLngLat(marker.geometry.coordinates)
                    .setPopup(new mapboxgl.Popup({
                        offset: 25
                    }).setHTML('<h5>' + marker.properties.title + '</h5><p></p>'))
                    .addTo(map);

            });






            let bounds = _this.state.data.data.features.reduce(function (bounds, feature) {
                if (!Array.isArray(feature.geometry.coordinates[0])) { // point feature
                    return bounds.extend(feature.geometry.coordinates);
                } else {
                    return feature.geometry.coordinates.reduce(function (bounds, coord) {
                        return bounds.extend(coord);
                    }, bounds);
                }
            }, new mapboxgl.LngLatBounds());


            map.fitBounds(bounds, {

                padding: { top: 150, bottom: 150, left: 150, right: 150 },
                easing(t) {
                    return t * (2 - t);
                },
            });


            map.addSource('points', {
                'type': 'geojson',
                'data': _this.state.data.data
            });

            map.addLayer({
                'id': 'points',
                'type': 'symbol',
                'source': 'points',
                'layout': {

                    'text-field': ['get', 'title'],
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold'
                    ],
                    'text-offset': [0, 1.25],
                    'text-anchor': 'top'
                }
            });


        });


    }

    componentDidMount() {
        this.getMap()
    }
    render() {

        const { topThingsToDo } = this.props.data
        return (
            <div className='mapParent'>
                <div className='list'>
                    <div className='title'>TOP THINGS TO DO</div>
                    <ul>
                        {topThingsToDo.map((item, index) => (
                            <li key={index}>
                                <div className='products'>
                                    <div className='productImg'><img src={process.env.REACT_APP_ASSETS_ADDRESS + "images/tour/" + item.img} alt=""/></div>
                                    <div className='detail'>
                                        <div className='title'>{item.title}</div>
                                        <div className='categories'>{item.categories}</div>
                                    
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="maps">
                    <div ref={this.mapContainer} className="map-container" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
})

export default connect(mapStateToProps)(TopThingsTodo)


class MyCustomControl {


    onAdd(map) {
        this.map = map;


        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon" + " helpMap";
        this._btn.type = "button";
        this._btn.title = "?";
        this._btn.innerHTML = "?";
        this._btn.onclick = this.openHelpWindow;

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
    }
    onRemove() {
        this.container.parentNode.removeChild(this._container);
        this.map = undefined;
    }

    openHelpWindow() {
        let elem = document.querySelector(".popupInfo");
        console.log(elem.classList.contains("active"));
        if (!elem.classList.contains("active")) {
            elem.classList.add("active");
        } else {
            elem.classList.remove("active");
        }

    }
}