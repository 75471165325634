import React from 'react';
import ReactDOM from 'react-dom';
import App from "./Components/App/Component/App"
import { Provider } from 'react-redux'
import store from './Components/store'
import { setAuthorizationToken, setLanguageHeader, setguid, getRefreshPageTokenSet,setCurrency } from "./Helpers/setAuthorizationToken";
import detectBrowserLanguage from 'detect-browser-language'

import reportWebVitals from './reportWebVitals';


/*
GUID CHECK
*/

const guid = localStorage.getItem("guid");

if (guid) {
  setguid(guid)
}


/*
*/

const langCode = localStorage.getItem("langCode");
if (!langCode) {
  localStorage.setItem("langCode", detectBrowserLanguage());
  setLanguageHeader(detectBrowserLanguage());
} else {
  setLanguageHeader(langCode);
}

const currency = localStorage.getItem("currency");
const currSymbol = localStorage.getItem("currSymbol");
if (!currency) {
  localStorage.setItem("currency", "USD");
  localStorage.setItem("currSymbol", "$");
  setCurrency("USD")
} else {
  setCurrency(currency)
}



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
