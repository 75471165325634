import React, { Component } from 'react'
import { connect } from 'react-redux'
import LanguageSet from '../../../Helpers/LanguageSet';
import moment from 'moment';
import enLocale from 'moment/locale/en-gb'
import deLocale from 'moment/locale/de'
import ruLocale from 'moment/locale/ru'
import trLocale from 'moment/locale/tr'
class ProductSpec extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data
        }

    }

    availabilityDate = (e) => {



        moment.locale(this.props.appData.appData.sites.data.language.langCode, [enLocale, deLocale, ruLocale, trLocale])

        return moment(e).format("ll")

    }


    setDurationTime = (e) => {
        let durationString = "";
        if (e.day !== 0) {
            durationString = e.day + " Day ";
        }

        if (e.hours !== 0) {
            durationString = durationString + e.hours + " Hour ";
        }

        if (e.minutes !== 0) {
            durationString = durationString + e.minutes + " Min ";
        }

        return durationString;

    }

    level = (e) => {
        if (e === 1) {
            return "PRODUCT_ICON_LEVEL_LEISURELY"
        } else if (e === 2) {
            return "PRODUCT_ICON_LEVEL_MODERATE"
        } else if (e === 3) {
            return "PRODUCT_ICON_LEVEL_CHALLENGING"
        } else if (e === 4) {
            return "PRODUCT_ICON_LEVEL_TOUGH"
        }
    }

    getLanguage = () => {
        let lng = [];
        this.state.data.product.spect.language.map(item => {
            lng.push(item.language);
        });

        return lng
    }
    render() {

        const { spect } = this.state.data.product

        return (
            <>
                <div className="productSpect spy" id="overviewSpy">

                    <div className="title">
                        {this.state.data.productTitle}
                    </div>

                    <div className="productRoad">
                        <LanguageSet code="PRODUCT_PRODUCT_ADDRESS_LINE" /> / {this.state.data.locationAndType.startDestination}	&rarr; {this.state.data.locationAndType.mainDestination} / {this.state.data.productTitle}
                    </div>

                    <div className="titlesub">
                        {this.state.data.locationAndType.mainCategories}
                    </div>
                    <div className="line"></div>
                    <div className="overview">
                        <div className="iconSet">
                            <div className="icon"><img src="/Assets/img/productIcon/1.svg" alt="icon" /></div>
                            <div className="text"><b><LanguageSet code="PRODUCT_ICON_AVAILABILITY" />:</b> {this.availabilityDate(spect.availability)}</div>
                        </div>

                        <div className="iconSet">
                            <div className="icon"><img src="/Assets/img/productIcon/8.svg" alt="icon" /></div>
                            <div className="text"><b><LanguageSet code="PRODUCT_ICON_DURATION" />:</b> {spect.duration.flex === false ? this.setDurationTime(spect.duration) : "Flex"}</div>
                        </div>
                        {this.getLanguage().length > 0 &&
                            <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/2.svg" alt="icon" /></div>
                                <div className="text">{this.getLanguage().join(", ")}</div>
                            </div>
                        }
                        {spect.evoucher ?
                            <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/9.svg" alt="icon" /></div>
                                <div className="text"><LanguageSet code="PRODUCT_ICON_MOBILE_VOUCHER" /></div>
                            </div>
                            : <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/15.svg" alt="icon" /></div>
                                <div className="text"><LanguageSet code="PRODUCT_ICON_PRINTED_VOUCHER" /></div>
                            </div>
                        }
                        {spect.instantConfirmation &&
                            <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/3.svg" alt="icon" /></div>
                                <div className="text"><LanguageSet code="PRODUCT_ICON_INSTANT_CONFIRMATION" /></div>
                            </div>
                        }
                        {spect.weelchair &&
                            <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/10.svg" alt="icon" /></div>
                                <div className="text"><LanguageSet code="PRODUCT_ICON_WEELCHAIR" /></div>
                            </div>
                        }
                        {spect.pickUpService &&
                            <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/4.svg" alt="icon" /></div>
                                <div className="text"><LanguageSet code="PRODUCT_ICON_PICKUP_SERVICE" /></div>
                            </div>
                        }
                        {spect.skipTheLine &&
                            <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/11.svg" alt="icon" /></div>
                                <div className="text"><LanguageSet code="PRODUCT_ICON_SKIP_THE_LINE" /></div>
                            </div>
                        }
                        {spect.privateTour &&
                            <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/5.svg" alt="icon" /></div>
                                <div className="text"><LanguageSet code="PRODUCT_ICON_PRIVATE_TOUR" /></div>
                            </div>
                        }
                        {spect.liveTourGuide &&
                            <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/12.svg" alt="icon" /></div>
                                <div className="text"><LanguageSet code="PRODUCT_ICON_LIVE_GUIDE" /></div>
                            </div>
                        }
                        {spect.privateTransfer &&
                            <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/6.svg" alt="icon" /></div>
                                <div className="text"><LanguageSet code="PRODUCT_ICON_PRIVATE_TRANSFER" /></div>
                            </div>
                        }
                        {spect.privateGroup &&
                            <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/13.svg" alt="icon" /></div>
                                <div className="text"><LanguageSet code="PRODUCT_ICON_PRIVATE_GROUP" /></div>
                            </div>
                        }
                        {spect.showId &&
                            <div className="iconSet">
                                <div className="icon"><img src="/Assets/img/productIcon/7.svg" alt="icon" /></div>
                                <div className="text"><LanguageSet code="PRODUCT_ICON_SHOW_ID" /></div>
                            </div>
                        }
                        <div className="iconSet">
                            <div className="icon"><img src="/Assets/img/productIcon/14.svg" alt="icon" /></div>
                            <div className="text"><LanguageSet code={this.level(spect.level)} /></div>
                        </div>
                    </div>
                    <div className="line"></div>


                </div>
                <div className="highlights spy" id="highlightsSpy">
                    <div className="title">
                        Highlights
                    </div>
                    <div className="text">
                        <ul>
                            {this.state.data.highlights.map((item, index) => (
                                <li key={index}>{item}</li>

                            ))}

                        </ul>
                    </div>
                    <div className="line"></div>
                </div>

            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(ProductSpec)
