import React, { Component, Suspense } from 'react'
import Categories from './Categories'

import TrustIcon from './TrustIcon'

const Groups = React.lazy(() => import('./Groups'))
const Slider = React.lazy(() => import('./Slider'))


export default class Home extends Component {


    render() {

        return (
            <div className="home">
                <Suspense fallback={<div>Yükleniyor...</div>}>
                    <Slider history={this.props.history} />
                </Suspense>
                <div className="homeContainer">
                    <Categories />
                    <Suspense fallback={<div>Yükleniyor...</div>}>
                        <Groups />
                    </Suspense>
                </div>
            </div>
        )
    }
}
