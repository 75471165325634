import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoadingSmall from '../../../../Helpers/LoadingSmall'
import { BookingSettings } from "../../Action/BookingAction"

import BookingCalendar from './BookingCalendar'


import '../../../../Assets/bookingNew.css'
import BookingPackage from './BookingPackage';
import BookingSesssionAndGuide from './BookingSesssionAndGuide';
import BookingPerson from './BookingPerson';

class BookingNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settingsLoading: true,
            settingsData: []
        }
    }


    componentDidMount() {

        this.props.BookingSettings(this.props.productId);

    }












    render() {

        const { engineData } = this.props;
        return (
            <div className='bookingNew'>
                <div className='bookingNewTitle'>
                    Booking Details
                </div>
                {engineData.settingsLoading ? <LoadingSmall /> :
                    <>
                        <div className='bookingEngineBody'>
                            {!engineData.loading &&
                                <BookingCalendar productId={this.props.productId} />
                            }
                            <BookingPackage productId={this.props.productId} />
                            <BookingSesssionAndGuide productId={this.props.productId} />
                            {engineData.priceList.length > 0 &&
                                <BookingPerson productId={this.props.productId} />
                            }
                        </div>
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    engineData: state.engineData

});

export default connect(mapStateToProps, { BookingSettings })(BookingNew)