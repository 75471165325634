import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from 'moment';
import axios from "axios";
import Booking from '../../Product/Component/Booking';
import CurrencyFormat from 'react-currency-format';
import Countdown, { zeroPad } from 'react-countdown';

import BookingEngine from '../../BookingEngine/Component/BookingEngine'


class Book extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            timeLeft: props.data.left,
            openEdit: false,
            datas: []
        }

        //this.countDown(props.data.left);
    }
    componentDidMount() {

    }

    componentWillUnmount() {

    }



    deleteBook = async (id) => {
        this.setState({

            loading: true
        })

        const data = {
            book: id
        }

        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/removeCart`, data)

            if (resp.status === 200) {
                const result = resp
                console.log(result);
                if (result.data.status === true) {
                    this.props.refresh()
                }

            }
        } catch (err) {
            console.error(err);
        }
    }

    openEdit = () => {
        this.getBookSpect()
    }


    getBookSpect = async () => {
        this.setState({
            sendRequestLoading: true
        });

        const data = {
            productId: this.props.data.productId
        }


        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getProductDetail`, data)

            if (resp.status === 200) {
                this.setState({
                    openEdit: true,
                    datas: resp.data,
                });

                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });


            }
        } catch (err) {
            console.error(err);
        }
    };

    closeModal = () => {
        this.setState({
            openEdit: false,

        })
    }

    complete = () => {
        this.setState({
            openEdit: false,

        }, () => {
            this.props.refresh();
        })
    }

    render() {
        const { data } = this.state

        return (
            <>

                <div className="book" key={this.props.key}>

                    <div className="bookContent">

                        <div className="photo">
                            {
                                data.product.img.filter((item) => item.type === "image").slice(0, 1).map((item, i, arra) => (
                                    <div className='imgs' style={{ backgroundImage: "url(" + process.env.REACT_APP_ASSETSNEW_ADDRESS + "products/720/" + item.name + ")" }}>
                                        <div className='countdown'>
                                            We’ll hold your spot for
                                            <br></br>

                                            <Countdown
                                                date={Date.now() + (data.left * 1000)}
                                                daysInHours={true}
                                                onComplete={() => this.deleteBook(this.props.data.cart.id)}
                                                zeroPadTime={2}
                                                renderer={props => (
                                                    <span>
                                                        {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
                                                    </span>
                                                )}
                                            /> Minutes
                                        </div>
                                    </div>
                                ))
                            }



                        </div>
                        <div className="content">
                            <div className="title">{data.product.productTitle}</div>
                            <div className="package">Package: {data.cart.packageName}</div>
                            {data.cart.guideLanguage !== null &&
                                <div className="package">Guide: {data.cart.guideLanguage}</div>
                            }
                            <div className="reservation">
                                <div className="icon"><img src="/Assets/img/icon/calendar.svg" alt="Search" /></div>
                                <div className="date"><b>{Moment(data.cart.reservationDate).format("DD.MM.YYYY")}</b></div>
                            </div>
                            <div className="startTime">
                                <div className="icon"><img src="/Assets/img/productIcon/8.svg" alt="Search" /></div>
                                <div className="time">Starting Time: <b>{data.cart.time}</b> Local Time</div>
                            </div>
                            <div className="person">
                                <div className='priceBreakDown'>Price Breakdown</div>
                                {data.pricesDetail.map((item, i) => (
                                    <div className="row">
                                        <div className="per">{item.blockTitle} x {item.pax}</div>
                                        <div className="price">
                                            <CurrencyFormat value={item.exchangeTotalSpoPrice}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                prefix={item.exchangeInfo.symbol + " "} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {data.options.length > 0 &&
                                <>

                                    <div className="person">

                                        {data.options.map((item, index) => (
                                            <div className="row">

                                                <div className="title">{item.title} x {item.pax} </div>
                                                <div className="price">
                                                    <CurrencyFormat value={item.exchangeTotalSpoPrice}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        prefix={item.exchangeInfo.symbol + " "} />
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </>
                            }
                        </div>
                        <div className="summary">
                            <div>
                                <div className='totalPriceTitle'>Total Price</div>
                                {data.priceTotal.grandTotalPrice !== data.priceTotal.grandTotalExchangePrice &&
                                    <>
                                        <div className="priceDisc">

                                            <CurrencyFormat value={data.priceTotal.grandTotalPrice}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                prefix={data.exchangeInfo.symbol + " "} />

                                        </div>
                                        <div className='saveTo'>

                                            SAVE UP TO <CurrencyFormat value={100 - (data.priceTotal.grandTotalExchangePrice / data.priceTotal.grandTotalPrice * 100)}
                                                displayType={'text'}
                                                decimalScale={0}
                                                fixedDecimalScale={false}
                                                thousandSeparator={true}
                                                suffix={"% "} />

                                        </div>
                                    </>
                                }

                                <div className="price">

                                    <CurrencyFormat value={data.priceTotal.grandTotalExchangePrice}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        prefix={data.exchangeInfo.symbol + " "} />
                                </div>
                                <div className="tax">All taxes and fees included</div>


                                <div className="removeCart" onClick={this.deleteBook.bind(this, this.props.data.cart.id)}>Remove Cart</div>
                                <div className="edit" onClick={this.openEdit.bind(this)}>Change</div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.openEdit &&
                    <>

                        <div className="modal">
                            <div className='modal-body'>

                                <div className="closeButton" onClick={this.closeModal.bind(this)}>
                                    <img src="/Assets/img/icon/cancel.svg" alt="icon" />
                                </div>

                                <div className='productPage'>

                                    <BookingEngine
                                        productData={this.state.data}
                                        productId={this.props.data.productId}
                                        history={this.props.history}
                                        edit={true}
                                        bookId={this.props.data.cart.id}
                                        complete={this.complete}
                                    />
                                    {/*
                                    <Booking
                                        data={this.state.datas}
                                        productId={this.props.data.productId}
                                        history={this.props.history}
                                        edit={true}
                                        bookId={this.props.data.cart.id}

                                        complete={this.complete}
                                        selectedDate={this.props.data.cart.reservationDate}
                                    />
                */}
                                </div>

                            </div>
                        </div>
                        <div className="modal-back-drop"></div>
                    </>
                }


            </>
        )
    }
}

const mapStateToProps = (state) => ({

    appData: state.appData,
});

export default connect(mapStateToProps)(Book)
