import React, { Component } from 'react'
import { connect } from 'react-redux'
import  BookingOptionItems  from "./BookingOptionItems"


export class BookingOptions extends Component {


  render() {

    const { engineData } = this.props;



    return (
      <>
        {engineData.options.length > 0 &&
          <div className='bookingAddOns'>
            <div className='title'>Booking Add ons</div>
            <div className='addOnsBody'>
              {engineData.options.map((items, i) => (

                <BookingOptionItems items={items} key={i}/>

              ))}



            </div>
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  appData: state.appData,
  engineData: state.engineData
})



export default connect(mapStateToProps)(BookingOptions)