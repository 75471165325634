import React, { Component } from 'react'
import { connect } from 'react-redux'
import MapMeetingPoint from '../../../Helpers/MapMeetingPoint'
export class MeetingPoint extends Component {

    constructor(props) {
        super(props)
        this.state= {
            map:[],
            point:"",
            productId:this.props.productId,

        }
    }

    onChange = (e) => {
        this.props.parentUpdate(e,"select",this.props.id,this.props.productId)
    }

    mapSet =(e)=> {
        this.props.parentUpdate(e,"map",this.props.id,this.props.productId)
    }

   
    render() {



        const { meetingPoint } = this.props.data
        return (
            <>
                <div className="pickupPointTitle">Pick-up point</div>
                {
                    meetingPoint.hotelCheck === 1 &&
                    <>

                        <MapMeetingPoint id={this.props.id} data={meetingPoint.selectedArea} setMapData={this.mapSet}  />
                       
                            {meetingPoint.pickupPointData.map((item, index) => (
                                <div className="pickupPoinParent">
                                    <div className="radio-button">
                                        <label className="radio-button__label-wrapper" forhtml={item.id}>
                                            <h3 className="radio-button__label-title">
                                                {item.pickupPointDetail}
                                            </h3>
                                            <span className="radio-button__label-subtext">
                                                {item.address}
                                            </span>
                                            <input type="radio" name="hotelChecked" id={item.id} onChange={this.onChange.bind(this,item.id)} value="2" className="radio-button__input" />
                                            <div className="radio-button__custom-indicator"></div>
                                        </label>
                                    </div>
                                </div>
                            ))}

                       
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({

    appData: state.appData,
});

export default connect(mapStateToProps)(MeetingPoint)