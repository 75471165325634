import React, { Component } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore,{ Navigation,Thumbs} from 'swiper';
import 'swiper/swiper.min.css'
import { connect } from 'react-redux'
import 'swiper/components/navigation/navigation.scss';
import Fancybox from '../../../Helpers/fancybox';
import { Vimeo } from 'vimeo';
SwiperCore.use([Navigation,Thumbs]);
class Gallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            imageInfos: props.data.productImg,
            swipers:""
         
        }



    }

    nextSlide = (e) => {

    }

    componentDidMount() {
        this.state.data.productImg.map((item, index) => {
            if (item.type === "video") {
                this.getListVideoPictures(item.name, index);
            }
        })
    }

    getListVideoPictures = (uri, i) => {


        let client = new Vimeo("e4e3cc0f241568e0a77af18e3e24e02e05c28fe8",
            "yp4EGWuKFX61p0EJt8sgC9Ua5TkD9S5+GWqxAcGmFDxtqPxLZGnAvXU1N13u8CIDbcKUOalUbKKRAprhh7YeYS9Fp4oPC5MN5A+mCx078OLwRrZ5aUjGWwEZWnFLh6az",
            "ecca4d5e02577016fbe495501d49834d");
        let _this = this;
        client.request({
            path: uri,
            query: {
                fields: 'uri,name,description,duration,created_time,modified_time,pictures'
            }
        }, function (error, body, status_code, headers) {

            _this.setState({
                imageInfos: _this.state.imageInfos.map((
                    obj, index) => (index === i ? Object.assign(obj, {
                        videoImg: body.pictures.base_link,
                        videoId: uri.split("/")
                    }) : obj)
                )
            });
        });
    }
    prevNavRef = React.createRef();
    nextNavRef = React.createRef();


    setThumSwiper = (e)=> {
        this.setState({
            swipers:e
        })
    }

    render() {

        return (
            <>
                <div className="productGallery">
                    <div className='thumb'>
                        <Swiper
                            modules={[Thumbs]}
                            spaceBetween={5}
                            slidesPerView={4}
                            direction={"vertical"}
                            loop={false}
                            lazy={false}
                            onSwiper={(e)=>{
                                this.setThumSwiper(e)
                            }}
                            
                        >
                            {this.state.imageInfos.map((item, index) => (

                                <div key={index}>
                                    {item.type === "image" ?
                                        <SwiperSlide key={index}>
                                            <div className="sliderItem" style={{ backgroundImage: "url(" + process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/360/" + item.name + ")" }} 
                                                data-src={process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/360/" + item.name}>
                                            </div>
                                        </SwiperSlide>
                                        :
                                        <SwiperSlide key={index}>
                                            <div className="sliderItem" style={{ backgroundImage: "url(" + item.videoImg + ")" }}
                                                data-src={"https://vimeo.com/" + item.videoId[2]}>
                                            </div>
                                        </SwiperSlide>
                                    }
                                </div>
                            ))}
                        </Swiper>
                    </div>
                    <div className='gallerys'>
                        <Fancybox >

                            <Swiper
                                modules={[Navigation,Thumbs]}
                                spaceBetween={7}
                                slidesPerView={1}
                                slidesPerGroup={1}
                                initialSlide={1}
                                thumbs={{ swiper: this.state.swipers }}
                                
                                navigation={{
                                    prevEl: this.prevNavRef.current,
                                    nextEl: this.nextNavRef.current,
                                }}
                                onInit={(swiper) => {
                                    swiper.params.navigation.prevEl = this.prevNavRef.current;
                                    swiper.params.navigation.nextEl = this.nextNavRef.current;
                                //    swiper.navigation.init(); // throws error here, navigation is undefined
                                  //  swiper.navigation.update();


                                }}
                                loop={false}
                                lazy={false}
                            >
                                {this.state.imageInfos.map((item, index) => (

                                    <div key={index}>
                                        {item.type === "image" ?
                                            <SwiperSlide key={index}>

                                                <div className="sliderItem" style={{ backgroundImage: "url(" + process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/1200/" + item.name + ")" }} data-fancybox="gallery"
                                                    data-src={process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/1200/" + item.name}>

                                                </div>

                                            </SwiperSlide>
                                            :
                                            <SwiperSlide key={index}>
                                                <div className="sliderItem" style={{ backgroundImage: "url(" + item.videoImg + ")" }} data-fancybox="gallery"
                                                    data-src={"https://vimeo.com/" + item.videoId[2]}>
                                                </div>
                                            </SwiperSlide>
                                        }
                                    </div>
                                ))}
                                <div ref={this.prevNavRef} className="swiper-button-prev-tour"></div>
                                <div ref={this.nextNavRef} className="swiper-button-next-tour"></div>
                            </Swiper>
                        </Fancybox>
                    </div>

                </div >


            </>
        )
    }
}
const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(Gallery)