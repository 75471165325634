import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { combineReducers } from 'redux'

import AppReducer from "./App/Reducer/AppReducer"
import BookingReducer from './Product/Reducer/BookingReducer'

import BookingEngineReducer from './BookingEngine/Reducer/BookingEngineReducer'


const rootReducer = combineReducers({
    appData: AppReducer,
    bookingsData: BookingReducer,
    engineData: BookingEngineReducer,
   


});

const initalState = {}

const middleware = [thunk]
const store = createStore(rootReducer, initalState, composeWithDevTools(applyMiddleware(...middleware)))
export default store;