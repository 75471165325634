import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

export default function GetAutoComplateGoogle(props) {
    const [value, setValue] = useState(null);

    useEffect(() => {

        setValue(props.oldValue);
    }, [props.oldValue])

    const handlePredictionSelection = (e) => {
        setValue(e)
        
        geocodeByPlaceId(e.value.place_id)
            .then(results => props.selectedAddress(results,e))
            .catch(error => console.error(error));
            
            

    }

    
    return (
        <div>
            <GooglePlacesAutocomplete
            apiKey="AIzaSyAnrJeRkJego6p9bhXDJ6Dm1hpCuDvAd9k"
                selectProps={{
                    value,
                    onChange: handlePredictionSelection,
                    styles: {
                        control: (provided) => ({
                          ...provided,
                          height: 45,
                        }),
                        
                      }
                }}
            />
        </div>
    );
}