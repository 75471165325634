import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/flatpickr.min.css'
import { BookingEngineCalendarGet, BookingEngineSetDate } from "../Action/BookingEngineAction"
export class BookingCalendar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstLoad: true

    }
  }
  calendar = React.createRef();
  getCalendar = (data) => {
    this.props.BookingEngineCalendarGet(data)
  };


  componentDidMount() {

    const { engineData } = this.props;

    const data = {
      packageId: this.getSelectedPackage().id,
      productId: engineData.productId,
      startDate: moment(this.getSelectedPackage().startDate) < moment() ? moment().startOf('month').format("YYYY-MM-DD") : this.getSelectedPackage().startDate,
      endDate: moment(this.getSelectedPackage().startDate).endOf('month').format("YYYY-MM-DD")
    }
    this.props.BookingEngineCalendarGet(data);
  }



  getSelectedPackage = () => {
    const { engineData } = this.props;
    return engineData.packagesData.filter(items => items.id === engineData.selectedPackage)[0];
  }


  getFirstValue = (date) => {

  }



  render() {

    const { engineData } = this.props;

    
    return (
      <div className='bookingCalendar'>
        <div className='calendarTitle'>Please select a tour date</div>

        {(engineData.packagesLoading || engineData.calendarLoading) ?

          <input className="bookingDateInput form-control input active" placeholder="Loading..." disabled={true} />
          :

          <Flatpickr
            ref={calendar => { this.calendar = calendar; }}
            placeholder="Check availability"
            className={"bookingDateInput"}
            value={engineData.selectedDate === "" ? "" : engineData.selectedDate}
            options={{
              minDate: moment(this.getSelectedPackage().startDate) < moment() ? moment().format("YYYY-MM-DD") : this.getSelectedPackage().startDate,
              altInput: true,
              altFormat: "F j, Y",
              dateFormat: "Y-m-d",
              maxDate: this.getSelectedPackage().maxDate,
              enable: this.getSelectedPackage().availabilityDate,
              locale: {
                firstDayOfWeek: 1
              }
            }}
            onReady={() => {
              const { engineData } = this.props;

              let cutofDate = engineData.settingsData.operationDate.date;


              let calcCutOffDate = engineData.calendarList.filter(item => item.status && !item.stop && item.quota.length > 0 && moment(item.date).diff(cutofDate) >= 0)


              if (calcCutOffDate.length > 0) {


                const datas = {
                  dates: calcCutOffDate[0].date,
                  packageId: this.getSelectedPackage().id,
                  productId: engineData.productId

                }



                this.props.BookingEngineSetDate(datas);
              }

            }}
            onChange={(selectedDates, dateStr, instance) => {

              this.setState({
                firstLoad: false
              })

              const datas = {
                dates: dateStr,
                packageId: this.getSelectedPackage().id,
                productId: engineData.productId

              }

              this.props.BookingEngineSetDate(datas);

              //this.props.BookingEngineSessionAndGuides(datas)

            }}

            onDayCreate={(dObj, dStr, fp, dayElem) => {


              if (this.props.engineData.calendarLoading) {
                dayElem.innerHTML += "<div class='lds-ellipsis'><div></div><div></div><div></div><div></div></div>";

                dayElem.className += ' loadingCalendarDay';

              } else {

                //dayElem.className += ' loading';

                let currentDate = moment(dayElem.dateObj).format('YYYY-MM-DD');
                let cutofDate = engineData.settingsData.operationDate.date;
                engineData.calendarList.map((item, i) => {

                  if (item.date === currentDate) {
                    if (item.status && !item.stop && item.quota.length > 0 && moment(item.date).diff(cutofDate) >= 0) {
                      dayElem.innerHTML += "<span class='event'>" + item.exchange.toSymbol + " " + item.price + "</span>";

                      if (item.spo) {
                        dayElem.className += ' discountColor';
                      }
                      if (moment(currentDate) >= moment()) {
                        if (item.guide.length > 0) {

                          let guideParent = "<div class='guideCalendar'>";
                          dayElem.innerHTML += "";
                          item.guide.map(g => {
                            guideParent += "<div class='guideColor guide" + g.id + "'></div>";
                          })

                          guideParent += "</div>";
                          dayElem.innerHTML += guideParent
                        }
                      }
                    } else {
                      dayElem.className = ' flatpickr-day flatpickr-disabled '
                      if (moment(currentDate) >= moment()) {
                        dayElem.innerHTML += "<div class='disabledShash'></div>"
                      }
                    }
                  }
                });

                if (!this.state.calendarLoading) {
                  dayElem.querySelectorAll(".lds-ellipsis").forEach(el => el.remove());
                  dayElem.classList.remove('loadingCalendarDay');
                }
              }

            }}
            onMonthChange={(value, dateStr, fp) => {
              this.setState({
                firstLoad: false
              })
              fp.days.className += ' discountColor';
              let dates = new Date(fp.currentYear, fp.currentMonth);



              const data = {
                packageId: this.getSelectedPackage().id,
                productId: engineData.productId,
                startDate: moment(dates).startOf('month').format("YYYY-MM-DD"),
                endDate: moment(dates).endOf('month').format("YYYY-MM-DD")
              }

              this.getCalendar(data);

            }}
          />
        }
      </div>

    )
  }
}


const mapStateToProps = (state) => ({
  appData: state.appData,
  engineData: state.engineData

});

export default connect(mapStateToProps, {
  BookingEngineCalendarGet,
  BookingEngineSetDate
})(BookingCalendar)
