import React, { Component } from 'react'
import ProductSlider from './ProductSlider';
import axios from "axios";
import { connect } from 'react-redux'



import ProductSpec from './ProductSpec';
import ProductGallery from './ProductGallery';
import Booking from './Booking';
import Itinerary from './Itinerary';
import Bullet from './Bullet';
import IncludeAndNotInclude from './IncludeAndNotInclude';
import ImportantToknow from './ImportantToknow';

import Covid from './Covid';
import CancelPolicy from './CancelPolicy';
import Routeing from './Routeing';
import { YouMightAlsoLike } from './YouMightAlsoLike';
import BookingNew from './BookingNew/BookingNew';
import BookingEngine from '../../BookingEngine/Component/BookingEngine'



class Product extends Component {

    constructor(props) {
        super(props);

   

        this.state = {
            sendRequestLoading: true,
            slug: props.match.params.code,
            data: [],
            pageSticky: false,
            productId: ""
        }
    }

    componentDidMount() {
        this.sendPostRequest();
        window.addEventListener('scroll', this.listenToScroll);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }


    sendPostRequest = async () => {
        this.setState({
            sendRequestLoading: true
        });

        const data = {
            slug: this.state.slug
        }


        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getProductDetail`, data)

            if (resp.status === 200) {
                this.setState({
                    data: resp.data,
                    sendRequestLoading: false,
                    productId: resp.data.productId

                })


            }
        } catch (err) {
            console.error(err);
        }
    };


    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {


        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        if (winScroll > 140) {
            this.setState({
                pageSticky: true
            });
        } else {
            this.setState({
                pageSticky: false
            });
        }
    }


    render() {


        return (
            <div className={this.state.pageSticky ? "productPage sticky" : "productPage"}>
                {!this.state.sendRequestLoading &&
                    <>
                        <Routeing />
                        <ProductSlider data={this.state.data} productId={this.state.productId} />
                        <div className="productContent">
                            <div className="left">
                                <ProductSpec data={this.state.data} />
                                <ProductGallery data={this.state.data} />


                            </div>
                            <div className="right bulletRight">
                                <Bullet data={this.state.data} productId={this.state.productId} />
                            </div>

                        </div>
                        <div className="productContent">
                            {
                                <BookingEngine productData={this.state.data} productId={this.state.productId} history={this.props.history}/>

                                /*
                                <Booking data={this.state.data} productId={this.state.productId} history={this.props.history} />
                                
                               <BookingNew data={this.state.data} productId={this.state.productId} history={this.props.history}/>
                               */
                            }
                        </div>
                        <Itinerary data={this.state.data} />

                        <div className="productContent">
                            <div className="left width100">
                                {/*<IncludeAndNotInclude data={this.state.data} />*/}
                                <ImportantToknow data={this.state.data} />
                                <div className="banner">
                                    <img src="/Assets/img/promobanner.webp" alt="promoBanner" />
                                </div>
                                <CancelPolicy data={this.state.data} />
                                <Covid data={this.state.data} />
                            </div>
                        </div>
                        <YouMightAlsoLike data={this.state.data} />
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(Product)
