import React, { Component } from 'react'
import { connect } from 'react-redux'



export class BookingPerson extends Component {

    constructor(props) {
        super(props)

        let bookingSpect = this.props.spect

        this.state = {
            maxReservation: bookingSpect.maxReservation,
            adultMandatory: bookingSpect.adultMandatory,
            adultNum: 0,
            childNum: 0,
            infantNum: 0,
            teenagerNum: 0,
            seniorNum: 0,
            totalNum: 0,
            groupPriceNum: 0,
            priceType: bookingSpect.priceType,
            bookingSpect: this.props.spect,
            personText: "",
            errorTextShow: false,
            errorText: ""
        }

    }

    setBookingPerson = () => {
        const bookingSpect = this.props.spect;

        if (bookingSpect.age.adult.status  && this.state.adultNum < bookingSpect.age.adult.min) {
            this.setState({
                errorText: bookingSpect.age.adult.title + " Mandatory",
                errorTextShow: true
            }, () => {
                setTimeout(
                    () => this.setState({ errorTextShow: false }),
                    2000
                );
            })

        } else if (bookingSpect.age.child.status  && this.state.childNum < bookingSpect.age.child.min) {
            this.setState({
                errorText: bookingSpect.age.child.title + " Mandatory",
                errorTextShow: true
            }, () => {
                setTimeout(
                    () => this.setState({ errorTextShow: false }),
                    2000
                );
            })
        } else if (bookingSpect.age.infant.status && this.state.infantNum < bookingSpect.age.infant.min) {
            this.setState({
                errorText: bookingSpect.age.infant.title + " Mandatory",
                errorTextShow: true
            }, () => {
                setTimeout(
                    () => this.setState({ errorTextShow: false }),
                    2000
                );
            })
        } else if (bookingSpect.age.teenager.status && this.state.teenagerNum < bookingSpect.age.teenager.min) {
            this.setState({
                errorText: bookingSpect.age.teenager.title + " Mandatory",
                errorTextShow: true
            }, () => {
                setTimeout(
                    () => this.setState({ errorTextShow: false }),
                    2000
                );
            })
        } else if (bookingSpect.age.senior.status && this.state.seniorNum < bookingSpect.age.senior.min) {
            this.setState({
                errorText: bookingSpect.age.senior.title + " Mandatory",
                errorTextShow: true
            }, () => {
                setTimeout(
                    () => this.setState({ errorTextShow: false }),
                    2000
                );
            })
        } else {
            this.personString();
            this.props.close();
        }
    }
    componentDidMount() {
        this.personString();
    }



    personString = () => {

        let e = this.state;

        if (this.state.priceType === 2) {

            this.setState({
                personText: e.groupPriceNum + " Travelers"
            }, () => {
                this.props.getBookingPerson(this.state);
            });


        } else if (this.state.priceType === 1) {
            let adultString = "Quantity";

            if (e.adultNum !== 0) {
                adultString = e.adultNum + "  " + this.props.spect.age.adult.title + " "
            }

            let childString = "";
            if (e.childNum !== 0) {
                childString = ", " + e.childNum + "  " + this.props.spect.age.child.title + " "
            }

            let infantString = "";
            if (e.infantNum !== 0) {
                infantString = ", " + e.infantNum + "  " + this.props.spect.age.infant.title + " "
            }

            let teenagerString = "";
            if (e.teenagerNum !== 0) {
                teenagerString = ", " + e.teenagerNum + "  " + this.props.spect.age.teenager.title + " "
            }

            let seniorString = "";
            if (e.seniorNum !== 0) {
                seniorString = ", " + e.seniorNum + "  " + this.props.spect.age.senior.title + " "
            }

            this.setState({
                personText: adultString + childString + infantString + teenagerString + seniorString,

            }, () => {
                this.props.getBookingPerson(this.state);
            });
        }

    }


    plus = (e) => {
        const total = this.state.adultNum + this.state.childNum + this.state.infantNum + this.state.teenagerNum + this.state.seniorNum

        const elems = e.currentTarget.dataset.vars
        const max = e.currentTarget.dataset.max;
        const min = e.currentTarget.dataset.min;


        if (this.state.maxReservation > total && this.state[elems] < max) {


            if (this.state[elems] === 0 && parseInt(min) > 0) {

                this.setState({
                    [elems]: parseInt(min),

                }, () => {
                    this.setState({
                        totalNum: this.state.adultNum + this.state.childNum + this.state.infantNum + this.state.teenagerNum + this.state.seniorNum,
                    });
                });
            } else {
                this.setState({
                    [elems]: this.state[elems] + 1,

                }, () => {
                    this.setState({
                        totalNum: this.state.adultNum + this.state.childNum + this.state.infantNum + this.state.teenagerNum + this.state.seniorNum,
                    });
                });
            }
        }



    }

    minus = (e) => {

        const elems = e.currentTarget.dataset.vars
        const min = e.currentTarget.dataset.min;


        if (this.state[elems] > min) {
            this.setState({
                [elems]: this.state[elems] - 1,

            }, () => {
                this.setState({
                    totalNum: this.state.adultNum + this.state.childNum + this.state.infantNum + this.state.teenagerNum + this.state.seniorNum,
                });
            });
        } else {
            this.setState({
                [elems]: 0,

            }, () => {
                this.setState({
                    totalNum: this.state.adultNum + this.state.childNum + this.state.infantNum + this.state.teenagerNum + this.state.seniorNum,
                });
            });
        }

        /*

        if (this.state.adultMandatory && e.currentTarget.dataset.vars === "adultNum") {
            if (this.state[e.currentTarget.dataset.vars] > 1) {
                this.setState({
                    [e.currentTarget.dataset.vars]: this.state[e.currentTarget.dataset.vars] - 1,

                }, () => {
                    this.setState({
                        totalNum: this.state.adultNum + this.state.childNum + this.state.infantNum + this.state.teenagerNum + this.state.seniorNum,
                    });
                });
            }
        } else if (this.state[e.currentTarget.dataset.vars] >= 1) {
            this.setState({
                [e.currentTarget.dataset.vars]: this.state[e.currentTarget.dataset.vars] - 1,

            }, () => {
                this.setState({
                    totalNum: this.state.adultNum + this.state.childNum + this.state.infantNum + this.state.teenagerNum + this.state.seniorNum,
                });
            });
        }
        */
    }


    plusGroup = (e) => {
        const total = this.state.groupPriceNum

        const elems = e.currentTarget.dataset.vars
        if (this.state.maxReservation > total) {
            this.setState({
                [elems]: this.state[elems] + 1,

            }, () => {
                this.setState({
                    totalNum: this.state.groupPriceNum,
                });
            });
        }
    }


    minusGroup = (e) => {

        if (this.state.adultMandatory && e.currentTarget.dataset.vars === "groupPriceNum") {
            if (this.state[e.currentTarget.dataset.vars] > 1) {
                this.setState({
                    [e.currentTarget.dataset.vars]: this.state[e.currentTarget.dataset.vars] - 1,

                }, () => {
                    this.setState({
                        totalNum: this.state.groupPriceNum,
                    });
                });
            }
        } else if (this.state[e.currentTarget.dataset.vars] >= 1) {
            this.setState({
                [e.currentTarget.dataset.vars]: this.state[e.currentTarget.dataset.vars] - 1,

            }, () => {
                this.setState({
                    totalNum: this.state.groupPriceNum,
                });
            });
        }
    }

    render() {



        const bookingSpect = this.props.spect

        return (
            <>
                <div className="totalPerson">You can select up to <b>{bookingSpect.maxReservation}</b> in total.</div>
                {bookingSpect.priceType === 1 ?
                    <>
                        <div className="personCalc">
                            <div className="personText">
                                <div className="personTitle"><b>{bookingSpect.age.adult.title}</b> {bookingSpect.ageTitleStatus && <>({bookingSpect.ageTitle} {bookingSpect.age.adult.start}-{bookingSpect.age.adult.end})</>}</div>
                                <div className="personSpect">{bookingSpect.quantityTitle} range: {bookingSpect.age.adult.min}-{bookingSpect.age.adult.max}</div>
                            </div>
                            <div className="personNum">
                                <div className="minus" data-min={bookingSpect.age.adult.min} data-vars="adultNum" onClick={this.minus.bind(this)}>
                                    <img src=
                                        {
                                            (this.state.adultNum === 0) ?
                                                "/Assets/img/icon/minusDisable.svg"
                                                :
                                                "/Assets/img/icon/minus.svg"
                                        }
                                        alt="Search" /></div>
                                <div className="num">{this.state.adultNum}</div>
                                <div className="plus" data-min={bookingSpect.age.adult.min} data-max={bookingSpect.age.adult.max} data-vars="adultNum" onClick={this.plus.bind(this)}>

                                    <img src={
                                        (this.state.totalNum === this.state.maxReservation || this.state.adultNum === bookingSpect.age.adult.max) ?
                                            "/Assets/img/icon/plusDisable.svg"
                                            :
                                            "/Assets/img/icon/plus.svg"
                                    }
                                        alt="Search" />

                                </div>
                            </div>
                        </div>
                        {bookingSpect.age.child.status &&
                            <div className="personCalc">
                                <div className="personText">
                                    <div className="personTitle"><b>{bookingSpect.age.child.title}</b> {bookingSpect.ageTitleStatus && <>({bookingSpect.ageTitle} {bookingSpect.age.child.start}-{bookingSpect.age.child.end})</>}</div>
                                    <div className="personSpect">{bookingSpect.quantityTitle} range: {bookingSpect.age.child.min}-{bookingSpect.age.child.max}</div>
                                </div>
                                <div className="personNum">
                                    <div className="minus" data-min={bookingSpect.age.child.min} data-vars="childNum" onClick={this.minus.bind(this)}>
                                        <img src={
                                            (this.state.childNum === 0) ?
                                                "/Assets/img/icon/minusDisable.svg"
                                                :
                                                "/Assets/img/icon/minus.svg"
                                        }
                                            alt="Search" />
                                    </div>
                                    <div className="num">{this.state.childNum}</div>
                                    <div className="plus" data-min={bookingSpect.age.child.min} data-max={bookingSpect.age.child.max} data-vars="childNum" onClick={this.plus.bind(this)}>
                                        <img src={
                                            (this.state.totalNum === this.state.maxReservation || this.state.childNum === bookingSpect.age.child.max) ?
                                                "/Assets/img/icon/plusDisable.svg"
                                                :
                                                "/Assets/img/icon/plus.svg"
                                        }
                                            alt="Search" />
                                    </div>
                                </div>
                            </div>
                        }
                        {bookingSpect.age.infant.status &&
                            <div className="personCalc">
                                <div className="personText">
                                    <div className="personTitle"><b>{bookingSpect.age.infant.title}</b>  {bookingSpect.ageTitleStatus && <>({bookingSpect.ageTitle} {bookingSpect.age.infant.start}-{bookingSpect.age.infant.end})</>}</div>
                                    <div className="personSpect">{bookingSpect.quantityTitle} range: {bookingSpect.age.infant.min}-{bookingSpect.age.infant.max}</div>
                                </div>
                                <div className="personNum">
                                    <div className="minus" data-min={bookingSpect.age.infant.min} data-vars="infantNum" onClick={this.minus.bind(this)}>
                                        <img src={
                                            this.state.infantNum === 0 ?
                                                "/Assets/img/icon/minusDisable.svg"
                                                :
                                                "/Assets/img/icon/minus.svg"
                                        }
                                            alt="Search" /></div>
                                    <div className="num">{this.state.infantNum}</div>
                                    <div className="plus" data-min={bookingSpect.age.infant.min} data-max={bookingSpect.age.infant.max} data-vars="infantNum" onClick={this.plus.bind(this)}>
                                        <img src={
                                            (this.state.totalNum === this.state.maxReservation || this.state.infantNum === bookingSpect.age.infant.max) ?
                                                "/Assets/img/icon/plusDisable.svg"
                                                :
                                                "/Assets/img/icon/plus.svg"
                                        }
                                            alt="Search" />
                                    </div>
                                </div>
                            </div>
                        }
                        {bookingSpect.age.teenager.status &&
                            <div className="personCalc">
                                <div className="personText">
                                    <div className="personTitle"><b>{bookingSpect.age.teenager.title}</b>  {bookingSpect.ageTitleStatus && <>({bookingSpect.ageTitle} {bookingSpect.age.teenager.start}-{bookingSpect.age.teenager.end})</>}</div>
                                    <div className="personSpect">{bookingSpect.quantityTitle} range: {bookingSpect.age.teenager.min}-{bookingSpect.age.teenager.max}</div>
                                </div>
                                <div className="personNum">
                                    <div className="minus" data-min={bookingSpect.age.teenager.min} data-vars="teenagerNum" onClick={this.minus.bind(this)}>
                                        <img src={
                                            this.state.teenagerNum === 0 ?
                                                "/Assets/img/icon/minusDisable.svg"
                                                :
                                                "/Assets/img/icon/minus.svg"
                                        }
                                            alt="Search" />
                                    </div>
                                    <div className="num">{this.state.teenagerNum}</div>
                                    <div className="plus" data-min={bookingSpect.age.teenager.min} data-max={bookingSpect.age.teenager.max} data-vars="teenagerNum" onClick={this.plus.bind(this)}>
                                        <img src={
                                            (this.state.totalNum === this.state.maxReservation || this.state.teenagerNum === bookingSpect.age.teenager.max) ?
                                                "/Assets/img/icon/plusDisable.svg"
                                                :
                                                "/Assets/img/icon/plus.svg"
                                        }
                                            alt="Search" />
                                    </div>
                                </div>
                            </div>
                        }
                        {bookingSpect.age.senior.status &&
                            <div className="personCalc">
                                <div className="personText">
                                    <div className="personTitle"><b>{bookingSpect.age.senior.title}</b> {bookingSpect.ageTitleStatus && <>({bookingSpect.ageTitle} {bookingSpect.age.senior.start}-{bookingSpect.age.senior.end})</>}</div>
                                    <div className="personSpect">{bookingSpect.quantityTitle} range: {bookingSpect.age.senior.min}-{bookingSpect.age.senior.max}</div>
                                </div>
                                <div className="personNum">
                                    <div className="minus" data-min={bookingSpect.age.senior.min} data-vars="seniorNum" onClick={this.minus.bind(this)}>
                                        <img src={
                                            this.state.seniorNum === 0 ?
                                                "/Assets/img/icon/minusDisable.svg"
                                                :
                                                "/Assets/img/icon/minus.svg"
                                        }
                                            alt="Search" />
                                    </div>
                                    <div className="num">{this.state.seniorNum}</div>
                                    <div className="plus" data-min={bookingSpect.age.senior.min} data-max={bookingSpect.age.senior.max} data-vars="seniorNum" onClick={this.plus.bind(this)}>
                                        <img src={
                                            (this.state.totalNum === this.state.maxReservation || this.state.seniorNum === bookingSpect.age.senior.max) ?
                                                "/Assets/img/icon/plusDisable.svg"
                                                :
                                                "/Assets/img/icon/plus.svg"
                                        }
                                            alt="Search" />
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                    :

                    <div className="personCalc">
                        <div className="personText">
                            <div className="personTitle"><b>Traveler </b> (age {bookingSpect.age.adult.start}-{bookingSpect.age.adult.end})</div>
                            <div className="personSpect">{bookingSpect.adultMandatory === 1 && "Minimum: 1"} Maximum: {bookingSpect.maxReservation}</div>
                        </div>
                        <div className="personNum">
                            <div className="minus" data-vars="groupPriceNum" onClick={this.minusGroup.bind(this)}>
                                <img src=
                                    {
                                        this.state.groupPriceNum === 0 ?
                                            "/Assets/img/icon/minusDisable.svg"
                                            :
                                            "/Assets/img/icon/minus.svg"
                                    }
                                    alt="Search" /></div>
                            <div className="num">{this.state.groupPriceNum}</div>
                            <div className="plus" data-vars="groupPriceNum" onClick={this.plusGroup.bind(this)}>

                                <img src={
                                    this.state.totalNum === this.state.maxReservation ?
                                        "/Assets/img/icon/plusDisable.svg"
                                        :
                                        "/Assets/img/icon/plus.svg"
                                }
                                    alt="Search" />

                            </div>
                        </div>
                    </div>
                }
                <div className="setPerson" onClick={this.setBookingPerson.bind(this)}>Apply</div>
                <div className='minErrors' style={{
                    opacity: !this.state.errorTextShow ? "0" : "1",
                    visibility: !this.state.errorTextShow ? "hidden" : "visible",
                }}>
                    {this.state.errorText}
                </div>

            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(BookingPerson)
