import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from "axios";
import "../../../Assets/search.css"
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/flatpickr.min.css'
import Moment from 'moment';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { DropdownMenu } from '../../../Helpers/DropDownMenu';
import LoadingSmall from '../../../Helpers/LoadingSmall';
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import debounce from "lodash/debounce"
class Search extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sendRequestLoading: true,
            q: props.match.params.q,
            firstLoad: true,
            data: [],
            counts: "",
            filters: [],
            products: [],
            sortType: "featured",
            sortTitle: "Featured",
            startDate: Moment(new Date().fp_incr(1)).format('YYYY-MM-DD'),
            endDate: Moment(new Date().fp_incr(1)).format('YYYY-MM-DD'),
            priceRange: [0, 500],
            categories: [],
            selectedCategories: [],
            selectedGuide: [],
            selectedDestination: [],
            durationMin3H: false,
            duration3H5H: false,
            duration5H7H: false,
            duration7H24H: false,
            duration1D3D: false,
            durationMin3D: false,
            disconts: false,
            freeCancel: false,
            privateTour: false,
            skiptoline: false,
            weelchair: false,
            pickUpService: false,
            priceRangeCurr: [],

        }

        this.servicesValue = debounce(this.servicesValue, 300)

    }

    servicesValue = () => {
        this.sendPostRequest()
    }

    componentDidMount() {
        this.sendPostRequest();


    }
    onSliderChange = (value) => {

        this.setState({
            priceRange: value,
        }, () => {
            this.servicesValue();
        });
    };
    sendPostRequest = async (loadStatus) => {
        if (this.state.firstLoad) {
            this.setState({
                sendRequestLoading: true,
                bodyLoading: true
            });
        } else {
            this.setState({

                bodyLoading: true
            });
        }

        const data = {
            q: this.state.q,
            sortType: this.state.sortType,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            selectedCategories: this.state.selectedCategories,
            selectedGuide: this.state.selectedGuide,
            selectedDestination: this.state.selectedDestination,
            priceRange: this.state.priceRange,
            durationMin3H: this.state.durationMin3H,
            duration3H5H: this.state.duration3H5H,
            duration5H7H: this.state.duration5H7H,
            duration7H24H: this.state.duration7H24H,
            duration1D3D: this.state.duration1D3D,
            durationMin3D: this.state.durationMin3D,
            disconts: this.state.disconts,
            freeCancel: this.state.freeCancel,
            privateTour: this.state.privateTour,
            skiptoline: this.state.skiptoline,
            weelchair: this.state.weelchair,
            pickUpService: this.state.pickUpService,

        }


        try {

            const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getSearchServices`, data)

            if (resp.status === 200) {

                if (this.state.firstLoad) {
                    this.setState({
                        counts: resp.data.count,
                        filters: resp.data.filter,
                        products: resp.data.product,
                        sendRequestLoading: false,
                        priceRangeCurr: resp.data.priceRangeCurr,
                        firstLoad: false,
                        bodyLoading: false
                    });

                    if (resp.data.count > 0) {

                        let lastSearch = JSON.parse(localStorage.getItem("lastSearch")) || [];
                        let check = true;
                        lastSearch.map((item) => {

                            if (item.q === this.state.q) {
                                check = false;
                            }

                        });

                        if (check === true) {
                            lastSearch.push({ q: this.state.q });
                            localStorage.setItem("lastSearch", JSON.stringify(lastSearch))
                        }

                        
                    }
                } else {
                    this.setState({
                        counts: resp.data.count,
                        products: resp.data.product,
                        bodyLoading: false,

                    });
                }

                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });


            }
        } catch (err) {
            console.error(err);
        }
    };



    setSort = (type, title) => {
  
        this.setState({
            sortType: type,
            sortTitle: title
        }, () => {
            this.sendPostRequest();
        })
    }

    setDurationTime = (e) => {
        let durationString = "";
        if (e.day !== 0) {
            durationString = e.day + " Day ";
        }

        if (e.hours !== 0) {
            durationString = durationString + e.hours + " Hour ";
        }

        if (e.minutes !== 0) {
            durationString = durationString + e.minutes + " Min ";
        }

        return durationString;

    }

    setCategories = (id) => {

        if (this.state.selectedCategories.includes(id)) {
            this.setState({
                selectedCategories: this.state.selectedCategories.filter(item => item !== id)
            }, () => {
                this.sendPostRequest();
            })

        } else {

            this.setState({
                selectedCategories: [...this.state.selectedCategories, id]
            }, () => {
                this.sendPostRequest();
            })

        }
    }

    setDurationTimes = (e) => {
        this.setState({
            [e.target.name]: !this.state[e.target.name]
        }, () => {
            this.servicesValue();
        })
    }

    setGuideLanguage = (id) => {


        if (this.state.selectedGuide.includes(id)) {
            this.setState({
                selectedGuide: this.state.selectedGuide.filter(item => item !== id)
            }, () => {
                this.sendPostRequest();
            })

        } else {

            this.setState({
                selectedGuide: [...this.state.selectedGuide, id]
            }, () => {
                this.sendPostRequest();
            })

        }
    }


    setDestinations = (id) => {


        if (this.state.selectedDestination.includes(id)) {
            this.setState({
                selectedDestination: this.state.selectedDestination.filter(item => item !== id)
            }, () => {
                this.sendPostRequest();
            })

        } else {

            this.setState({
                selectedDestination: [...this.state.selectedDestination, id]
            }, () => {
                this.sendPostRequest();
            })

        }
    }

    scrolls = () => {
        let element = document.getElementById("categories");
        element.scrollTo({ left: element.scrollLeft + 400, behavior: 'smooth' });
    }


    render() {
        const { data, filters, products, counts, priceRangeCurr } = this.state
        return (
            <div className="searchContainer">
                <div className='search'>

                    <div className='categories'></div>
                    <div className='searchPanels'>
                        <div className='filters'>
                            <div className='dates'>
                                <div className='title'>
                                    When are you traveling?
                                </div>
                                <div className='calendar'>
                                    <Flatpickr
                                        ref={calendar => { this.calendar = calendar; }}
                                        placeholder="Select date"
                                        //   value={[new Date(), "2022-11-04"]}
                                        options={{
                                            minDate: "today",
                                            altInput: true,
                                            altFormat: "F j",
                                            dateFormat: "Y-m-d",
                                            defaultDate: [this.state.startDate, this.state.endDate],
                                            locale: {
                                                firstDayOfWeek: 1
                                            },
                                            mode: "range"
                                        }}
                                        onChange={(selectedDates, dateStr, instance) => {


                                            selectedDates.map(item => {

                                                if (selectedDates.length < 2) {
                                                    this.setState({
                                                        startDate: Moment(selectedDates[0]).format("YYYY-MM-DD"),
                                                        endDate: Moment(selectedDates[0]).format("YYYY-MM-DD")
                                                    })
                                                } else {
                                                    this.setState({
                                                        startDate: Moment(selectedDates[0]).format("YYYY-MM-DD"),
                                                        endDate: Moment(selectedDates[1]).format("YYYY-MM-DD")
                                                    })
                                                }

                                            });


                                        }}
                                    />
                                    <div className="icon">
                                        <img src="/Assets/img/icon/calendar.svg" alt="Search" />
                                    </div>
                                </div>
                                <div className='button' onClick={this.sendPostRequest}>Result</div>
                            </div>
                            <div className='filterItem'>
                                <div className='title'>
                                    Price
                                </div>
                                <div className='elems price'>
                                    <div className='price'>
                                        <Range min={0} max={500} allowCross={false} value={this.state.priceRange} onChange={this.onSliderChange} />
                                    </div>
                                    {!this.state.sendRequestLoading ?
                                        <div className="priceVals">
                                            <div className='startPrice'>
                                                <CurrencyFormat value={this.state.priceRange[0] * priceRangeCurr.rate}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                    prefix={priceRangeCurr.toSymbol + " "} />

                                            </div>
                                            <div className='endPricePrice'>


                                                {this.state.priceRange[1] === 500 ?
                                                    <CurrencyFormat value={this.state.priceRange[1] * priceRangeCurr.rate}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        suffix={" +"}
                                                        prefix={priceRangeCurr.toSymbol + " "} />
                                                    :
                                                    <>
                                                        <CurrencyFormat value={this.state.priceRange[1] * priceRangeCurr.rate}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}
                                                            prefix={priceRangeCurr.toSymbol + " "} />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <LoadingSmall />
                                    }
                                </div>
                            </div>

                            <div className='filterItem'>
                                <div className='title'>
                                    Duration<span className="chevron"></span>
                                </div>
                                <div className='elems'>
                                    <ul>
                                        <li>
                                            <input name="durationMin3H" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="3h" type="checkbox" value="1" />
                                            <label htmlFor="3h">0 - 3 hours</label>
                                        </li>
                                        <li>
                                            <input name="duration3H5H" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="5h" type="checkbox" value="1" />
                                            <label htmlFor="5h">3 - 5 hours</label>
                                        </li>
                                        <li>
                                            <input name="duration5H7H" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="7h" type="checkbox" value="1" />
                                            <label htmlFor="7h">5 - 7 hours</label>
                                        </li>
                                        <li>
                                            <input name="duration7H24H" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="8h" type="checkbox" value="1" />
                                            <label htmlFor="8h">Full day (7+ hours)</label>
                                        </li>
                                        <li>
                                            <input name="duration1D3D" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="3d" type="checkbox" value="1" />
                                            <label htmlFor="3d">1-3 days</label>
                                        </li>
                                        <li>
                                            <input name="durationMin3D" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="4d" type="checkbox" value="1" />
                                            <label htmlFor="4d">3+ days</label>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className='filterItem'>
                                <div className='title'>
                                    Specials<span className="chevron"></span>
                                </div>
                                <div className='elems'>
                                    <ul>
                                        <li>
                                            <input name="disconts" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="disconts" type="checkbox" value="1" />
                                            <label htmlFor="disconts">Discounts</label>
                                        </li>
                                        <li>
                                            <input name="freeCancel" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="freeCancel" type="checkbox" value="1" />
                                            <label htmlFor="freeCancel">Free cancellation </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className='filterItem'>
                                <div className='title'>
                                    Rate<span className="chevron"></span>
                                </div>
                                <div className='elems'>
                                    <ul>
                                        <li>
                                            <input name="disconts" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="disconts" type="checkbox" value="1" />
                                            <Rate
                                                defaultValue={4}
                                                style={{ fontSize: 32, paddingTop: 0 }}

                                            />
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className='filterItem'>
                                <div className='title'>
                                    Service<span className="chevron"></span>
                                </div>
                                <div className='elems'>
                                    <ul>
                                        <li>
                                            <input name="privateTour" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="privateTour" type="checkbox" value="1" />
                                            <label htmlFor="privateTour">Private Tour</label>
                                        </li>
                                        <li>
                                            <input name="skiptoline" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="skiptoline" type="checkbox" value="1" />
                                            <label htmlFor="skiptoline">Skip the line</label>
                                        </li>
                                        <li>
                                            <input name="weelchair" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="weelchair" type="checkbox" value="1" />
                                            <label htmlFor="weelchair">Wheelchair accessible</label>
                                        </li>

                                        <li>
                                            <input name="pickUpService" onChange={this.setDurationTimes.bind(this)} className="styled-checkbox" id="pickUpService" type="checkbox" value="1" />
                                            <label htmlFor="pickUpService">Pickup posibble</label>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                            {!this.state.sendRequestLoading ?
                                <>
                                    {this.state.filters.destination.length > 0 &&
                                        <div className='filterItem'>
                                            <div className='title'>Destinations <span className="chevron"></span></div>
                                            <div className='elems'>

                                                <ul>
                                                    {this.state.filters.destination.map((items, index) => (
                                                        <li>
                                                            <input name={"dest-" + items.id} onChange={this.setDestinations.bind(this, items.id)} className="styled-checkbox" id={"dest-" + index} type="checkbox" value="1" />
                                                            <label htmlFor={"dest-" + index}>{items.destination}</label>
                                                        </li>
                                                    ))}
                                                </ul>

                                            </div>
                                        </div>
                                    }
                                </>
                                :
                                <LoadingSmall />
                            }
                            {!this.state.sendRequestLoading ?
                                <>
                                    {this.state.filters.guideLanguage.length > 0 &&
                                        <div className='filterItem'>
                                            <div className='title'>Guide Language <span className="chevron"></span></div>
                                            <div className='elems'>

                                                <ul>
                                                    {this.state.filters.guideLanguage.map((items, index) => (
                                                        <li>
                                                            <input onChange={this.setGuideLanguage.bind(this, items.id)} name={"guide-" + items.id} className="styled-checkbox" id={"guide-" + index} type="checkbox" value="1" />
                                                            <label htmlFor={"guide-" + index}>{items.guideLanguage}</label>
                                                        </li>
                                                    ))}
                                                </ul>

                                            </div>
                                        </div>
                                    }
                                </>
                                :
                                <LoadingSmall />
                            }
                        </div>
                        <div className='result'>
                            <div className='searchQuery'>Results for "{this.state.q}"</div>
                            {!this.state.sendRequestLoading ?
                                <>
                                    {this.state.filters.categories.length > 0 &&
                                        <div className='categories'>
                                            <div className="catItems" id="categories">
                                                {this.state.filters.categories.map((item, index) => (
                                                    <div className={this.state.selectedCategories.includes(item.id) ? 'catItem active' : 'catItem'} onClick={this.setCategories.bind(this, item.id)}>
                                                        {item.categories}
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="arrows" onClick={this.scrolls.bind(this)}>
                                                <div className="icon"><img src="/Assets/img/icon/down.svg" alt="down" /></div>

                                            </div>
                                        </div>
                                    }
                                    <div className='shorting'>
                                        <div className='searchQueryCount'>{counts} activities found</div>
                                        <div className='shortButton'>
                                            <DropdownMenu clickClose={true} title={"Sort by: " + this.state.sortTitle} menu={
                                                <ul>
                                                    <li onClick={this.setSort.bind(this, "featured", "Featured")}>Featured</li>
                                                    <li onClick={this.setSort.bind(this, "priceLow", "Price (Low to High)")}>Price (Low to High)</li>
                                                    <li onClick={this.setSort.bind(this, "priceHigh", "Price (High to Low)")}>Price (High to Low)</li>
                                                    <li onClick={this.setSort.bind(this, "durationShort", "Duration (Short to Long)")}>Duration (Short to Long)</li>
                                                    <li onClick={this.setSort.bind(this, "durationLong", "Duration (Long to Short)")}>Duration (Long to Short)</li>
                                                    <li onClick={this.setSort.bind(this, "new", "New on Tourzone")}>New on Tourzone</li>
                                                </ul>

                                            } />
                                        </div>
                                    </div>
                                    {this.state.bodyLoading &&
                                        <div className='bodyLoading'><LoadingSmall /> </div>
                                    }
                                    {this.state.products.map((item, index) => (
                                        <Link to={{ pathname: `/Product/${item.slug}`, state: { id: item.id } }}>
                                            <div className='resultBody'>
                                                <div className='photo'>
                                                    <div className='search-pic'
                                                        style={{
                                                            backgroundImage: "url(" + process.env.REACT_APP_ASSETSNEW_ADDRESS + "products/360/" + item.img + ")",
                                                            backgroundColor: "#ccc"
                                                        }}>
                                                        <div className='wishlistProduct'>
                                                            <img src="/Assets/img/icon/wishlist_3.svg" alt="menu" />
                                                        </div>
                                                        <div className='resultDestination'>
                                                            {item.destination}
                                                        </div>

                                                        <div className='resultCategories'>
                                                            {item.categories}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='resultContent'>
                                                    <div className='productTitle'>
                                                        {item.name}
                                                    </div>
                                                    <div className='productText'>
                                                        {item.text}
                                                    </div>
                                                    <div className='rating'>
                                                        <Rate
                                                            defaultValue={4.5}

                                                            disabled
                                                            style={{ fontSize: 18, paddingTop: 0 }}

                                                        />
                                                    </div>
                                                    <div className="productSpect">
                                                        <div className="icons"><img src="/Assets/img/productIcon/8.svg" alt="icon" /></div>
                                                        <div className="text">{item.duration.flex === false ? this.setDurationTime(item.duration) : "Flex"}</div>
                                                    </div>


                                                    {item.weelchair &&
                                                        <div className="productSpect">
                                                            <div className="icons"><img src="/Assets/img/productIcon/10.svg" alt="icon" /></div>
                                                            <div className="text">Wheelchair compatibility</div>
                                                        </div>
                                                    }



                                                    {item.privateTour &&
                                                        <div className="productSpect">
                                                            <div className="icons"><img src="/Assets/img/productIcon/5.svg" alt="icon" /></div>
                                                            <div className="text">Private Tour</div>
                                                        </div>
                                                    }

                                                    {item.liveTourGuide &&
                                                        <div className="productSpect">
                                                            <div className="icons"><img src="/Assets/img/productIcon/12.svg" alt="icon" /></div>
                                                            <div className="text">Live Guide</div>
                                                        </div>
                                                    }

                                                    {item.cancelPolicyId !== "10" &&
                                                        <div className="productSpect freecancel">
                                                            <div className="icons"><img src="/Assets/img/icon/calendar.svg" alt="icon" /></div>
                                                            <div className="text">Free cancellation</div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='resultPrice'>
                                                    {item.discountRate !== null &&
                                                        <div className='discountRate'>SAVE UP TO {item.discountRate * 100} %</div>
                                                    }
                                                    <div className='bestPriceFrom'>from</div>
                                                    <div className='bestPrice'>

                                                        <CurrencyFormat value={item.minPrice}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}
                                                            prefix={item.currency + " "} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                    }


                                </>
                                :
                                <div className='bodyLoading'><LoadingSmall /> </div>
                            }
                        </div>
                    </div>
                </div>



            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
})



export default connect(mapStateToProps)(Search)
