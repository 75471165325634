import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/flatpickr.min.css'
import { BookingEngineCalendar, SetCalendarDate, BookingEngineSessionAndGuides } from "../../Action/BookingAction"
export class BookingCalendar extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }
  calendar = React.createRef();
  getCalendar = (data) => {
    this.props.BookingEngineCalendar(data)
  };


  componentDidMount() {
    const { engineData } = this.props;

    const data = {
      packageId: engineData.packageData[0].id,
      productId: this.props.productId,
      startDate: moment(engineData.packageData[0].startDate) < moment() ? moment().startOf('month').format("YYYY-MM-DD") : engineData.packageData[0].startDate,
      endDate: moment(engineData.packageData[0].startDate).endOf('month').format("YYYY-MM-DD")
    }
    this.props.BookingEngineCalendar(data);
  }



  render() {

    const { engineData } = this.props;

    return (
      <div className='bookingCalendar'>
        <div className='calendarTitle'>Please select a tour date</div>

        {!engineData.loading &&

          <Flatpickr
            ref={calendar => { this.calendar = calendar; }}
            placeholder="Check availability"
            className={"bookingDateInput"}
            value={engineData.selectedDate}
            options={{
              minDate: moment(engineData.packageData[engineData.selectedPackage].startDate) < moment() ? moment().format("YYYY-MM-DD") : engineData.packageData[engineData.selectedPackage].startDate,
              altInput: true,
              altFormat: "F j, Y",
              dateFormat: "Y-m-d",
              maxDate: engineData.packageData[engineData.selectedPackage].maxDate,
              enable: engineData.packageData[engineData.selectedPackage].availabilityDate,
              locale: {
                firstDayOfWeek: 1
              }
            }}
            onChange={(selectedDates, dateStr, instance) => {

              const datas = {
                dates: dateStr,
                packageId: engineData.packageData[engineData.selectedPackage].id,
                productId: this.props.productId

              }

              this.props.SetCalendarDate(datas);
              this.props.BookingEngineSessionAndGuides(datas)

            }}
            onDayCreate={(dObj, dStr, fp, dayElem) => {


              if (this.props.engineData.calendarLoading) {
                dayElem.innerHTML += "<div class='lds-ellipsis'><div></div><div></div><div></div><div></div></div>";

                dayElem.className += ' loadingCalendarDay';

              } else {

                //dayElem.className += ' loading';

                let currentDate = moment(dayElem.dateObj).format('YYYY-MM-DD');
                this.props.engineData.calendarPriceList.map(item => {

                  if (item.date === currentDate) {
                    if (item.status && !item.stop && item.quota.length > 0) {
                      dayElem.innerHTML += "<span class='event'>" + item.exchange.toSymbol + " " + item.price + "</span>";

                      if (item.spo) {
                        dayElem.className += ' discountColor';
                      }
                      if (moment(currentDate) >= moment()) {
                        if (item.guide.length > 0) {

                          let guideParent = "<div class='guideCalendar'>";
                          dayElem.innerHTML += "";
                          item.guide.map(g => {
                            guideParent += "<div class='guideColor guide" + g.id + "'></div>";
                          })

                          guideParent += "</div>";
                          dayElem.innerHTML += guideParent
                        }
                      }
                    } else {
                      dayElem.className = ' flatpickr-day flatpickr-disabled '
                      if (moment(currentDate) >= moment()) {
                        dayElem.innerHTML += "<div class='disabledShash'></div>"
                      }
                    }
                  }
                });

                if (!this.state.calendarLoading) {
                  dayElem.querySelectorAll(".lds-ellipsis").forEach(el => el.remove());
                  dayElem.classList.remove('loadingCalendarDay');
                }
              }

            }}
            onMonthChange={(value, dateStr, fp) => {
              fp.days.className += ' discountColor';
              let dates = new Date(fp.currentYear, fp.currentMonth);



              const data = {
                packageId: engineData.packageData[engineData.selectedPackage].id,
                productId: this.props.productId,
                startDate: moment(dates).startOf('month').format("YYYY-MM-DD"),
                endDate: moment(dates).endOf('month').format("YYYY-MM-DD")
              }

              this.getCalendar(data);




            }}
          />
        }
      </div>

    )
  }
}


const mapStateToProps = (state) => ({
  appData: state.appData,
  engineData: state.engineData

});

export default connect(mapStateToProps, { BookingEngineCalendar, SetCalendarDate, BookingEngineSessionAndGuides })(BookingCalendar)
