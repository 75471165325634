import React, { Component } from 'react'
import { connect } from 'react-redux'

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import * as turf from '@turf/turf'
import axios from "axios";
import { sub } from 'date-fns';
import { lineString } from '@turf/turf';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiZW1yZWNldGUiLCJhIjoiY2tqM2N3ZGQxMmFqbDJ0bnhtenRvZW1kcCJ9.Y2wLTA5RF0gzpqtcM8kzRA';

class Itinerary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lng: -70.9,
            lat: 42.35,
            zoom: 7,
            maxZoom: 13,
            data: this.props.data.map,
            sendRequestLoading: true,
            maps: "",
            road: [],
            features: []

        };
        this.mapContainer = React.createRef();
    }


    getMap = () => {

        this.initMap()

    };

    componentDidMount() {
        this.getMap()
    }

    initMap = () => {
        const { lng, lat, zoom } = this.state;
        const map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/emrecete/cklz59m697xfa17mwet3yi7sr',
            center: [lng, lat],
            zoom: zoom
        });

        map.scrollZoom.disable();

        this.setState(
            {
                maps: map
            }
        )
        map.addControl(new mapboxgl.NavigationControl());

        let _this = this;


        map.once('style.load', function (e) {

        });


        map.on('load', function () {
            /*
            
            marker
            */

            let a = 0;

            let roadArray = [];

            let featuresing = [];

            let els = document.createElement('div');
            els.className = 'marker';
            els.innerHTML = '<span class="last">&#9733;</span>'
            new mapboxgl.Marker(els)
                .setLngLat(_this.state.data.startPoint.geometry.coordinates)
                .setPopup(new mapboxgl.Popup({
                    offset: 25
                }).setHTML('<h3>Start Point</h3><p></p>'))
                .addTo(map);


            roadArray.push(_this.state.data.startPoint.geometry.coordinates);
            featuresing.push(_this.state.data.startPoint);
            _this.state.data.daily.map((daily) => {

                daily.content.map((content, index) => {

                    let el = document.createElement('div');
                    el.className = 'marker';
                    let len = daily.content.length
                    if (index === (len - 1)) {
                        el.innerHTML = '<span class="firstAndLast"><div class="circles"></div></span>'
                        //el.innerHTML = '<span><b>&#9733;</b></span>'
                    } else {
                        el.innerHTML = '<span class="points"><b></b></span>'
                        //el.innerHTML = '<span class="points"><b></b></span>'
                    }


                    let coord = content.geo.geometry.coordinates

                    new mapboxgl.Marker(el)
                        .setLngLat(coord)
                        .setPopup(new mapboxgl.Popup({
                            offset: 25
                        }).setHTML('<h3>' + content.geo.properties.title + '</h3><p></p>'))
                        .addTo(map);

                    roadArray.push(coord);
                    featuresing.push(content.geo);
                    a++;
                });

            });

            roadArray.push(_this.state.data.endPoint.geometry.coordinates);
            featuresing.push(_this.state.data.endPoint);
            let els2 = document.createElement('div');
            els2.className = 'marker';
            els2.innerHTML = '<span class="last">&#9733;</span>'
            new mapboxgl.Marker(els2)
                .setLngLat(_this.state.data.endPoint.geometry.coordinates)
                .setPopup(new mapboxgl.Popup({
                    offset: 25
                }).setHTML('<h3>End Point</h3><p></p>'))
                .addTo(map);


            let m = 0;

            for (let c = 0; c < roadArray.length; c++) {
                if (typeof roadArray[c + 1] != "undefined") {
                    var d = {
                        start: roadArray[c],
                        end: roadArray[c + 1]
                    }

                    let LineString = {
                        type: 'LineString',
                        coordinates: [d.start, d.end]
                    };

                    let routess = turf.projection.toWgs84(LineString);

                    let lineD = turf.lineDistance(routess, { units: 'kilometers' });
                    let mp = turf.midpoint(routess.coordinates[0], routess.coordinates[1]);
                    let center = turf.destination(
                        mp,
                        lineD,
                        turf.bearing(routess.coordinates[0], routess.coordinates[1]) - 90
                    );

                    let lA = turf.lineArc(
                        center,
                        turf.distance(center, routess.coordinates[0]),
                        turf.bearing(center, routess.coordinates[1]),
                        turf.bearing(center, routess.coordinates[0])
                    );




                    map.addLayer(
                        {
                            id: "routemxs" + m,
                            type: 'line',
                            source: {
                                type: 'geojson',
                                data: turf.projection.toMercator(lA)
                            },
                            paint: {
                                'line-width': 1,
                                'line-color': '#000',

                            },

                        });



                    _this.createArrow(m, turf.projection.toMercator(lA), map);
                    m++;



                }


                map["dragPan"].disable()
            }


            let bounds = featuresing.reduce(function (bounds, feature) {
                if (!Array.isArray(feature.geometry.coordinates[0])) { // point feature
                    return bounds.extend(feature.geometry.coordinates);
                } else {
                    return feature.geometry.coordinates.reduce(function (bounds, coord) {
                        return bounds.extend(coord);
                    }, bounds);
                }
            }, new mapboxgl.LngLatBounds());

            map.fitBounds(bounds, {
                padding: { top: 50, bottom: 50, left: 50, right: 50 },
                maxZoom:8,
                easing(t) {
                    return t * (2 - t);
                },
            });



            let places = {
                'type': 'FeatureCollection',
                'features': featuresing
            }

            map.addSource('places', {
                'type': 'geojson',
                'data': places
            });

            map.addLayer({
                id: 'points',
                type: 'symbol',
                source: "places",
                layout: {
                    'text-field': ['get', 'title'],
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold'
                    ],
                    'text-offset': [0, 0.4],
                    'text-anchor': 'top',
                    'text-size': 13,

                }
            });

        });

        const myCustomControl = new MyCustomControl();
        map.addControl(myCustomControl, "top-left");

    }






    reDrawinMap = (e, a, m) => {
        let i = e;
        this.state.maps.flyTo({
            zoom: 8,
            center: e,
            pitch: 1 * 5,
            bearing: 1 * 5,
            essential: true
        })





        this.scrollTo(a);

    }

    scrollTo = (a) => {

        let element = document.getElementById(a);
        let viewport = document.getElementById("pointBody")
        let letGo = (viewport.scrollTop - 15) + this.getBoundingRect(element, viewport).top
        viewport.scrollTo({ top: letGo, behavior: 'smooth' });
    }




    getBoundingRect = (element, viewport) => {
        // root element
        if (viewport === document.documentElement) {
            return element.getBoundingClientRect();
        }
        // relative to viewport
        else {
            var elRect = element.getBoundingClientRect();
            var vpRect = viewport.getBoundingClientRect();
            return {
                bottom: elRect.bottom - vpRect.top,
                height: elRect.height,

                left: elRect.left - vpRect.left,
                right: elRect.right - vpRect.left,
                top: elRect.top - vpRect.top,
                width: elRect.width
            };
        }
    }






    createArrow = (x, datas, map) => {
        if (map.getLayer('arrow-layer' + x)) {
            map.removeLayer('arrow-layer' + x);
        }

        var url = '/Assets/img/icon/arrowsmap2.png';
        map.loadImage(url, function (err, image) {

            if (err) {
                console.error('err image', err);
                return;
            }
            if (map.hasImage(image)) {
                map.removeImage(image);
            }

            map.addImage('arrow' + x, image);


            map.addLayer({
                id: 'arrow-layer' + x,
                type: 'symbol',
                tolerance: 0,
                source: {
                    type: 'geojson',
                    data: datas
                },
                layout: {
                    'symbol-placement': 'line-center',
                    'symbol-spacing': 1,
                    'icon-allow-overlap': true,
                    'text-allow-overlap': true,
                    // 'icon-ignore-placement': true,
                    'icon-image': 'arrow' + x,
                    'icon-size': 1,
                    'visibility': 'visible'
                }
            });
        });
    }



    render() {



        return (
            <div className="itinerary spy" id="itinerarySpy">
                <div className="title">
                    Itinerary
                </div>
                <div className="text">{this.props.data.itinerary}</div>

                {(this.state.data.daily[0].tourType === "singelDay" && this.state.data.daily[0].content.length === 0) ?

                    <div className="container">
                        <div className="maps" style={{width:"100%"}}>
                            <div ref={this.mapContainer} className="map-container" />
                        </div>
                    </div>

                    :

                    <>
                        <div className="container">



                            <div className="contents">
                                <div className="productTitleSub">Trip points</div>
                                <div className="list">

                                    <ul>

                                        <li key={999} data-key={999} onClick={this.reDrawinMap.bind(this, this.state.data.startPoint.geometry.coordinates, "item0-0")}>
                                            <div className="icon">

                                                <img className="icons" src="/Assets/img/icon/locator.svg" alt="Top destination" />
                                            </div>
                                            <div className="text"><b>Start Point</b></div>
                                        </li>

                                        {this.state.data.daily.map((contents, indext) => (
                                            <>
                                                {contents.content.map((item, index) => (
                                                    <li key={index} data-key={index} onClick={this.reDrawinMap.bind(this, item.geo.geometry.coordinates, "item" + indext + "-" + index)}>
                                                        <div className="icon">
                                                            {index === (contents.content.length - 1) && contents.stayDay === "1" ?
                                                                <img className="icons" src="/Assets/img/icon/pin2.svg" alt="Top destination" />
                                                                : <img className="iconsCircle" src="/Assets/img/icon/circle2.svg" alt="Top destination" />
                                                            }
                                                        </div>
                                                        <div className="text"><b>{item.title} </b>- <small>{index === (contents.content.length - 1) && contents.stayDay === "1" ? "1 nigth" : "via"}</small></div>
                                                    </li>
                                                ))}

                                            </>
                                        ))
                                        }

                                        <li key={9999} data-key={9999} onClick={this.reDrawinMap.bind(this, this.state.data.endPoint.geometry.coordinates, "item0-0")}>
                                            <div className="icon">

                                                <img className="icons" src="/Assets/img/icon/locator.svg" alt="Top destination" />
                                            </div>
                                            <div className="text"><b>End Point</b></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="pointBody" id="pointBody">
                                {this.state.data.daily.map((contents, indext) => (
                                    <>

                                        {contents.tourType === "singelDay" ?
                                            <div className='days'>

                                            </div>
                                            :
                                            <div className='days'>
                                                <div className='dayItem'>{contents.dailyNumber} Day</div>
                                                <div className='dayText'>
                                                    {
                                                        contents.title
                                                    }

                                                </div>
                                            </div>
                                        }
                                        <ul>
                                            {contents.content.map((item, index) => (
                                                <li id={"item" + indext + "-" + index} key={indext + "-" + index} data-key={indext + "-" + index}>
                                                    <div className="title">{item.title}</div>
                                                    <div className="text">{item.detail}</div>
                                                    {item.duration.hour !== "0" && item.duration.minutes !== "0" &&
                                                        <div className='items'>
                                                            <div className='icon'>
                                                                <img src="/Assets/img/productIcon/8.svg" alt="icon" />
                                                            </div>
                                                            <div className='duration'>{item.duration.hour} H {item.duration.minutes} Min </div>
                                                        </div>
                                                    }
                                                    {contents.stayDay === "1" &&
                                                        <div className='items'>
                                                            <div className='icon'>
                                                                <img src="/Assets/img/icon/bed.svg" alt="icon" />
                                                            </div>
                                                            <div className='duration'>{contents.stay}</div>
                                                        </div>
                                                    }

                                                </li>
                                            ))}
                                        </ul>

                                    </>
                                ))
                                }
                            </div>
                            <div className="maps">
                                <div ref={this.mapContainer} className="map-container" />
                                <div className="popupInfo">
                                    <ul>
                                        <li>
                                            <div className="icon">
                                                <div className="startMarker"><b>&#9733;</b></div>
                                            </div>
                                            <div className="text">Start Point</div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <div className="endMarker"><b>&#9733;</b></div>
                                            </div>
                                            <div className="text">End Point</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(Itinerary)


class MyCustomControl {


    onAdd(map) {
        this.map = map;


        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon" + " helpMap";
        this._btn.type = "button";
        this._btn.title = "?";
        this._btn.innerHTML = "?";
        this._btn.onclick = this.openHelpWindow;

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
    }
    onRemove() {
        this.container.parentNode.removeChild(this._container);
        this.map = undefined;
    }

    openHelpWindow() {
        let elem = document.querySelector(".popupInfo");
        console.log(elem.classList.contains("active"));
        if (!elem.classList.contains("active")) {
            elem.classList.add("active");
        } else {
            elem.classList.remove("active");
        }

    }
}