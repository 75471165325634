import React, { Component } from 'react'

export default class LoadingSmall extends Component {
    render() {
        return (
            <div className="tourzone-loader">
                <div className="tourzone-inner tourzone-one"></div>
                <div className="tourzone-inner tourzone-two"></div>
                <div className="tourzone-inner tourzone-three"></div>
            </div>
        )
    }
}
