import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import {
    BookingPackageGet,
    BookingPackageSetId,
    BookingEngineCalendarGet,

} from "../Action/BookingEngineAction"

export class BookingPackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productId: props.productId,
        }
    }

    componentDidMount() {
        const { productId } = this.props.engineData;
        this.props.BookingPackageGet(productId);

    }

    setPackage = (id) => {
        this.props.BookingPackageSetId(id);
        this.setCalendar(id);
    }


    setCalendar = (id) => {
        const { engineData } = this.props;
        const data = {
            packageId: this.getSelectedPackage(id).id,
            productId: engineData.productId,
            startDate: moment(this.getSelectedPackage(id).startDate) < moment() ? moment().startOf('month').format("YYYY-MM-DD") : this.getSelectedPackage(id).startDate,
            endDate: moment(this.getSelectedPackage(id).startDate).endOf('month').format("YYYY-MM-DD")
        }
        this.props.BookingEngineCalendarGet(data);
    }

    getSelectedPackage = (id) => {
        const { engineData } = this.props;
        return engineData.packagesData.filter(items => items.id === id)[0];
    }

    render() {

        const { packagesData, packagesLoading } = this.props.engineData;



        return (
            <>
                {packagesLoading ? "" :
                    <div className='packages'>


                        <div className='packageTitle'>Package Type</div>
                        <div className='packageItem'>
                            {packagesData.map((item, index) => (
                                <div onClick={this.setPackage.bind(this, item.id)} key={index} className={this.props.engineData.selectedPackage === item.id ? "package active" : "package default"}>{item.packageTitle}</div>
                            ))}
                        </div>
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    engineData: state.engineData


});

export default connect(mapStateToProps, {
    BookingPackageGet,
    BookingPackageSetId,
    BookingEngineCalendarGet

})(BookingPackage)