import React, { Component } from 'react'
import { connect } from 'react-redux'

class Covid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,

        };
    }
    render() {
        return (
            <div className="covid">
                <div className="title">COVID-19 Updates</div>
                <div className="content">
                    <ul>
                        {this.state.data.covid.map((item, index) => (

                            <li key={index}>{item.title}</li>

                        ))}

                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(Covid)
