import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import { BookingEnginePackage, UpdateSelectedPackage, BookingEngineCalendar } from "../../Action/BookingAction"

export class BookingPackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productId: props.productId,
        }
    }

    componentDidMount() {
        this.props.BookingEnginePackage(this.state.productId)
    }

    setPackage = (i) => {
        this.props.UpdateSelectedPackage(i);
        
        const { engineData } = this.props;

        const data = {
            packageId: engineData.packageData[i].id,
            productId: this.props.productId,
            startDate: moment(engineData.packageData[i].startDate) < moment() ? moment().startOf('month').format("YYYY-MM-DD") : engineData.packageData[i].startDate,
            endDate:  moment(engineData.packageData[i].startDate).endOf('month').format("YYYY-MM-DD")
          }
        this.props.BookingEngineCalendar(data);
    }

    render() {

        const { packageData } = this.props.engineData;

     

        return (
            <div className='packages'>

                <div className='packageTitle'>Package Type</div>
                <div className='packageItem'>
                    {packageData.map((item, index) => (
                        <div onClick={this.setPackage.bind(this, index, item.id)} key={index} className={this.props.engineData.selectedPackage === index ? "package active" : "package default"}>{item.packageTitle}</div>
                    ))}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    engineData: state.engineData


});

export default connect(mapStateToProps, { BookingEnginePackage, UpdateSelectedPackage, BookingEngineCalendar })(BookingPackage)