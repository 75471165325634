import React, { Component } from 'react'
import { connect } from 'react-redux'
import LanguageSet from '../../../Helpers/LanguageSet';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Lazy } from 'swiper';
import 'swiper/swiper.min.css'
import 'swiper/components/lazy/lazy.scss';
import { Link } from 'react-router-dom';

export class YouMightAlsoLike extends Component {
    constructor(props) {
        super(props);

        this.state = {

            data: [],



        }
        SwiperCore.use([Lazy]);
    }
    componentDidMount() {
        this.setState({
            data: this.props.data.youMightAlsoLike

        })
    }

    render() {

        return (
            <div className="youMightAlsoLike">
                <div className="title">You might also like</div>


                <>
                    <Swiper
                        slidesPerView={4} // or 'auto'
                        slidesPerGroup={1}
                        spaceBetween={15}
                        lazy={true}
                        breakpoints={{
                            // when window width is >= 640px
                            300: {

                                slidesPerView: 1.3,
                            },
                            1024: {

                                slidesPerView: 3,
                            },
                            1280: {

                                slidesPerView: 4,
                            },
                            // when window width is >= 768px
                            1337: {

                                slidesPerView: 4,
                            },
                        }}

                    >

                        {

                            this.state.data.map((product, index) => (
                                <SwiperSlide key={index}>
                                    <div className="productDiv">
                                        <div className="productImg swiper-lazy">
                                            {product.img.slice(0, 1).map((item, i) => (
                                                <div key={i}>
                                                    <>
                                                        {
                                                            i === 0 &&
                                                            <>

                                                                <img src={process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/360/" + item.name} alt="productImg" />
                                                            </>
                                                        }
                                                    </>
                                                </div>
                                            ))
                                            }
                                            <div className="priceParent">
                                                <div className="currency"></div>
                                                <div className="price"></div>

                                            </div>
                                        </div>
                                        <div className="productDetail">
                                            <div className="productTitle">{product.name}</div>
                                            <div className="productText">{product.text}</div>

                                        </div>
                                        <div className="line"></div>
                                        <div className="productFooter">
                                            <div className="col-25 rating">
                                                &#9733; 4.9/5
                                            </div>
                                            <div className="col-75 directionRight">
                                                <Link to={{ pathname: `/Product/${product.slug}`, state: { id: product.id } }}>
                                                    <div className="viewProduct">
                                                        <LanguageSet code="HOME_PRODUCT_VIEW_PRODUCT" />
                                                    </div>
                                                </Link>


                                            </div>

                                        </div>
                                        <div className="line"></div>
                                        <div className="footerIcon">
                                            <div className="icon">
                                                <img src="/Assets/img/icon/eye.svg" alt="show" />
                                                <div className="info">({product.show}) <LanguageSet code="HOME_PRODUCT_TOTAL_VIEWS" /></div>
                                            </div>
                                            <div className="icon">
                                                <img src="/Assets/img/icon/share.svg" alt="share" />
                                                <div className="info"><LanguageSet code="HOME_PRODUCT_SHARE" /></div>
                                            </div>
                                            <div className="icon">
                                                <img src="/Assets/img/icon/heart.svg" alt="wishlist" />
                                                <div className="info"><LanguageSet code="HOME_PRODUCT_ADD_WISHLIST" /></div>
                                            </div>
                                            <div className="icon">
                                                <img src="/Assets/img/icon/comment.svg" alt="comment" />
                                                <div className="info"><LanguageSet code="HOME_PRODUCT_ADD_COMMENT" /></div>
                                            </div>

                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }

                    </Swiper>
                </>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData
});

export default connect(mapStateToProps)(YouMightAlsoLike)

