import {
    BOOKING_GET,
    BOOKING_SETTING_GET,
    BOOKING_ENGINE_PACKAGE_GET,
    BOOKING_ENGINE_PACKAGE_UPDATE_SELECTED,
    BOOKING_ENGINE_CALENDAR_GET,
    BOOKING_ENGINE_CALENDAR_LOADING,
    BOOKING_ENGINE_PACKAGE_LOADING,
    BOOKING_ENGINE_CALENDAR_SET_DATE,
    BOOKING_ENGINE_SESSION_GET,
    BOOKING_ENGINE_GUIDE_GET,
    BOOKING_ENGINE_SET_GUIDE,
    BOOKING_ENGINE_SET_SESSIONS,
    BOOKING_UPDATE_PAX,
    BOOKING_FIRST_PRICE,
    BOOKING_BLOCK_PRICE_UPDATE
} from '../../types';
import axios from "axios";



export const BookingSettings = (datas) => async dispatch => {

    const data = {
        productId: datas,
    }
    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getProductSettings`, data)

        if (resp.status === 200) {
            dispatch({
                type: BOOKING_SETTING_GET,
                payload: resp.data.data
            });

        }
    } catch (err) {
        console.error(err);
    }

}

export const BookingsAction = (data) => dispatch => {

    dispatch({
        type: BOOKING_GET,
        payload: data
    });

};

export const BookingEnginePackage = (id) => async dispatch => {

    const data = {
        productId: id,
    }



    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getProductPackage`, data)

        if (resp.status === 200) {
            dispatch({
                type: BOOKING_ENGINE_PACKAGE_GET,
                payload: resp.data.packages
            })
        }
    } catch (err) {
        console.error(err);
    }

}

export const UpdateSelectedPackage = (selectedId) => dispatch => {


    dispatch({
        type: BOOKING_ENGINE_PACKAGE_UPDATE_SELECTED,
        payload: selectedId
    })

}




export const BookingEngineCalendar = (datas) => async dispatch => {

    const data = {
        packageId: datas.packageId,
        productId: datas.productId,
        startDate: datas.startDate,
        endDate: datas.endDate
    }

    dispatch({
        type: BOOKING_ENGINE_CALENDAR_LOADING,
        payload: true
    })



    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getCalendar`, data)

        if (resp.status === 200) {

            dispatch({
                type: BOOKING_ENGINE_CALENDAR_GET,
                payload: resp.data.calendar
            })

        }
    } catch (err) {
        console.error(err);
    }

}

export const SetCalendarDate = (data) => dispatch => {

    dispatch({
        type: BOOKING_ENGINE_CALENDAR_SET_DATE,
        payload: data.dates
    });
}

export const BookingEngineSessionAndGuides = (datas) => async dispatch => {

    console.log(datas);

    const data = {
        packageId: datas.packageId,
        productId: datas.productId,
        date: datas.dates,
    }



    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getGuidesAndSessions`, data)

        if (resp.status === 200) {
            dispatch({
                type: BOOKING_ENGINE_SESSION_GET,
                payload: resp.data.sessions
            });
            dispatch({
                type: BOOKING_ENGINE_GUIDE_GET,
                payload: resp.data.guides
            });




        }
    } catch (err) {
        console.error(err);
    }
}

export const setGuides = (data) => dispatch => {

    dispatch({
        type: BOOKING_ENGINE_SET_GUIDE,
        payload: data
    });
}

export const setSessions = (data) => async dispatch => {

    dispatch({
        type: BOOKING_ENGINE_SET_SESSIONS,
        payload: data.selectedId
    });

    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getPersonFirstPrices`, data)

        if (resp.status === 200) {

            dispatch({
                type: BOOKING_FIRST_PRICE,
                payload: resp.data.prices
            });


        }
    } catch (err) {
        console.error(err);
    }



}


export const UpdatePax = (data) => async dispatch => {

    dispatch({
        type: BOOKING_UPDATE_PAX,
        payload: data.newPriceList
    });
 


    const datas = {
        sessionId: data.sessionId,
        priceId: data.priceId,
        packageId: data.packageId,
        productId: data.productId,
        date: data.date,
        block: data.block,
        pax: data.pax
    }

    console.log(datas);


    try {

        const resp = await axios.post(process.env.REACT_APP_API_ADDRESS + `/getPersonBlockPrices`, datas)

        if (resp.status === 200) {




        }
    } catch (err) {
        console.error(err);
    }

};


export const UpdateBlockPrice = (data) => async dispatch => {

    console.log(data);

}