import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.scss';
import Fancybox from '../../../Helpers/fancybox';
import CreateDrawer from '../../../Helpers/CreateDrawer';




SwiperCore.use([Navigation]);

export class BookingPackageDetail extends Component {
    prevNavRef = React.createRef();
    nextNavRef = React.createRef();

    constructor(props) {
        super(props)
        this.state = {
            packageDetailModal: false
        }
    }



    componentDidMount() {


    }

    getPackageDetail = () => {
        const { engineData } = this.props
        const datas = engineData.packagesData.filter(items => items.id === engineData.selectedPackage)[0]


        return datas;
    }


    getCancelTime = () => {
        const { engineData } = this.props;
        let result = [];
        if (engineData.cancelPolicy.length > 0) {


            engineData.cancelPolicy.filter(it => it.status === true).map(item => {



                result.push(item);

            })
        }

        return result;
    }

    openDetail = () => {
        this.setState({
            packageDetailModal: true
        })
    }

    closeDetail = () => {
        this.setState({
            packageDetailModal: false
        })
    }


    render() {
        const { engineData, productData } = this.props


        return (
            <>
            
                <div className='thumb'>
                    <Fancybox >

                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={7}
                            slidesPerView={1}
                            slidesPerGroup={1}


                            navigation={{
                                prevEl: this.prevNavRef.current,
                                nextEl: this.nextNavRef.current,
                            }}
                            onInit={(swiper) => {
                                swiper.params.navigation.prevEl = this.prevNavRef.current;
                                swiper.params.navigation.nextEl = this.nextNavRef.current;
                                //    swiper.navigation.init(); // throws error here, navigation is undefined
                                //  swiper.navigation.update();


                            }}
                            loop={true}
                            lazy={false}
                        >
                            {this.getPackageDetail().packageDetail.img.map((item, index) => (

                                <div key={index}>

                                    <SwiperSlide key={index}>

                                        <div className="sliderItem" style={{ backgroundImage: "url(" + process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/360/" + item.name + ")" }} data-fancybox="package"
                                            data-src={process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/1200/" + item.name}>

                                        </div>

                                    </SwiperSlide>

                                </div>
                            ))}

                        </Swiper>
                    </Fancybox>
                </div>

                <div className='titles1'>{engineData.packagesData.filter(items => items.id === engineData.selectedPackage)[0].packageTitle}</div>
                {
                    this.getCancelTime().map((cancel, i) => (
                        <div key={i}>
                            {
                                (cancel.status) ?
                                    <div className='cancelTime'>
                                        Cancel before {moment(cancel.lastCancelDate).format('lll')}  for a %{cancel.refund} refund.<br></br>
                                    </div>
                                    : ""
                            }

                        </div>

                    ))
                }


                <div className='titles2'>
                    Package description
                </div>
                <div className='packageDesc'>
                    <>
                        {this.getPackageDetail().packageDetail.data.map((item, is) => (
                            <div key={is*66}>

                                {(item.status && item.data.length>0) &&
                                    <div key={is}>

                                        <div className='subTitle'><img src={"/Assets/img/packageIcon/" + item.icon} alt="icon" />{item.title}</div>
                                        <div className='subText'>

                                            <ul>

                                                {item.data.map((d, i) => (
                                                    <li key={i}>
                                                        {d.text}
                                                    </li>
                                                ))}
                                            </ul>

                                        </div>
                                    </div>
                                }
                            </div>

                        ))
                        }
                    </>

                </div>
                <div className='expand' onClick={this.openDetail.bind(this)}>
                    <div className="icon"><img src="/Assets/img/icon/expands.svg" alt="Search" /></div>
                    <div className="text">See all description</div>
                </div>
                <>

                    <CreateDrawer
                        show={this.state.packageDetailModal}
                        close={this.closeDetail}
                        title={this.getPackageDetail().packageTitle} >
                        {this.state.packageDetailModal &&
                            <>
                                <div className='modalsNewPackage'>
                                    <div className='detailLeft'>

                                        <div className='titles1'>{productData.productTitle} ({this.getPackageDetail().packageTitle})</div>
                                        {
                                            this.getCancelTime().map((cancel) => (
                                                <>
                                                    {
                                                        (cancel.status) ?
                                                            <div className='cancelTime'>
                                                                Cancel before {moment(cancel.lastCancelDate).format('lll')}  for a %{cancel.refund} refund.<br></br>
                                                            </div>
                                                            : ""
                                                    }

                                                </>

                                            ))
                                        }


                                        {this.getPackageDetail().packageDetail.data.map((item) => (
                                            <>

                                                {(item.status) &&
                                                    <>

                                                        <div className='subTitle'><img src={"/Assets/img/packageIcon/" + item.icon} alt="icon" />{item.title}</div>
                                                        <div className='subText'>

                                                            <ul>

                                                                {item.data.map((d, i) => (
                                                                    <li key={i}>
                                                                        {d.text}
                                                                    </li>
                                                                ))}
                                                            </ul>

                                                        </div>
                                                    </>
                                                }
                                            </>

                                        ))
                                        }

                                    </div>
                                    <div className='detailMain'>
                                        <div className='detailGallery'>
                                            <Fancybox >

                                                <Swiper

                                                    spaceBetween={15}
                                                    slidesPerView={1}
                                                    slidesPerGroup={1}


                                                    navigation={{
                                                        //      prevEl: this.prevNavRef.current,
                                                        //     nextEl: this.nextNavRef.current,
                                                    }}
                                                    onInit={(swiper) => {
                                                        //    swiper.params.navigation.prevEl = this.prevNavRef.current;
                                                        //  swiper.params.navigation.nextEl = this.nextNavRef.current;
                                                        //    swiper.navigation.init(); // throws error here, navigation is undefined
                                                        //  swiper.navigation.update();
                                                        console.log(swiper);

                                                    }}

                                                >
                                                    {this.getPackageDetail().packageDetail.img.map((item, index) => (

                                                        <div key={index}>

                                                            <SwiperSlide key={index}>

                                                                <div className="sliderItem" style={{ backgroundImage: "url(" + process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/1200/" + item.name + ")" }} data-fancybox="packagex"
                                                                    data-src={process.env.REACT_APP_ASSETSNEW_ADDRESS + "/products/1200/" + item.name}>

                                                                </div>

                                                            </SwiperSlide>

                                                        </div>
                                                    ))}

                                                </Swiper>
                                            </Fancybox>
                                        </div>
                                        <div className='addOns'>
                                            <div className='title'>Add ons</div>
                                        </div>
                                    </div>
                                </div>
                            </>

                        }
                    </CreateDrawer>



                </>



            </>
        )
    }
}

const mapStateToProps = (state) => ({

    appData: state.appData,
    engineData: state.engineData
})



export default connect(mapStateToProps)(BookingPackageDetail)