import React, { Component } from 'react'
import { connect } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()
class Routeing extends Component {
    constructor(props) {
        super(props);

        this.state = {

            routeAffix: false,
            spyBar: "overviewSpy",
            barWidth: 0


        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }



    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {


        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        if (winScroll > 140) {
            this.setState({
                routeAffix: true
            });
        } else {
            this.setState({
                routeAffix: false
            });
        }


        let height = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        )



        let barWidth = Math.round((winScroll + window.innerHeight) * 100 / height);
        let spy = document.getElementsByClassName("spy");

        if (winScroll === 0) {
            barWidth = 0;
        }
        this.setState({
            barWidth: barWidth
        });

        for (let i = 0, len = spy.length; i < len; i++) {
            let id = document.getElementById(spy[i].id);

           

            let offset = id.offsetTop - 140;
            let height = id.clientHeight;

            if (winScroll >= offset && winScroll < offset + height) {
                this.setState({
                    spyBar: id.id
                });


                let elemRouter = document.getElementsByClassName(spy[i].id + "x")[0];


                let routers = document.getElementById("routerScroll");
                routers.scrollTo({
                    left: elemRouter.offsetLeft - 15,
                    
                });


            }

        }

    }

    setScroll = (e, ids) => {

        let elem = document.getElementById(e);

        let elemWhiteBar = document.getElementsByClassName("whiteBar")[0];

        let h = 0;
        if (elemWhiteBar.clientHeight === 100) {
            h = 145;
        } else {
            h = 110;
        }

        window.scrollTo({
            top: elem.offsetTop - h,
            behavior: "smooth"
        });

        let elemRouter = document.getElementsByClassName(e + "x")[0];


        let routers = document.getElementById("routerScroll");
        routers.scrollTo({
            left: elemRouter.offsetLeft - 15,
            behavior: "smooth"
        });
      
    }


    render() {


        return (


            <div className={this.state.routeAffix ? "routeing sticky" : "routeing"}>
                <div className="scrollBar">
                    <div className="bar" style={{ width: this.state.barWidth + "%" }}></div>
                </div>
                <div className='router' id="routerScroll">
                    <ul>
                        <li onClick={this.setScroll.bind(this, "overviewSpy", 0)} className={this.state.spyBar === "overviewSpy" ? "overviewSpyx active" : "overviewSpyx"}>Overview</li>
                        <li onClick={this.setScroll.bind(this, "highlightsSpy", 1)} className={this.state.spyBar === "highlightsSpy" ? "highlightsSpyx active" : "highlightsSpyx"}>Highlights</li>
                        <li onClick={this.setScroll.bind(this, "bookingSpy", 2)} className={this.state.spyBar === "bookingSpy" ? "bookingSpyx active" : "bookingSpyx"}>Booking details</li>
                        <li onClick={this.setScroll.bind(this, "itinerarySpy", 3)} className={this.state.spyBar === "itinerarySpy" ? "itinerarySpyx active" : "itinerarySpyx"}>Itinerary</li>
                        <li onClick={this.setScroll.bind(this, "importantoknowSpy", 4)} className={this.state.spyBar === "importantoknowSpy" ? "importantoknowSpyx active" : "importantoknowSpyx"}>Important to know</li>
                        <li onClick={this.setScroll.bind(this, "cancellationPolicySpy", 5)} className={this.state.spyBar === "cancellationPolicySpy" ? "cancellationPolicySpyx active" : "cancellationPolicySpyx"}>Policies</li>
                    </ul>
                </div>
                <div className='icon'>
                    <img src="/Assets/img/icon/down.svg" alt="down" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData
});

export default connect(mapStateToProps)(Routeing)
