import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from 'moment';
class BookingFixedBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bookingBottom: false,
            minPrice: false,

        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
        this.getMinPrice();
    }



    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);

    }

    listenToScroll = () => {


        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop
        let elembooking = document.getElementsByClassName("booking")[0].getBoundingClientRect();


        let height = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        )



        let barWidth = Math.round((winScroll + window.innerHeight) * 100 / height);
        if (elembooking.bottom < 200 && barWidth < 99) {
            this.setState({
                bookingBottom: true
            });
        } else {
            this.setState({
                bookingBottom: false
            });
        }





    }



    getBooking = () => {
        let elembooking = document.getElementsByClassName("booking")[0];

        window.scrollTo({
            top: elembooking.offsetTop - 110,
            behavior: "smooth"
        });

    }

    getMinPrice = () => {
        let prices = [];
        let priceObj = [];
        this.props.data.calendarPrice.dates.map((item, index) => {

            if (item.price[0].mon !== null) {
                let data = {
                    price: item.price[0].mon,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    spoPrice: item.price[0].mon,
                    weekDay: "offerMon"
                }

                let pu = {
                    data: data,
                    spo: this.calcSpo(data)

                }


                priceObj.push(pu);

                prices.push(item.price[0].mon);
            }

            if (item.price[0].tue !== null) {

                let data = {
                    price: item.price[0].tue,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    spoPrice: item.price[0].tue,
                    weekDay: "offerTue"
                }

                let pu = {
                    data: data,
                    spo: this.calcSpo(data)

                }

                priceObj.push(pu);

                prices.push(item.price[0].tue);
            }
            if (item.price[0].wed !== null) {
                let data = {
                    price: item.price[0].wed,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    spoPrice: item.price[0].wed,
                    weekDay: "offerWed"
                }

                let pu = {
                    data: data,
                    spo: this.calcSpo(data)

                }

                priceObj.push(pu);
                prices.push(item.price[0].wed);
            }
            if (item.price[0].thu !== null) {
                let data = {
                    price: item.price[0].thu,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    spoPrice: item.price[0].thu,
                    weekDay: "offerThu"
                }

                let pu = {
                    data: data,
                    spo: this.calcSpo(data)
                }

                priceObj.push(pu);
                prices.push(item.price[0].thu);
            }
            if (item.price[0].fri !== null) {
                prices.push(item.price[0].fri);

                let data = {
                    price: item.price[0].fri,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    spoPrice: item.price[0].fri,
                    weekDay: "offerFri"
                }

                let pu = {
                    data: data,
                    spo: this.calcSpo(data)
                }

                priceObj.push(pu);
            }
            if (item.price[0].sat !== null) {
                prices.push(item.price[0].sat);

                let data = {
                    price: item.price[0].sat,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    spoPrice: item.price[0].sat,
                    weekDay: "offerSat"
                }

                let pu = {
                    data: data,
                    spo: this.calcSpo(data)
                }

                priceObj.push(pu);


            }
            if (item.price[0].sun !== null) {
                prices.push(item.price[0].sun);

                let data = {
                    price: item.price[0].sun,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    spoPrice: item.price[0].sun,
                    weekDay: "offerSun"
                }

                let pu = {
                    data: data,
                    spo: this.calcSpo(data)
                }

                priceObj.push(pu);

            }

        });

        let pr = [];
        priceObj.map((item) => {

            let pus = {
                price: item.data.price,
                spoPrice: parseFloat(parseFloat(item.data.price * (1 - item.spo)).toFixed(2)),
                spo: item.spo
            }


            pr.push(pus);




        });

        pr.sort(function (a, b) { return a.spoPrice - b.spoPrice });






        if (pr.length > 0) {
            prices.sort(function (a, b) { return a - b });
            //   console.log(prices)
            this.setState(
                {
                    minPrice: pr[0]
                }
            );


        } else {
            this.setState(
                {
                    minPrice: false
                }
            );
        }



    }

    calcSpo = (datas) => {


        let startDate = Date.parse(Moment(datas.startDate).format('YYYY-MM-DD'));
        let endDate = Date.parse(Moment(datas.endDate).format('YYYY-MM-DD'));
        let spo = 0;
        this.props.data.calendarPrice.specialOffers.map((item, index) => {

            let spoStartDate = Date.parse(Moment(item.startDate).format('YYYY-MM-DD'));
            let spoEndDate = Date.parse(Moment(item.endDate).format('YYYY-MM-DD'));

            //  console.log(endDate);
            // console.log(spoStartDate);
            if (spoStartDate <= endDate && spoEndDate >= startDate && item[datas["weekDay"]] === "1") {

                if (this.props.data.bookingSpect.priceType === 1) {
                    // console.log(item.adult);
                    spo = parseFloat(item.adult)
                } else if (this.props.data.bookingSpect.priceType === 2) {
                    spo = parseFloat(item.groups);
                }
            }


        });



        return spo;

    }



    specialOffers = () => {

        let discount = [];
        this.props.data.calendarPrice.specialOffers.map((item, index) => {

            if (this.props.data.bookingSpect.priceType === 1) {
                discount.push(1 - parseFloat(item.adult));
            } else if (this.props.data.bookingSpect.priceType === 2) {
                discount.push(1 - parseFloat(item.groups));
            }



        });


        if (discount.length > 0) {
            discount.sort();
            return discount;
        } else {
            return false
        }
    }

    priceFormat = (e) => {
        return e.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    }
    render() {

       
        return (
            <div className="bookingFixedBar" style={{
                visibility: this.state.bookingBottom ? "visible" : "hidden"
            }}>



                <div className="checkAvailability">


                    <>

                        <div className="title">
                            <div className="productName">{this.props.data.productTitle}</div>
                            <div className="pricesGroup">
                                {(this.specialOffers() && this.state.minPrice.spo !== 0) ?
                                    <>
                                        <div className='prices discountPrices'>

                                            <div className="price discount">
                                                {this.state.minPrice !== false && this.props.data.calendarPrice.dates[0].currency}
                                            </div>
                                            <div className="currency discount">
                                                {this.state.minPrice !== false && this.priceFormat(this.state.minPrice.price)}
                                            </div>
                                        </div>
                                        <div className='prices lastPrices'>
                                            <div className="currency">
                                                {this.state.minPrice !== false ? this.props.data.calendarPrice.dates[0].currency : ""}
                                            </div>
                                            <div className="price">
                                                {this.state.minPrice !== false ? this.priceFormat(this.state.minPrice.spoPrice) : "Not available now"}
                                            </div>
                                        </div>

                                    </>
                                    : <>
                                        <div className='prices lastPrices'>
                                            <div className="currency">
                                                {this.state.minPrice !== false ? this.props.data.calendarPrice.dates[0].currency : ""}
                                            </div>
                                            <div className="price">
                                                {this.state.minPrice !== false ? this.priceFormat(this.state.minPrice.price) + " " : "Not available now"}
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className='fixedButtons'>

                                <div className='bookButton' onClick={this.getBooking.bind(this)}>Check Availability</div>

                            </div>
                        </div>



                    </>


                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(BookingFixedBar)