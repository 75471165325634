import React, { Component } from 'react'
import { connect } from 'react-redux'
import BookingPersonDetail from './BookingPersonDetail';

import { BookingGetBlockInfo } from "../Action/BookingEngineAction"



export class BookingPerson extends Component {



    componentDidMount() {
        const { engineData } = this.props;

        const data = {

            sessionId: engineData.selectedSession,
            priceId: engineData.sessionsData.filter(item => item.quotaId === engineData.selectedSession)[0].priceId,
            packageId: engineData.selectedPackage,
            productId: engineData.productId,
            date: engineData.selectedDate
        }

        this.props.BookingGetBlockInfo(data);

    }


    





    render() {



        const { engineData } = this.props



        return (


            <>
                {!engineData.blockLoading &&
                    <>
                        <div className="personDiv">
                            <div className="title">Quantity</div>
                            <>
                                {engineData.block.filter(items => items.status === true).map((item, index) => (



                                    <BookingPersonDetail key={index} data={item} />


                                ))
                                }
                            </>
                        </div>
                        
                        
                    </>
                }

            </>
        )
    }
}

const mapStateToProps = (state) => ({
    appData: state.appData,
    engineData: state.engineData,


});



export default connect(mapStateToProps, {
    BookingGetBlockInfo
})(BookingPerson)